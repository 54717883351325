import React from "react"
import Arrow from "../../../../../../assets/images/Inner/arrow.svg";
import Line from "../../../../../../assets/images/dashboard/line.svg";
import { getNotesFromLuci } from "../../../../../../utils/constants"


class SeeMoreDetails extends React.Component {

    render() {
        const { moreDetailInfo = [], moreDetailNotes = [] } = this.props
        return(
            <div className="see-more">
                <a
                    data-toggle="collapse" href="#collapseExample" role="button"
                    aria-expanded="false" aria-controls="collapseExample"
                    style={{color: "#333333", textDecoration: "none"}}
                >
                    See More Details &nbsp;&nbsp;
                    <img src={Arrow} className="arrow-tab-more"/>
                </a>

                <div className="collapse" id="collapseExample">
                    <div className="card card-body card-detail-modal">
                      <div className="tab-pane fade show active see-more card-tab mt-0" id="pills-home"
                           role="tabpanel" aria-labelledby="pills-home-tab">
                        <div className="card-dtl">
                          <div className="card-dtl-l">
                            <div className="cr-title">
                              Details
                            </div>
                            {
                              (moreDetailInfo || []).map((reward, index) => (
                                <div className="card-dtl-item" key={index.toString()}>
                                  <img src={Line} className="mt-2"/>
                                  <p dangerouslySetInnerHTML={{__html: reward}}/>
                                </div>
                              ))
                            }
                          </div>
                          <div className="card-dtl-r">
                            <div className="cr-title">
                              Notes from luci
                            </div>
                            {
                              (moreDetailNotes || []).map((note, index) => (
                                <div className="card-dtl-item" key={index.toString()}>
                                  <img className={`w-20 ${(note || "").startsWith("+") ? "mt-0" : ""}`} src={getNotesFromLuci(note, "src")}/>
                                  <p>{getNotesFromLuci(note, "text")}</p>
                                </div>
                              ))
                            }
                          </div>
                        </div>
  
                      </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default SeeMoreDetails
