import React, { Component } from "react";
import { connect } from "react-redux";
import { Switch, Route, Redirect } from "react-router-dom";
import { ConnectedRouter } from "connected-react-router";
import { history } from "./common/services/ReduxService";
import AppLayout from "./common/components/AppLayout";
import SignIn from "./pages/SignIn";
import SignUp from "./pages/SignUp";
import ForgotPassword from "./pages/ForgotPassword";
import RestorePassword from "./pages/RestorePassword";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import Terms from "./pages/Terms";
import CookiesPolicy from "./pages/CookiesPolicy";
import Support from "./pages/Support";
import AboutUs from "./pages/AboutUs";
import GoogleAuthentication from "./pages/GoogleAuthentication";
import Dashboard from "./pages/Auth/Dashboard";
import MyAccount from "./pages/Auth/MyAccount";
import Debug from "./pages/Auth/Debug";
import GeneralError from "./pages/GeneralError";
import Auth from "./pages/Auth/Dashboard/Components/FirstStep/Auth";
import { getAuthToken, clearAuthToken } from "./services/ApiService";
import { fetchUserDetails } from "./redux/modules/userDetails/userDetailsActions";
import { Loading } from "./common/components/Loading/Loading";
import {setAccounts, setReLoginRequiredInstitutes} from "./redux/modules/accounts/accountsActions";
import { setCosts } from "./redux/modules/manualCosts/costsActions";
import { setCards } from "./redux/modules/cards/cardsActions";
import { getLocalData } from "./utils/constants";
import { setDashboardStep } from "./redux/modules/dashboardStep/dashboardStepActions";
import { setResultSetting } from "./redux/modules/resultSetting/resultSettingActions";


class AppEntry extends Component {
    constructor(props){
        super(props)
        this.state = {
            isLoggedInUser: false,
            isUserLoading: !!getAuthToken()
        }
    }

    async componentDidMount() {
        this.onSetData()
        if(getAuthToken()) {
            const onSuccess = () => {
                this.setState({
                    isLoggedInUser: true,
                    isUserLoading: false
                });
            };
            this.props.fetchUserDetails({
                onSuccess: onSuccess,
                onFail: () => {
                    clearAuthToken();
                    this.setState({
                        isUserLoading: false
                    })
                    window.location.href = "/"
                }
            });
        }
    }

    onSetData = () => {
        const localData = getLocalData();
        if(Object.keys(localData).length) {
            const { accounts, cards, costs, dashboardStep, resultSetting } = localData;
            this.props.onSetAccounts(accounts.banks);
            this.props.onSetReLoginRequiredInstitutes(accounts.reLoginRequiredInstitutes);
            this.props.onSetCards(cards.cardsDetails)
            this.props.onSetCosts(costs || {})
            this.props.onSetDashboardStep(dashboardStep)
            this.props.onSetResultSetting({...resultSetting, linkToken: this.onGetLinkToken(resultSetting?.linkToken || "")})
        }
    }

    onGetLinkToken = (token) => {
        const storedToken = localStorage.getItem("link-token") || ""
        return storedToken || token || ""
    }

    render() {
        const isAuth = getAuthToken() && this.state.isLoggedInUser;
        const { isUserLoading } = this.state;
        return(
            <ConnectedRouter history={history}>
                <div>
                    <Switch>
                        {isAuth ? <Route path="/dashboard" exact component={Dashboard}/> : null}
                        {isAuth ? <Route path="/dashboard_debug" exact component={Debug}/> : null}
                        {isAuth ? <Route path="/dashboard/oauth" exact component={Auth}/> : null}
                        {isAuth ? <Route path="/account" exact component={MyAccount}/> : null}
                        {!isAuth && !isUserLoading ? <Route path="/signin" component={SignIn} /> : null}
                        {!isAuth && !isUserLoading ? <Route path="/signup" component={SignUp} /> : null}
                        {!isAuth && !isUserLoading ? <Route path="/forgot-password" component={ForgotPassword} /> : null}
                        {!isAuth && !isUserLoading ? <Route path="/restore-password/:token" component={RestorePassword} /> : null}
                        {!isAuth && !isUserLoading ? <Route path="/oops" render={(props) => <GeneralError unAuth={!isAuth} {...props}/>} /> : null}
                        <Route path="/" exact component={AppLayout} />
                        <Route path="/privacy-policy" render={(props) => <AppLayout {...props}><PrivacyPolicy/></AppLayout>} />
                        <Route path="/terms" render={(props) => <AppLayout {...props}><Terms/></AppLayout>} />
                        <Route path="/cookies-policy" render={(props) => <AppLayout {...props}><CookiesPolicy/></AppLayout>} />
                        <Route path="/oops" render={(props) => <GeneralError unAuth={!isAuth} {...props}/>} />
                        <Route path="/support" render={(props) => <AppLayout {...props}><Support/></AppLayout>} />
                        <Route path="/about" render={(props) => <AppLayout {...props}><AboutUs/></AppLayout>} />
                        <Route path="/auth/google" render={(props) => <GoogleAuthentication {...props}/>} />
                        {!isUserLoading && <Route render={() => (<Redirect to={"/"} />)}/>}
                        <Route path="*" render={() => <Loading /> } />
                    </Switch>
                </div>
            </ConnectedRouter>
        )
    }

}

const mapDispatchToProps = (dispatch) => ({
    fetchUserDetails: (state) => {
        return dispatch(fetchUserDetails(state))
    },
    onSetAccounts: data => {
        dispatch(setAccounts(data));
    },
    onSetReLoginRequiredInstitutes: data => {
        dispatch(setReLoginRequiredInstitutes(data));
    },
    onSetCosts: data => {
        dispatch(setCosts(data));
    },
    onSetCards: data => {
        dispatch(setCards(data));
    },
    onSetDashboardStep: data => {
        dispatch(setDashboardStep(data));
    },
    onSetResultSetting: (data) => {
        dispatch(setResultSetting(data))
    }
});

const mapStateToProps = state => ({
    state: state || {}
});

export default connect(mapStateToProps, mapDispatchToProps)(AppEntry)
