import React from "react";
import Banner from "../Banner";
import Slider from "../Slider";
import Problems from "../Problems";
import FindCreditCard from "../FindCreditCard";
import HowItWorks from "../HowItWorks";
import Benefits from "../Benefits";
// import Alumni from "../Alumni";
import "../../common/styles/global.scss";

const Home = (props) => {
  return (
    <>
      <Banner {...props} />
      <Slider {...props} />
      <Problems {...props} />
      <FindCreditCard {...props} />
      <HowItWorks {...props} />
      <Benefits {...props} />
      {/*<Alumni {...props} />*/}
    </>
  );
};

export default Home;
