import React from "react";
import {valueWithCommas} from "../../../../../../utils/constants"


const LineBarChart = (props) => {

  const { getChartData, onGetTotalSpending, money } = props || {}

  return(
      <div className="graph-left mt-3 mb-3">
          <div className="line-bar-chart">
              <div className="chart-inner-text d-flex mb-3">
                  <div className="pr-15">
                      <div>
                          <h3 className="ci-title">${valueWithCommas(onGetTotalSpending() || 0)}</h3>
                      </div>
                      <div>
                          <span className="ci-value">Total Spending</span>
                      </div>
                  </div>
                  <div className="reward-txs pl-15">
                      <div>
                          <h3 className="ci-title reward-color">${valueWithCommas(money || 0)}</h3>
                      </div>
                      <div>
                          <span className="ci-value reward-color">Reward from Txs</span>
                      </div>
                  </div>
              </div>
              <div className="line-chart-area">
                  {
                      (getChartData() || []).map((x, index) => (
                          <div key={index.toString()} className="chart-line" style={{width: x.percentage, background: x.color}}/>
                      ))
                  }
              </div>
          </div>
      </div>
  )
}

export default LineBarChart
