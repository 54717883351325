import React from "react";
import {connect} from "react-redux";
import cloneDeep from "lodash/cloneDeep";
import { PieChart } from "react-minimal-pie-chart";
import {Tooltip} from "antd";
import {commaSeparator, getCategoriesIcon, getCategoriesColor, valueWithCommas} from "../../../../../../utils/constants";
import Help from "../../../../../../assets/images/dashboard/help-icon.svg";
import Arrow from "../../../../../../assets/images/Inner/arrow.svg";


class EnableCardsChart extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            isMore: false
        }
    }

    getCardList = () => {
        const { transactions_statistics } = this.props || {}
        let array = cloneDeep(transactions_statistics);

        const index = array.findIndex(x => x.category === "Other")

        if(index !== -1) {
            array.splice(index, 1)
            array = array.sort((a,b) => b.spending - a.spending)
            array.push(transactions_statistics[index])
        } else {
            array = array.sort((a,b) => b.spending - a.spending)
        }

        return array
    }

    getChartData = (stat) => {
        return stat.map((s, i) => {
            return {
                ...s,
                color: getCategoriesColor(s.category),
                title: s.category,
                value: s.spending
            };
        });
    }

    content = (category) => {
        const { spendingByCategory } = this.props || {}
        return(
            <div className="p-3">
                <div className="text-nowrap">
                    <img src={getCategoriesIcon(category)} style={{background: getCategoriesColor(category), width: 35, padding: 5, borderRadius: 5, marginRight: 20}}/>
                    <span className="color-white" style={{fontSize: 20}}><b>Top Merchants</b></span>
                </div>
                {
                    (spendingByCategory[category] || []).map((value, index) => (
                        <div className="color-white mt-4 justify-content-between d-flex" key={index.toString()}>
                            <span className="top-category">{value.name}</span>
                            <span className="gr-spending w-80 text-right ml-1">${valueWithCommas(value.amount)}</span>
                        </div>
                    ))
                }
            </div>
        )
    }

    onMoreChange = () => {
        this.setState({
            isMore: !this.state.isMore
        })
    }

    render() {
        const { getSelectedDate, avgSpending, spending, selectedDate, spendingByCategory } = this.props || {}
        const { isMore } = this.state || {}

        const cardCategories = this.getCardList() || [];

        const getCardCategories = this.getChartData(cardCategories) || [];
        return(
            <div className="mt-30">
                <div className="pick-text">
                    Breakdown of your spending data
                </div>
                <div className="data-graph data-graph-border m-0">
                    <div className="dg-area mb-20">
                        <div className="dg-dates mt-10">
                            <div className="dg-date">{getSelectedDate()}</div>
                        </div>
                        <div className={`graph-info ${(getCardCategories || []).length > 6 ? "w-100" : ""}`}>
                            <div className={`graph-left ${(getCardCategories || []).length > 6 ? "flex-unset" : ""}`}>
                                <div className="chart-area mt-0">
                                    <div
                                        className="d-flex align-items-center justify-content-center pens flex-grow-1 position-relative">
                                        <PieChart
                                            animation
                                            animationDuration={500}
                                            animationEasing="ease-out"
                                            center={[50, 50]}
                                            rounded={false}
                                            data={this.getChartData(cardCategories)}
                                            labelPosition={50}
                                            lengthAngle={360}
                                            lineWidth={15}
                                            paddingAngle={0}
                                            radius={50}
                                            startAngle={0}
                                            viewBoxSize={[100, 100]}
                                            style={{
                                                height: "250px",
                                                position: "relative"
                                            }}
                                        />
                                        <div className="chart-inner-text d-flex flex-column">
                                            <div>
                                                <h3 className="ci-title">${commaSeparator(spending || 0)}</h3>
                                            </div>
                                            <div>
                                                <span className="ci-value">Total Spending</span>
                                            </div>
                                            <div>
                                                <hr/>
                                            </div>
                                            <div>
                                                <h3 className="ci-title">
                                                    ${commaSeparator(avgSpending || "")}
                                                    {
                                                        selectedDate === 6 ?
                                                            <Tooltip
                                                                title="Assumes 30 days in a month"
                                                                trigger={"click"}
                                                            >
                                                                <img className="help-icon ml-1" src={Help}/>
                                                            </Tooltip> : null
                                                    }
                                                </h3>
                                            </div>
                                            <div>
                                                <span className="ci-value">Avg per Month</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={`graph-right pt-0 ${(getCardCategories || []).length > 6 ? "pl-40" : ""}`}>
                                <div className="row">
                                    {(getCardCategories || []).map((x, index) => {
                                        let object = { trigger: "hover" }
                                        const isSpending = (spendingByCategory[x.category]  || []).length
                                        if(isSpending) {
                                            object.title = () => this.content(x.category)
                                        }
                                        return(
                                            <div className={`${index > 3 && !isMore ? "mobile-hide" : ""} ${(getCardCategories || []).length > 6 ? `col-md-6 ${index % 2 ? "graph-pl-40" : "graph-pr-40"}` : 'col-md-12'} gr-item justify-content-start`} key={index.toString()}>
                                                <Tooltip
                                                    {...object}
                                                    visible={false}
                                                >
                                                <span className={`gr-name ${isSpending ? "cursor-pointer" : ""}`}>
                                                    <img src={getCategoriesIcon(x.category)} alt={`${x.category}-icon`} style={{background: getCategoriesColor(x.category)}}/>
                                                    <span>{x.category}</span>
                                                </span>
                                                </Tooltip>
                                                <span className="gr-spending w-120">${valueWithCommas(x.spending)}</span>
                                            </div>
                                        )
                                    })}
                                </div>
                                {
                                    (getCardCategories || []).length > 4 ?
                                        <div className="text-center web-hide">
                                            <span onClick={this.onMoreChange}>{ isMore ? "Less" : "More"} <img src={Arrow} style={!isMore ? {transform: 'rotate(180deg)'} : {}} /></span>
                                        </div> : null
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    spendingByCategory: state.cards.cardsDetails.topSpendingsByCategory || []
});

export default connect(mapStateToProps, null)(EnableCardsChart);

