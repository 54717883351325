import React from "react"
import {connect} from "react-redux";
import moment from "moment"
import {cloneDeep, max, orderBy} from "lodash"
import { Spin, Skeleton } from "antd";
import MediaQuery from "react-responsive";
import CardDetails from "./component/CardDetails"
import AllCardsView from "./component/AllCardsView"
import {
  dateFormat,
  commaSeparator,
  getStoredDates,
  getDateRanges,
  getDateQuery,
  getSelectedDate,
  getCardsListByYearWise,
} from "../../../../../utils/constants";
import DotImage from "../../../../../assets/images/dashboard/dot.svg"
import DotFill from "../../../../../assets/images/dashboard/dot-fill.svg"
import {Loading} from '../../../../../common/components/Loading/Loading';
import { setCards, fetchCards } from "../../../../../redux/modules/cards/cardsActions";
import {setResultSetting} from "../../../../../redux/modules/resultSetting/resultSettingActions";
import EnableCardsChart from "./component/EnableCardsChart";
import CardsFilter from "./component/CardsFilter";
import SettingModal from "./component/SettingModal";
import RewardCalculationModal from "./component/RewardCalculationModal";
import RecommendedCards from "./component/RecommendedCards"
import CardDetailsModal from "./component/CardDetailsModal"
import YearProjection from "./component/YearProjection"
import TopSpending from "./component/TopSpending";
import "./datePicker.scss";


class SecondStep extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isRunTested: false,
      isSettingModal: false,
      isCalculationModal: false,
      isYearChanging: false,
      isCardDetailsModal: false,
      activeTab: "all",
      selectedDate: 1,
      selectedCard: 0,
      selectedCardInfo: {},
      selectedRange: [moment(new Date(new Date().setFullYear(new Date().getFullYear() - 1))), moment()],
      enableCardLength: 0,
      selectedFeatures: [],
      selectedCategories: []
    }
  }
  
  componentDidMount() {
    const { cardList, fetchCardsLoaded, fetchCardsLoading, isAccountsLoading, isManualCost } = this.props
    if(!((cardList || []).length && fetchCardsLoaded) && !fetchCardsLoading && !isAccountsLoading && !isManualCost) {
      this.onGetCardsList()
    }
    if(Object.keys(getStoredDates() || {}).length) {
      const { dateType, date } = getStoredDates() || {}
      this.setState({
        selectedDate: Number(dateType) || 1,
        selectedRange: (date || []).length && Number(dateType) === 6 ? [moment(date[0]), moment(date[1])] : this.state.selectedRange
      })
    }
  }

  componentWillReceiveProps(nextProps, nextContext) {
    if(nextProps.isCardRequest) {
      this.props.onCardRequestChange(false)
      this.onGetCardsList()
    } else if(nextProps.resettingCard) {
      this.onCardChange(0)
    }
  }

  onGetCardsList = async () => {
    const { getEnableCardsIds, onFetchCards, onSetCards, onPlaidModalChange } = this.props

    const ids = getEnableCardsIds()

    await onFetchCards({
      apiEndpoint: "run-backtest",
      query: `?${ids.join("&")}${getDateQuery()}`,
      onSuccess: (res) => {
        onSetCards(res || {})
        this.setState({
          isRunTested: false
        })
        localStorage.setItem("LUCI_DATAS", JSON.stringify(this.props.state))
      },
      onFail: (response) => {
        if (response?.data?.errors && response.data.errors[0]?.param?.error_type === 'popup') {
          onPlaidModalChange({
            isPlaidPopUp: true,
            institutionName: response.data.errors[0]?.param?.institutionName,
            currentStep: 0,
            plaidPopUpErrorMsg: response.data.errors[0]?.msg || ""
          });
        }
        this.setState({
          isRunTested: false
        })
        localStorage.setItem("LUCI_DATAS", JSON.stringify(this.props.state))
      }
    });
  }

  onTabChange = (value) => {
    this.setState({
      activeTab: value,
      selectedCard: 0
    })
  }
  
  onDateChange = (value) => {
    const selectedRange = getDateRanges(value)
    this.setState({
      selectedDate: value,
      selectedRange
    });
  }
  
  onCardChange = (value) => {
    this.setState({
      selectedCard: value
    })
  }
  
  onSelectCard = (info) => {
    const { isCardDetailsModal } = this.state
    this.setState({
      isCardDetailsModal: !isCardDetailsModal,
      selectedCardInfo: isCardDetailsModal ? {} : info
    })
  }
  
  onRangeDatesChange = (selectedRanges) => {
    this.setState({
      selectedRange: selectedRanges
    });
  }

  getSelectedDate = () => {
    const { isManualCost } = this.props;
    const { selectedRange, selectedDate } = this.state;
    if(isManualCost) {
      return "12 Months"
    } else if(selectedDate === 6) {
      const date1 = ((selectedRange && selectedRange[0] && selectedRange[0].clone()) || (selectedRange && selectedRange[1] && selectedRange[1].clone()) || moment()).format(dateFormat);
      const date2 = ((selectedRange && selectedRange[1] && selectedRange[1].clone()) || (selectedRange && selectedRange[0] && selectedRange[0].clone()) || moment()).format(dateFormat);
      return `${date1} - ${date2}`;
    } else {
      return getSelectedDate(selectedDate)
    }
  }
  
  getCardsList = () => {
    const { selectedCategories, selectedFeatures } = this.state
    
    let list = cloneDeep(this.props.cardList)
    
    /*if (activeTab !== "all") {
      list = list.filter(x => {
        return (x.card.category || []).filter(y => {
          return [(activeTab || "").toLowerCase()].some(element => (y || "").toLowerCase().includes(element))
        }).length > 0;
      });
    }*/

    list = orderBy(list, ["totalMoney", 'cardName'], "desc");

    if((selectedCategories || []).length || (selectedFeatures || []).length) {

      list = list.filter(x => {
        return (x.category || []).filter(y => {
          return (selectedCategories || []).some(element => (y || "").toLowerCase() === (element || "").toLowerCase())
        }).length > 0 ||
          (x.features || []).filter(y => {
            return (selectedFeatures || []).some(element => (y || "").toLowerCase() === (element || "").toLowerCase())
          }).length > 0;
      });

      let filteredCards = []

      list.forEach(x => {
        let categoriesLength = 0
        selectedCategories.forEach(y => {
          if((x.category || []).some(z => (z || "").toLowerCase() === (y || "").toLowerCase())) {
            categoriesLength += 1
          }
        })

        let featuresLength = 0
        selectedFeatures.forEach(y => {
          if((x.features || []).some(z => (z || "").toLowerCase() === (y || "").toLowerCase())) {
            featuresLength += 1
          }
        })

        if((selectedCategories || []).length === categoriesLength && ((selectedFeatures || []).length === featuresLength)) {
          filteredCards.push(x)
        }

      })

      list = filteredCards

    }
    
    return list
  }
  
  getTotalRewards = () => {
      const value = (this.getCardsList() || []).length && this.getCardsList()[0].totalMoney || 0
      return ((value) || 0).toFixed(2) || '0.00';
  }

  onFilterChange = (type, value) => {
    let list = this.state[type]
    if((list || []).includes(value)) {
      list = (list || []).filter(x => x !== value)
    } else {
      list.push(value)
    }
    this.setState({
      [type]: list,
      selectedCard: 0
    })
  }

  onDismissFilter = () => {
    this.setState({
      selectedFeatures: [],
      selectedCategories: [],
      selectedCard: 0
    })
  }

  onModalChange = () => {
    this.setState({
      isSettingModal: !this.state.isSettingModal
    })
  }

  onApplyingSetting = () => {
    this.props.onCardRequestChange(true)
    this.onModalChange()
    this.onCardChange(0)
  }

  onGetSelectedDate = () => {
    const { selectedDate } = this.state
    if(selectedDate === 1) {
      return "12 Months"
    } else if(selectedDate === 2) {
      return "6 Months"
    } else if(selectedDate === 3) {
      return "3 Months"
    } else if(selectedDate === 4) {
      return "1 Month"
    } else if(selectedDate === 5) {
      return "Max (12 Months)"
    } else if(selectedDate === 6) {
      return this.getSelectedDate()
    } else if(selectedDate === 7) {
      return "9 Months"
    }
  }

  getSelectedCard = () => {
    const { getEnableCardsIds, banks } = this.props
    const length = (getEnableCardsIds() || []).length

    let text = ""
    if(length === 1) {
      banks.forEach(bank => {
        bank.accounts.forEach(account => {
          if(account.enabled) {
            text = (account.official_name || account.name)
          }
        })
      })
    } else {
      text = `${length} of your cards.`
    }

    return text
  }

  onCalculationModalChange = () => {
    this.setState({
      isCalculationModal: !this.state.isCalculationModal
    })
  }

  onYearProjectionChange = (value) => {
    this.setState({isYearChanging: false, selectedCard: 0})
    this.props.onSetYearProjection({topYearProjection: value})
    setTimeout(() => localStorage.setItem("LUCI_DATAS", JSON.stringify(this.props.state)), 100)
    /*setTimeout(() => {
      this.setState({isYearChanging: false})
    }, 500)*/
  }

  onIncludeIntroChange = () => {
    this.setState({selectedCard: 0})
    this.props.onSetYearProjection({topIncludedIntroBonus: !this.props.topIncludedIntroBonus})
    setTimeout(() => localStorage.setItem("LUCI_DATAS", JSON.stringify(this.props.state)), 100)
  }

  render() {
    const { selectedDate, selectedCard, selectedCardInfo, selectedRange, isRunTested, selectedFeatures, selectedCategories, isSettingModal, isCalculationModal, isYearChanging, isCardDetailsModal } = this.state
    const {
      isManualCost,
      cardList,
      statics,
      fetchCardsFailure,
      fetchCardsLoading,
      isAccountsLoading,
      getEnableCardsIds,
      fetchAccountsFailure,
      errorMessage,
      onFindCardModalChange,
      backToFirstStep,
      avgSpending,
      spending,
      isFindingCards,
      topYearProjection,
      topIncludedIntroBonus,
      onAccountsLoading,
      getAccountService
    } = this.props;

    let object = {}
    if (isRunTested) {
      object.visible = false
    }
    
    const filteredCards = this.getCardsList() || [];

    const loading = isAccountsLoading || fetchCardsLoading || isFindingCards
    const loadingFailed = fetchCardsFailure || fetchAccountsFailure

    return (
      <div id="content" className="">

        {
          loading ?
            <div className="text-center mt-100"><Loading isInteral={true} isCenter={true}/></div> :
            <div className="card luci-card second-step">
              <div className="card-body padd-xs">
                <div className="luci-title">
                  luci tested <span className="link-text">{loading ? <Spin className="mr-5px" /> : cardList.length} credit cards</span> and found a card with <span
                    className="link-text">${loading ? <Spin className="mr-5px" /> : commaSeparator(this.getTotalRewards())}</span> of reward
                    {/*<Tooltip title="Below is your personalized recommendation ranked by savings and rewards based on your spending" trigger={"click"}>
                      <img className="help-icon ml-2" src={Help} />
                    </Tooltip>*/}
                </div>
                {
                  isManualCost ?
                    <div>
                      <div>
                        Based on your monthly spending estimates projected for a year (modify your estimates by clicking
                        <a href="#" className="ml-1" onClick={onFindCardModalChange}>here</a>).
                      </div>
                      <div>
                        <a href="#" onClick={backToFirstStep}>Add accounts</a> to get personalized recommendation using your actual spending data.
                      </div>
                    </div> :
                    <div>
                      <div>
                        Based on your {this.onGetSelectedDate()} { selectedDate === 6 ? "" : "of" } spending data for {this.getSelectedCard()}
                      </div>
                      <div>
                        <b className="color-link cursor-pointer" onClick={this.onModalChange}>Change Settings</b>
                      </div>
                    </div>
                }

                {
                  loadingFailed ?
                    <div className="mt-60 text-center w-100">
                      <h3>{ errorMessage || "Oops, Something went wrong. Please try again later." }</h3>
                    </div> : null
                }

                { !loadingFailed ?
                  <TopSpending
                    transactions_statistics={statics}
                    avgSpending={avgSpending}
                    isManualCost={isManualCost}
                    spending={spending}
                    selectedDate={selectedDate}
                    getSelectedDate={this.getSelectedDate}
                    onAccountsLoading={onAccountsLoading}
                    getAccountService={getAccountService}
                    backToFirstStep={backToFirstStep}
                  /> : null
                }

                {/*{ !loadingFailed ?
                  <EnableCardsChart
                    transactions_statistics={statics}
                    avgSpending={avgSpending}
                    spending={spending}
                    selectedDate={selectedDate}
                    getSelectedDate={this.getSelectedDate}
                  /> : null
                }*/}

              </div>

              {
                !loadingFailed && !loading ?
                  <div className="card-body padd-xs">

                    <div className="pick-text mb-0 text-left">
                      {/*luci's pick based on your spending*/}
                      <h3><b>Recommended cards based on your spending</b></h3>
                    </div>

                    <div>
                      Browse your personalized recommendation from luci ranked by rewards
                    </div>

                    <div className="view-top-cards mt-2">
                      <span className="mr-3">View top cards for</span>
                      <CardsFilter
                        selectedCategories={selectedCategories}
                        selectedFeatures={selectedFeatures}
                        selectedCard={selectedCard}
                        onDismissFilter={this.onDismissFilter}
                        onFilterChange={this.onFilterChange}
                        getCardsList={this.getCardsList}
                        onCalculationModalChange={this.onCalculationModalChange}
                        isTop={true}
                      />
                    </div>

                    <p className="d-flex text-nowrap mb-2">
                      Include intro bonus
                      <div className="bank-area mb-0 ml-3">
                        <div className="toggle">
                          <label className="switch">
                            <input type="checkbox" checked={topIncludedIntroBonus} onChange={this.onIncludeIntroChange} />
                            <span className="slider round"/>
                          </label>
                        </div>
                      </div>
                    </p>

                    <YearProjection
                      yearProjection={topYearProjection}
                      onYearProjectionChange={this.onYearProjectionChange}
                    />

                    {
                      isYearChanging ?
                        <div className="text-center mt-50 mb-50"><Skeleton avatar paragraph={{ rows: 8 }}  active className="card-skeleton"/></div> :
                        <>
                          {
                            filteredCards.length ?
                              <>
                                <CardDetails
                                  selectedCard={selectedCard}
                                  avgSpending={avgSpending}
                                  spending={spending}
                                  selectedDate={selectedDate}
                                  topYearProjection={topYearProjection}
                                  topIncludedIntroBonus={topIncludedIntroBonus}
                                  onTabChange={this.onTabChange}
                                  getCardsList={this.getCardsList}
                                  onCardChange={this.onCardChange}
                                  onGetDate={this.getSelectedDate}
                                />

                                <MediaQuery minWidth={991}>
                                  <div className="dot-slider">
                                    {
                                      (this.getCardsList() || []).map((card, index) => (
                                        <img
                                          key={index.toString()}
                                          className="cursor-pointer"
                                          src={selectedCard === index ? DotFill : DotImage}
                                          onClick={() => this.onCardChange(index)}
                                        />
                                      ))
                                    }
                                  </div>
                                </MediaQuery>

                                <div className="page-number">
                                  <span>({selectedCard + 1} / {(this.getCardsList() || []).length})</span>
                                </div>
                              </> :
                              <div className="text-center no-cards">
                                No Cards found!
                              </div>
                          }
                        </>
                    }

                    <RecommendedCards onSelectCard={this.onSelectCard} />

                    <MediaQuery minWidth={767}>
                      <AllCardsView
                        // cardList={cardList}
                        onSelectCard={this.onSelectCard}
                      />
                    </MediaQuery>
                  </div> : null
              }

            </div>
        }

        {
          isSettingModal ?
            <SettingModal
              selectedDate={selectedDate}
              selectedRange={selectedRange}
              isAccountsLoading={isAccountsLoading}
              onCancel={this.onModalChange}
              onApplyingSetting={this.onApplyingSetting}
              onDateChange={this.onDateChange}
              onRangeDatesChange={this.onRangeDatesChange}
              getEnableCardsIds={getEnableCardsIds}
            /> : null
        }

        {
          isCalculationModal ?
            <RewardCalculationModal
              onClose={this.onCalculationModalChange}
              onCardChange={this.onCardChange}
            /> : null
        }

        {
          isCardDetailsModal ?
            <CardDetailsModal
              selectedCardInfo={selectedCardInfo || {}}
              onCancel={this.onSelectCard}
            /> : null
        }

      </div>
    )
  }
}

const mapStateToProps = state => ({
  state: state,
  isManualCost: state.costs.isManualCost || false,
  banks: state.accounts.banks || [],
  cardList: getCardsListByYearWise(cloneDeep(state.resultSetting.topIncludedIntroBonus ? state.cards?.cardsDetails?.results : state.cards?.cardsDetails?.noBonus_results), state.cards?.cardsDetails?.cards || {}, state.resultSetting.topYearProjection) || [],
  statics: state.cards.cardsDetails.transactions_statistics || [],
  avgSpending: state.cards.cardsDetails.avgSpending || "",
  spending: state.cards.cardsDetails.spending || "",
  fetchCardsLoaded: state.cards.fetchCardsLoaded || false,
  fetchCardsLoading: state.cards.fetchCardsLoading || false,
  fetchCardsFailure: state.cards.fetchCardsFailure || false,
  errorMessage: state.cards.errorMessage || false,
  fetchAccountsFailure: state.accounts.fetchAccountsFailure || false,
  topYearProjection: state.resultSetting.topYearProjection,
  topIncludedIntroBonus: state.resultSetting.topIncludedIntroBonus
});

const mapDispatchToProps = dispatch => {
  return {
    onSetCards: data => {
      dispatch(setCards(data));
    },
    onFetchCards: data => {
      dispatch(fetchCards(data));
    },
    onSetYearProjection: (data) => {
      dispatch(setResultSetting(data))
    }
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(SecondStep);
