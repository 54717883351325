import { put, call, takeLatest } from "redux-saga/effects";
import { request } from "../../../utils/fetch";
import { UserDetailsConstants } from "./userDetailsConstants";
import {getAPIEndpoint} from '../../../services/ApiService';

function* getUserDetails(action) {
  yield call(
    request({
      type: UserDetailsConstants.FETCH_USER_DETAILS,
      method: "get",
      url: getAPIEndpoint('/auth/authorize'),
      success: action.payload ? action.payload.onSuccess : null,
      fail: action.payload ? action.payload.onFail : null
    }),
    action
  );
}

function putUserDetails(payload) {
  put({
    type: "SET_USER_DETAILS",
    payload
  });
}

export default function* rootSaga() {
  yield takeLatest(UserDetailsConstants.FETCH_USER_DETAILS, getUserDetails);
  yield takeLatest(UserDetailsConstants.SET_USER_DETAILS, putUserDetails);
}
