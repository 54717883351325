import React from "react";
import {Modal, Spin, Input, message} from "antd";
import { ApiService } from "../../../../services/ApiService";
import {validateEmail} from '../../../../utils/constants';
import {fetchUserDetails} from "../../../../redux/modules/userDetails/userDetailsActions";

class ChangeEmailModal extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            email: "",
            password: "",
            isLoading: false
        }
    }

    onChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }
    
    onSubmitEmail = async () => {
        const {userDetails = {}} = this.props;
        const { password, email } = this.state
        if (!password || !email) {
            return message.error('Please enter both email and password!')
        }
        if (!validateEmail(email)) {
            return message.error('Please enter valid email id!')
        }
        this.setState({
            isLoading: true
        });
        const response = await ApiService.saveProfile(userDetails.id, {email: email.trim(), oldPassword: password});
        if (!response || response.error) {
            message.error(response?.error || 'Something went wrong while saving data. Please try again later!')
        } else {
            message.success('email saved successfully!')
            this.props.fetchUserDetails(response);
        }
        this.setState({
            isLoading: false
        })
    }

    render() {
        const { onClose } = this.props
        const { password, email, isLoading } = this.state
        return (
            <Modal
                visible={true}
                footer={null}
                width={400}
                className="unlink-account-modal"
                onCancel={() => onClose("isEmailModal")}
            >
                <div className="modal-header border-bottom-0 p-0 d-block">
                    <h5 className="modal-title p-card-title fs-22">
                        Change Email Address
                    </h5>
                </div>

                <div className="p-10">
                    <div className="mt-15">
                        <small>New Email</small>
                        <Input name="email" value={email} onChange={this.onChange} />
                    </div>
                    <div>
                        <small>Current Password</small>
                        <Input type="password" name="password" value={password} onChange={this.onChange} />
                    </div>
                </div>

                <div className="modal-footer border-top-0 justify-content-right mr-10">
                    <button
                        className={`btn luci-btn primary-btn font-weight-bold`}
                        onClick={this.onSubmitEmail}
                        disabled={isLoading}
                    >
                        {isLoading ? <Spin className="pr-10" /> : null }Save Email
                    </button>
                </div>
            </Modal>
        );
    }
}

export default ChangeEmailModal;
