import React from "react";
import moment from "moment";
import cloneDeep from "lodash/cloneDeep";
import {connect} from "react-redux";
import {Checkbox, Modal, Spin} from "antd";
import {dateFormat, getSelectedDate} from "../../../../../../utils/constants";
import {setCosts} from "../../../../../../redux/modules/manualCosts/costsActions";
import DateRangePicker from "./DateRangePicker"
import {setAccounts} from "../../../../../../redux/modules/accounts/accountsActions";


class SettingModal extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            banks: [],
            selectedDateType: props.selectedDate,
            selectedRange: props.selectedRange,
            isVisible: false
        }
    }

    componentDidMount() {
        this.setState({
            banks: cloneDeep(this.props.banks)
        })
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.isAccountsLoading && prevProps.fetchAccountsLoaded) {
            this.setState({
                banks: cloneDeep(prevProps.banks)
            })
        }
    }

    onDateTypeChange = (value) => {
        if(value === 6) {
            this.onVisibleChange(true)
        } else {
            this.onVisibleChange(false)
        }
        this.setState({
            selectedDateType: value
        })
    }

    onApplySetting = () => {
        const { onDateChange, onApplyingSetting, onRangeDatesChange } = this.props
        const { selectedRange, selectedDateType, banks } = this.state

        const date = {
            dateType: selectedDateType,
            date: selectedRange,
            month: selectedDateType === 1 ? 12 : selectedDateType === 2 ? 6 : selectedDateType === 3 ? 3 : selectedDateType === 4 ? 1 :selectedDateType === 7 ? 9 : null
        }
        localStorage.setItem("dates", JSON.stringify(date))
        this.props.onSetAccounts(banks);

        onDateChange(selectedDateType)
        if(selectedDateType === 6) {
            onRangeDatesChange(selectedRange)
        }
        onApplyingSetting()
    }

   onDateRangeChange = (selectedRanges) => {
       this.setState({
           selectedRange: selectedRanges
       });
   }

    getDateRange = () => {
        const { selectedDateType, selectedRange } = this.state
        if(selectedDateType === 6) {
            const date1 = ((selectedRange && selectedRange[0] && selectedRange[0].clone()) || (selectedRange && selectedRange[1] && selectedRange[1].clone()) || moment()).format(dateFormat);
            const date2 = ((selectedRange && selectedRange[1] && selectedRange[1].clone()) || (selectedRange && selectedRange[0] && selectedRange[0].clone()) || moment()).format(dateFormat);
            return `${date1} - ${date2}`;
        } else {
            return getSelectedDate(selectedDateType)
        }
    }

    onVisibleChange = (value) => {
        this.setState({
            isVisible: value
        })
    }

    onCardChange = (accountIndex, bankIndex) => (event) => {
        const banks = [
            ...this.state.banks
        ];
        const bank = banks[bankIndex];
        const account = bank.accounts[accountIndex];
        account.enabled = event.target.checked;

        this.setState({
            banks
        })
    }

    getEnableCardsIds = () => {
        const {banks} = this.state

        let length = 0
        banks.forEach(bank => {
            bank.accounts.forEach(acc => {
                if (acc.enabled) {
                    length += 1
                }
            })
        })

        return length
    }

    render() {
        const { onCancel, fetchCardsLoading, isAccountsLoading, fetchAccountsFailure, fetchCardsFailure } = this.props
        const { selectedDateType, selectedRange, isVisible, banks } = this.state
        const enableCards = this.getEnableCardsIds()
        const loadingFailed = fetchAccountsFailure || fetchCardsFailure
        const loading = fetchCardsLoading || isAccountsLoading || loadingFailed
        const disableButton = isVisible || !enableCards
        return (
            <Modal
                visible={true}
                footer={null}
                title="Change Settings"
                onCancel={onCancel}
                width={800}
                className="card-setting-modal"
            >
                <div className="row">
                    <div className="date-setting col-md-6 col-sm-12">
                        <b>Date Range:</b>
                        <span className="ml-1">{this.getDateRange()}</span>
                        <div
                            className={`range-title ${selectedDateType === 1 ? "selected" : ""}`}
                            onClick={() => this.onDateTypeChange(1)}
                        >
                            Max (12 Months)
                        </div>
                        {/*<div
                          className={`range-title ${selectedDateType === 7 ? "selected" : ""}`}
                          onClick={() => this.onDateTypeChange(7)}
                        >
                            9 Months
                        </div>
                        <div
                            className={`range-title ${selectedDateType === 2 ? "selected" : ""}`}
                            onClick={() => this.onDateTypeChange(2)}
                        >
                            6 Months
                        </div>
                        <div
                            className={`range-title ${selectedDateType === 3 ? "selected" : ""}`}
                            onClick={() => this.onDateTypeChange(3)}
                        >
                            3 Months
                        </div>
                        <div
                            className={`range-title ${selectedDateType === 4 ? "selected" : ""}`}
                            onClick={() => this.onDateTypeChange(4)}
                        >
                            1 Month
                        </div>
                        <div
                            className={`range-title ${selectedDateType === 6 ? "selected" : ""}`}
                            onClick={() => this.onDateTypeChange(6)}
                        >
                            Custom
                        </div>*/}

                        {
                            selectedDateType === 6 ?
                                <div>
                                    {
                                        isVisible ?
                                        <div id="dateSelector">
                                            <DateRangePicker
                                                selectedRange={selectedRange}
                                                onDateRangeChange={this.onDateRangeChange}
                                                onVisibleChange={this.onVisibleChange}
                                            />
                                        </div> : null
                                    }
                                    <div
                                        className="date-change-title"
                                        onClick={() => this.onVisibleChange(!isVisible)}
                                    >
                                        { isVisible ? "Hide" : "Change" } Date
                                    </div>
                                </div> : null
                        }
                    </div>

                    <div className="card-setting col-md-6 col-sm-12">
                        <b>Cards Enabled:</b>
                        <span className="ml-1">{enableCards} {enableCards > 1 ? "Cards" : "Card"}</span>
                        <div>
                            {
                                isAccountsLoading ?
                                    <div className="text-center mt-20">
                                        <Spin/>
                                    </div> :
                                    loadingFailed ?
                                        <div className="text-center mt-20">
                                            Cards fetching failed.
                                        </div> :
                                    (banks || []).map((bank, index) => {
                                        return(
                                            <div key={index.toString()} className="mt-15">
                                                <b>{bank.bankName}</b>
                                                {
                                                    (bank.accounts || []).map((account, i) => (
                                                        <div key={i.toString()} className="mt-1">
                                                            <Checkbox checked={account.enabled} onChange={this.onCardChange(i, index)}>{account.official_name || account.name}</Checkbox>
                                                        </div>
                                                    ))
                                                }
                                            </div>
                                        )
                                    })
                            }
                        </div>
                    </div>
                </div>
                <div className="mt-30 text-center">
                    <button
                        className={`btn luci-btn ${(fetchCardsLoading || isAccountsLoading || disableButton) ? "gray-btn" : "primary-btn shadow-btn" } font-weight-bold pt-3 pb-3`}
                        onClick={this.onApplySetting}
                        disabled={loading || disableButton}
                    >
                        Apply Settings
                    </button>
                </div>
            </Modal>
        );
    }
}

const mapStateToProps = state => ({
    banks: state.accounts.banks || [],
    fetchCardsLoading: state.cards.fetchCardsLoading || false,
    fetchAccountsFailure: state.accounts.fetchAccountsFailure || false,
    fetchCardsFailure: state.accounts.fetchCardsFailure || false,
    fetchAccountsLoaded: state.accounts.fetchAccountsLoaded || false
});

const mapDispatchToProps = (dispatch) => {
    return {
        onSetCosts: (data) => {
            dispatch(setCosts(data))
        },
        onSetAccounts: data => {
            dispatch(setAccounts(data));
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SettingModal);
