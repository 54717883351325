import React from "react";
import ReactHighcharts from 'react-highcharts';
import "./SpendingBehavior.scss";
import {getCategoriesColor, valueWithCommas} from "../../../../../../utils/constants";

const legend = {
  enabled: false,
  align: 'right',
  verticalAlign: 'top',
  floating: false,
  backgroundColor: 'white',
  borderColor: '#CCC',
  borderWidth: 1,
  shadow: false,
  outside: true
};

const toolTipStyle = {
  fontWeight: 'normal',
  fontSize: 16,
  backgroundColor: 'black',
  color: 'white',
};

const selectedBarBorderColor = '#BBCBEA';
const barColor = '#FFE05A';
const avgLineColor = '#FF8D5A';
const selectedLabelColor = '#FF8D5A';

const BarChart = ({onColumnChange, selectedType, selectedTime, weekObject, monthObject, selectedChartColumn}) => {
  
  const config = {
    chart: {
      type: 'column',
      height: '300px',
      style: {
        fontFamily: '"Poppins", sans-serif'
      },
      events: {
        load: function () {
          const axis = this.xAxis[0];
          const ticks = axis.ticks;
          const points = this.series[0].points;
          points.forEach(function (point, i) {
            if (ticks[i]) {
              var label = ticks[i].label.element
              label.onclick = function () {
                onColumnChange(i);
              }
            }
          });
        }
      }
    },
    title: {
      text: ''
    },
    xAxis: {
      min: 0,
      max: 5,
      labels: {
        style: {
          fontSize: '13px',
          cursor: 'pointer',
        },
        events: {
          click: function () {
            if (selectedChartColumn === this.pos) return;
            onColumnChange(this.pos)
          }
        }
      },
    },
    yAxis: {
      min: 0,
      title: {
        text: ''
      },
      labels: {
        formatter: function () {
          return `$${valueWithCommas(this.value || 0, '0')}`;
        }
      }
    },
    legend: {
      enabled: false
    },
    tooltip: {
      headerFormat: '',
      pointFormatter: function () {
        return `<div style="width: auto; padding: 0 5px;font-size: 16px;">$${valueWithCommas(this.y || 0)}</div>`
      },
      useHTML: true,
      backgroundColor: 'black',
      borderColor: 'black',
      borderRadius: 2,
      padding: 12,
      style: toolTipStyle
    },
    plotOptions: {
      column: {
        stacking: 'normal',
        dataLabels: {
          enabled: false
        }
      },
      series: {
        cursor: 'pointer',
        borderWidth: 0,
        animation: {
          duration: 0
        },
        events: {
          click: function (event) {
            if (selectedChartColumn === event.point.index) return;
            onColumnChange(event.point.index)
          }
        }
      },
      spline: {
        color: avgLineColor,
      }
    },
    series: []
  };
  
  const getSum = (data) => {
    let sum = 0;
    data.forEach(x => {
      x.data.forEach((a, i) => {
        if (i === selectedChartColumn) {
          sum += a.y
        }
      })
    })
    return sum;
  };
  
  const getChartConfig = () => {
    const array = selectedTime === "week" ? weekObject.time_buckets : monthObject.time_buckets;
    const averageSpend = selectedTime === "week" ? weekObject.spending_averages : monthObject.spending_averages;
    const categories = selectedTime === "week" ? [...(weekObject.category_order || [])] : [...(monthObject.category_order || [])];
    if (categories && Array.isArray(categories)) {
      categories.reverse()
    }
    if (!array.length) return {};
    const average = averageSpend?.All || 0;
    let keys = categories || Object.keys(array[0]?.sums).filter(x => x !== 'All');
    config.xAxis.categories = [];
    array.forEach((x, i) => {
      config.xAxis.categories.push(i == selectedChartColumn ? '<b style="color: '+selectedLabelColor+'; line-height: 18px; font-size: 14px; font-weight: bolder;">' + x.time_display + '</b>' : x.time_display)
    });
    
    if (selectedType === "category") {
      config.plotOptions.column.color = '';
      config.series = [];
      config.legend = legend;
      keys.map((x, i) => {
        const series = {name: x, data: [], type: 'column', zIndex: 1};
        series.data = array.map(point => {
          return {y: (point.sums && point.sums[x]) || 0, color: getCategoriesColor(x), borderWidth: 0};
        });
        config.series.push(series);
      });
      config.series.push({
        showInLegend: false,
        stacking: false,
        data: array.map((a, i) =>  i === selectedChartColumn ? getSum(config.series) : 0),
        grouping: false,
        color: 'transparent',
        borderWidth: 8,
        zIndex: 0,
        borderColor: selectedBarBorderColor
      });
      
    } else {
      config.plotOptions.column.color = barColor;
      config.legend = {
        enabled: false
      };
      config.series = [{data: []}];
      
      array.forEach((x, i) => {
        config.series[0].type = 'column'
        config.series[0].data.push(
          i === selectedChartColumn ?
            {y: x.sums.All, borderColor: selectedBarBorderColor, borderWidth: 5}
          :
          x.sums.All,
        )
      });
    }
    
    const splineSeries = {
      type: 'spline',
      name: 'Average',
      zIndex: 2,
      data: [{x: -0.5, y: average}, ...array.map(() => average)],
      enableMouseTracking: false,
      marker: {
        enabled: false
      },
      zoneAxis: 'x',
      zones: [{
        value: array.length - 1.5
      }, {
        value: array.length - 0.5,
        dashStyle: 'dot'
      }, {
        value: array.length,
        dashStyle: 'dot'
      }
      ],
    };
    
    splineSeries.data.push({x: 5.5, y: average});
    
    splineSeries.data[splineSeries.data.length - 2] = {
      y: average,
      dataLabels: {
        enabled: true,
        formatter: function () {
          return `Average: $${valueWithCommas(this.y || 0)}`
        },
        style: {
          fontSize: '12px',
          fontWeight: 'normal',
          textShadow: 'none'
        }
      }
    };
    
    
    config.series.push(splineSeries);
    console.log(JSON.stringify(config));
    return config;
  }
  
  return (
    <div className="chart-box">
      <ReactHighcharts config={getChartConfig()}></ReactHighcharts>
    </div>
  );
  
}

export default React.memo(BarChart)
