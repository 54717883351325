import React from "react";
import {Link} from "react-router-dom";
import Logo  from "../../../../assets/images/logo.svg"
import Instagram  from "../../../../assets/images/social/instagram.svg"
import Facebook  from "../../../../assets/images/social/facebook.svg"
import Twitter  from "../../../../assets/images/social/tweet.svg"


const Footer = () => {
  return (
    <footer>
      <div className="container">
        <div className="row">
          <div className="col-lg-3 col-sm-12 copyright">
            <img src={Logo} alt=""/>
              <span className="copyright-txt">© 2021 luci™</span>
          </div>
          <div className="col-lg-3 col-sm-12 links">
            <h4>Product</h4>
            <ul>
              <li>
                <a href="/#HowItWorks">How it works</a>
              </li>
              <li><Link to="/support">Support</Link></li>
            </ul>
          </div>
          <div className="col-lg-3 col-sm-12">
            <h4>Legal</h4>
            <ul>
              <li><Link to="/terms">Terms Of Service</Link></li>
              <li><Link to="/privacy-policy">Privacy Policy</Link></li>
              <li><Link to="/cookies-policy">Cookies Policy</Link></li>
            </ul>
          </div>
          <div className="col-lg-3 col-sm-12 social">
            <div className="social-links">
              <i><a href="https://www.facebook.com/smartluci" target="_blank"><img src={Facebook}  alt=""/></a></i>
              <i><a href="https://www.instagram.com/luci.smart.finance/" target="_blank"><img src={Instagram}  alt=""/></a></i>
              <i><a href="https://twitter.com/lucisupport" target="_blank"><img src={Twitter}  alt=""/></a></i>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
