import React from "react";
import moment from "moment";
import { Modal } from "antd";
import {valueWithCommas} from "../../../../../../utils/constants";
import "./SpendingBehavior.scss"


class RelevantTransactionsModal extends React.Component {

    render() {
        const { onClose, isVisible, dataList } = this.props
        return (
            <Modal
                visible={isVisible}
                footer={null}
                onCancel={onClose}
                style={{height: 350}}
                className="card-setting-modal relevant-transactions-modal"
            >
                <div className="spending-behavior">
                    <div className="spending-behavior-box border-0" style={{minHeight: "unset"}}>
                        <div className="spending-behavior-table">
                            <div className="table-content">
                                <div className="table-row">
                                    <div className="td-1 fw-600">Date</div>
                                    <div className="td-2 fw-600">Description</div>
                                    <div className="td-3 fw-600">Amount</div>
                                </div>
                                <div className="border-div"/>
                                <div className="scroll-table">
                                    {
                                        (dataList || []).length ? (dataList || []).map((detail, index) => {
                                            const {date, name, amount} = detail || {}
                                            return (
                                                <div className="table-row" key={index.toString()}>
                                                    <div className="td-1 pr-10 fs-12">
                                                        {moment(date).format('ll')}
                                                    </div>
                                                    <div className="td-2 fs-12">
                                                        {name}
                                                    </div>
                                                    <div className="td-3 fs-12">
                                                        <b>{`$${valueWithCommas(amount || 0)}`}</b>
                                                    </div>
                                                </div>
                                            )
                                        }) :
                                        <div className="text-center">
                                            No data found!
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        );
    }
}


export default RelevantTransactionsModal
