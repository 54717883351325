import React from "react";
import {connect} from "react-redux";
import cloneDeep from "lodash/cloneDeep";
import {Checkbox, Modal} from "antd";
import Close from "../../../../../../assets/images/dashboard/close.svg";
import {setCards} from "../../../../../../redux/modules/cards/cardsActions";

const array = ["isReward", "isIntro", "isAnnual"]

class RewardCalculationModal extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            calculation: {
                isReward: true,
                isIntro: true,
                isAnnual: true
            },
            prevCalculation: {
                isReward: true,
                isIntro: true,
                isAnnual: true
            }
        }
    }

    componentDidMount() {
        const { cardDetails: { cardsDetails: { results = [] } } = {} } = this.props
        this.setState({
            calculation: cloneDeep((results || []).length && results[0].calculation || {}),
            prevCalculation: cloneDeep((results || []).length && results[0].calculation || {})
        })
    }

    onChange = (event) => {
        this.setState({
            calculation: {
                ...this.state.calculation,
                [event.target.name]: event.target.checked
            }
        })
    }

    onApplySetting = () => {
        const { cardDetails, onSetCards, onClose, onCardChange } = this.props
        const { calculation } = this.state

        cardDetails.cardsDetails.results.forEach(card => {
            card.calculation = calculation
            card.totalMoney = this.getTotal(cloneDeep(card))
        })

        onSetCards(cardDetails.cardsDetails)
        setTimeout(() => {
            localStorage.setItem("LUCI_DATAS", JSON.stringify(this.props.state))
            onCardChange(0)
            onClose()
        }, 100)
    }

    getTotal = (card) => {
        const { totalMoney, introMoney, money, card: { annualFee } = {} } = card || {}
        const { calculation, prevCalculation } = this.state

        let total = cloneDeep(totalMoney)

        array.forEach(key => {
            if(calculation[key] !== prevCalculation[key]) {
                if(key === "isAnnual") {
                    if(!calculation[key]) {
                        total += annualFee
                    } else {
                        total -= annualFee
                    }
                } else {
                    const fee = key === "isReward" ? money : introMoney
                    if(calculation[key]) {
                        total += fee
                    } else {
                        total -= fee
                    }
                }
            }
        })

        return total
    }

    render() {
        const { onClose  } = this.props;
        const { calculation, errorMessage } = this.state;
        const { isReward, isIntro, isAnnual } = calculation || {};
        const disable = !(calculation && Object.values(calculation || {}).filter(x => x).length)
        return (
            <Modal
                visible={true}
                footer={null}
                width={500}
                className="find-cards-modal"
                closable={false}
            >
                <div className="modal-header border-bottom-0 p-0">
                    <h5 className="modal-title p-card-title">
                        Reward Calculation Settings
                    </h5>
                    <button type="button" className="close" onClick={onClose}>
                        <img src={Close}/>
                    </button>
                </div>
                <div className="mt-20">
                    <div className="rewards-calculation">
                        <div className="mb-10">
                            <b>Include</b>
                        </div>
                        <p>
                            <Checkbox
                                name="isReward"
                                checked={isReward}
                                onChange={this.onChange}
                            >
                                Reward from Transactions
                            </Checkbox>
                        </p>
                        <p>
                            <Checkbox
                                name="isIntro"
                                checked={isIntro}
                                onChange={this.onChange}
                            >
                                Intro Bonus
                            </Checkbox>
                        </p>
                        <p>
                            <Checkbox
                                name="isAnnual"
                                checked={isAnnual}
                                onChange={this.onChange}
                            >
                                Annual Fee
                            </Checkbox>
                        </p>
                    </div>
                </div>
                { errorMessage ? <div className="text-center"><small className="text-danger">{errorMessage}</small></div> : null }
                <div className="modal-footer border-top-0 justify-content-center">
                    <button
                        className={`btn luci-btn ${(disable) ? "gray-btn cursor-not-allowed" : "primary-btn" } font-weight-bold pt-3 pb-3`}
                        type="submit"
                        onClick={this.onApplySetting}
                        disabled={disable}
                        data-dismiss="modal"
                    >
                        Apply Settings
                    </button>
                </div>
            </Modal>
        );
    }
}

const mapStateToProps = state => ({
    state: state || {},
    cardDetails: state.cards || {}
});

const mapDispatchToProps = (dispatch) => {
    return {
        onSetCards: data => {
            dispatch(setCards(data));
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(RewardCalculationModal);
