import React from "react";
import moment from "moment";
import { DatePicker, Button } from "antd"

const { RangePicker } = DatePicker

class DateRangePicker extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            selectedRange: props.selectedRange
        }
    }

    onDatesRangeChange = (selectedRanges) => {
        const {selectedRange} = this.state;
        if (selectedRanges && (selectedRange && selectedRange[0] && selectedRange[0].format('YYYYMMDD')) !== (selectedRanges[0] && selectedRanges[0].format('YYYYMMDD'))) {
            selectedRanges[1] = null;
        } else if (selectedRanges && !selectedRanges[0] && selectedRanges[1]) {
            selectedRanges = null;
        }
        this.setState({
            selectedRange: selectedRanges,
            errorMessage: ""
        });
    }

    onDone = () => {
        const { selectedRange } = this.state
        if((selectedRange || []).length === 2) {
            const from = moment(selectedRange[0] || "")
            const to = moment(selectedRange[1] || "")

            const total = Math.abs(from.diff(to, "days"))
            let errorMessage = ""
            if(total + 1 < 30) {
                errorMessage = "Minimum date range is 30 days"
            } else if(total > 365) {
                errorMessage = "difference between the start and end dates must be smaller than 365 days."
            }

            if(errorMessage) {
                return this.setState({errorMessage})
            }
        }
        this.props.onDateRangeChange(selectedRange)
        this.props.onVisibleChange(false)
    }

    disabledDate = (current) => {
        return (current && current > moment())
    }

    render() {
        const { selectedRange, errorMessage } = this.state
        return(
            <RangePicker
                position="bottom"
                value={selectedRange}
                open={true}
                inputReadOnly
                format="MM/DD/YYYY"
                disabledDate={this.disabledDate}
                getPopupContainer={() => document.getElementById('dateSelector')}
                onCalendarChange={value => this.onDatesRangeChange(value)}
                renderExtraFooter={
                    () => <div className="run-test">
                        { errorMessage ? <><small className="text-danger">{errorMessage}</small><br/></> : null }
                        <Button onClick={this.onDone}>Done</Button>
                    </div>
                }
            />
        )
    }
}

export default DateRangePicker