import React from "react";
import Footer from "./Footer";
import Header from "./Header";
import Main from "../../../pages/Main";
import "../../styles/style.scss"

const AppLayout = (props) => {
  return (
    <div>
      <Header {...props} signIn={true} buttonTitle="Join for Free" path="/signup"/>
      <Main/>
      <Footer />
    </div>
  );
};

export default AppLayout;
