const envType = process.env.REACT_APP_ENV || process.env.NODE_ENV || 'development';

const config = {
  development: {
    currentAddress: 'http://localhost:3000',
    // serverAddress: ' https://sandbox-api.joinluci.com:443',
    serverAddress: ' http://localhost:6800',
    storageUser: 'user',
    storageToken: 'accessToken',
    storageRefresh: 'refreshToken',
    plaid: {
      clientName: 'luci Smart Finance',
      env: 'sandbox',
      product: ['transactions']
    }
  },
  stage: {
    currentAddress: 'https://staging.joinluci.com',
    serverAddress: 'https://staging-api.joinluci.com',
    storageUser: 'user',
    storageToken: 'accessToken',
    storageRefresh: 'refreshToken',
    plaid: {
      clientName: 'luci Smart Finance',
      env: 'development',
      product: ['transactions']
    }
  },
  production: {
    currentAddress: 'https://joinluci.com',
    serverAddress: 'https://api.joinluci.com',
    storageUser: 'user',
    storageToken: 'accessToken',
    storageRefresh: 'refreshToken',
    plaid: {
      clientName: 'luci Smart Finance',
      env: 'production',
      product: ['transactions']
    }
  }
};

export default config[envType];
