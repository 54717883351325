import React from "react";
import {connect} from "react-redux";
import Header from "../../common/components/AppLayout/Header";
import {setUserDetails} from "../../redux/modules/userDetails/userDetailsActions";
import ErrorImage from "../../assets/images/il_404.svg";
import "../../common/styles/form.scss";
import "../../common/styles/_components.scss";


class GeneralError extends React.Component {
    render() {
        return(
            <div className="page-not-found">
                <Header {...this.props} hideHumburger={true} />

                <section className="banner">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-lg-6 banner-left">
                                <div className="banner-text">
                                    <h1>Oops!</h1>
                                    <p className="ml-1">{((this.props.location || {}).state || {}).error || "Something went wrong"}</p>
                                </div>
                                <div className="btn-area mt-5">
                                    <button
                                        className="btn luci-btn primary-btn font-weight-bold pt-3 pb-3"
                                        type="submit"
                                        onClick={() => this.props.history.push("/")}
                                    >
                                        Go Home
                                    </button>
                                </div>
                            </div>
                            <div className="col-12 col-lg-6 banner-right">
                                <div className="card-area text-right">
                                    <figure className="card-lg mt-0">
                                        <img className="w-100" src={ErrorImage} alt="" />
                                    </figure>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <footer className="footer-bg">
                    <div className="container">
                    </div>
                </footer>
            </div>
        )
    }

}

const mapDispatchToProps = (dispatch) => {
    return {
        onSetUserInfo: (data) => {
            dispatch(setUserDetails(data))
        }
    }
}

export default connect(null,mapDispatchToProps)(GeneralError)
