import { createAction } from "redux-actions";
import { CardsConstants } from "./cardsConstants";

export const fetchCards = createAction(
  CardsConstants.FETCH_CARDS
);

export const setCards = createAction(
  CardsConstants.SET_CARDS
);
