import React from "react";
import { connect } from "react-redux";
import { Input, Button, message } from "antd";
import Header from "../../../common/components/AppLayout/Header";
import ChangeEmailModal from "./components/ChangeEmailModal"
import ChangePasswordModal from "./components/ChangePasswordModal"
import { ApiService } from "../../../services/ApiService";
import { fetchUserDetails } from "../../../redux/modules/userDetails/userDetailsActions";
import TrashBin from "../../../assets/images/trash-bin.svg"
import "../../../common/styles/form.scss";
import "../../../common/styles/_components.scss";


class MyAccount extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            userDetails: {
                firstName: '',
                lastName: '',
                email: ''
            },
            isEmailModal: false,
            isPasswordModal: false,
            isNameSaving: false
        }
    }

    componentWillMount() {
        this.setState({
            userDetails: this.props.currentUser
        })
    }

    onChange = (e) => {
        this.setState({
            userDetails: {
                ...this.state.userDetails,
                [e.target.name]: e.target.value
            }
        })
    }

    onModalChange = (key) => {
        this.setState({
            [key]: !this.state[key]
        })
    }
    
    onSubmitUserName = async () => {
        const {userDetails = {}} = this.state;
        if (!userDetails.firstName || !userDetails.lastName) {
            return message.error('Please enter both first and last name')
        }
        this.setState({
           isNameSaving: true
        });
        const response = await ApiService.saveProfile(userDetails.id, {firstName: userDetails.firstName, lastName: userDetails.lastName});
        if (!response || response.error) {
            message.error(response?.error || 'Something went wrong while saving data. Please try again later!')
        } else {
            message.success('Fist name and Last name saved successfully!')
            this.props.fetchUserDetails();
        }
        this.setState({
            isNameSaving: false
        })
    }
    
    onUpdate = (userDetails) => {
        this.props.fetchUserDetails();
        this.setState({
            userDetails: userDetails || this.state.userDetails,
            isEmailModal: false,
            isPasswordModal: false
        })
    }
    
    render() {
        const { userDetails, isEmailModal, isPasswordModal, isNameSaving } = this.state
        const { firstName = "", lastName = "", email = "", isGoogle = false } = userDetails || {}

        return(
            <div className="your-account">
                <Header {...this.props} />

                <section className="banner">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-lg-6">
                                <div className="banner-text">
                                    <h1>My Account</h1>
                                </div>
                            </div>
                        </div>

                        <div className="account-setting">
                            {/*<h4>Account Settings</h4>*/}

                            <div className="profile-info mt-30">
                                <div className="profile-info-label">PROFILE INFORMATION</div>

                                <div className="name-input">
                                    <div className="d-flex">
                                        <div className="mr-15">
                                            <small>First Name</small>
                                            <Input name="firstName" value={firstName} onChange={this.onChange} />
                                        </div>
                                        <div>
                                            <small>Last Name</small>
                                            <Input name="lastName" value={lastName} onChange={this.onChange} />
                                        </div>
                                    </div>
                                    <div className="save-btn">
                                        <Button loading={isNameSaving} onClick={this.onSubmitUserName}>Save</Button>
                                    </div>
                                </div>
                            </div>

                            <div className="profile-info mt-30">
                                <div className="profile-info-label">ACCOUNT PREFERENCES</div>

                                <div className="d-flex mt-40 justify-content-between">
                                    <div>
                                        <h5>Email Address</h5>
                                        <div>{email}</div>
                                    </div>
                                    {
                                        !isGoogle ?
                                            <div className="align-self-end">
                                                <Button onClick={() => this.onModalChange("isEmailModal")}>Change</Button>
                                            </div> : null
                                    }
                                </div>

                                {
                                    !isGoogle ?
                                        <div className="d-flex mt-40 justify-content-between">
                                            <div>
                                                <h5>Change Password</h5>
                                                <div>Password must be at least 6 characters long</div>
                                            </div>
                                            <div className="align-self-end">
                                                <Button onClick={() => this.onModalChange("isPasswordModal")}>Change</Button>
                                            </div>
                                        </div> : null
                                }

                            </div>

                           {/* <div className="profile-info mt-30">
                                <div className="profile-info-label">DEACTIVATE ACCOUNT</div>

                                <div className="mt-20 deactivate">
                                    <img src={TrashBin} className="cursor-pointer" />
                                    <span className="ml-1 cursor-pointer">DEACTIVATE ACCOUNT</span>
                                </div>
                            </div>*/}

                        </div>
                    </div>
                </section>

                { isEmailModal ? <ChangeEmailModal userDetails={this.state.userDetails} fetchUserDetails={this.onUpdate} onClose={this.onModalChange} /> : null }
                { isPasswordModal ? <ChangePasswordModal userDetails={this.state.userDetails} onClose={this.onModalChange} /> : null }
            </div>
        )
    }

}

const mapStateToProps = state => ({
    currentUser: state.userDetails.currentUser || {}
})

const mapDispatchToProps = (dispatch) => {
    return {
        fetchUserDetails: (data) => {
            dispatch(fetchUserDetails(data))
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MyAccount)
