import { combineReducers } from "redux";
import { all } from "redux-saga/effects";
import { connectRouter } from "connected-react-router";
import {userDetailsReducer} from "./userDetails/userDetailsReducer";
import userDetailsSaga from "./userDetails/userDetailsSaga";
import accountsSaga from "./accounts/accountsSaga";
import cardsSaga from "./cards/cardsSaga";
import {accountsReducer} from "./accounts/accountsReducer";
import {costsReducer} from "./manualCosts/costsReducer";
import {cardsReducer} from "./cards/cardsReducer";
import {resultSettingReducer} from "./resultSetting/resultSettingReducer";
import {dashboardStepReducer} from "./dashboardStep/dashboardStepReducer";

export const reducers = history =>
  combineReducers({
    router: connectRouter(history),
    userDetails: userDetailsReducer,
    accounts: accountsReducer,
    costs: costsReducer,
    cards: cardsReducer,
    resultSetting: resultSettingReducer,
    dashboardStep: dashboardStepReducer
  });

export const rootSaga = function*() {
  yield all([
    userDetailsSaga(),
    accountsSaga(),
    cardsSaga()
  ]);
};
