import React from "react"
import {firstYear, lastYear, secondYear} from "../../../../../../utils/constants"


class YearProjection extends React.Component {

    render() {
        const { yearProjection, onYearProjectionChange } = this.props
        return(
            <div className="year-projection">
                <div
                    className={`first-year ${yearProjection === firstYear() ? "selected" : ""}`}
                    onClick={() => onYearProjectionChange(firstYear())}
                >
                    1-Year Projection
                </div>
                <div
                    className={`second-year ${yearProjection === secondYear() ? "selected" : ""}`}
                    onClick={() => onYearProjectionChange(secondYear())}
                >
                    2-Year Projection
                </div>
                <div
                    className={`last-year ${yearProjection === lastYear() ? "selected" : ""}`}
                    onClick={() => onYearProjectionChange(lastYear())}
                >
                    3-Year Projection
                </div>
            </div>
        )
    }
}

export default YearProjection
