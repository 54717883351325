import React from "react";
import {connect} from "react-redux";
import EYE from "../../assets/images/Inner/eye.svg";
import Header from "../../common/components/AppLayout/Header";
import {ApiService} from "../../services/ApiService";
import {setUserDetails} from "../../redux/modules/userDetails/userDetailsActions";
import {getPasswordStrengthClass, validatePassword} from "../../utils/constants";
import "../../common/styles/form.scss";
import "../../common/styles/_components.scss";

class RestorePassword extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            errorMessage: "",
            successMessage: "",
            newPassword: "",
            confirmPassword: "",
            showPassword: false,
            showConfirmPassword: false
        }
    }

    onChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value,
            errorMessage: ""
        })
    }

    onResetPassword = async () => {
        const { newPassword, confirmPassword } = this.state
        if(newPassword !== confirmPassword) {
            return this.setState({errorMessage: "New password and confirm password do not match"})
        }
        
        if (validatePassword(newPassword) !== true) {
            return this.setState({errorMessage: validatePassword(newPassword)})
        }
        

        const token = ((this.props.match || {}).params || {}).token || ""
        const response = await ApiService.passwordReset(token,{password: newPassword})
        if(!response || response.error) {
            this.props.history.push({
                pathname: '/oops',
                state: { error: `${(response.error || "").replace("!", "") || "Something went wrong"}, please try again` }
            })
            this.setState({
                errorMessage: response.error || ""
            })
        } else {
            this.setState({
                successMessage: "Your password has been reset successfully"
            });
            setTimeout(() => {
                this.props.history.push("/signin")
            }, 5000);
        }
    }

    render() {
        const { errorMessage, newPassword, confirmPassword, showPassword, showConfirmPassword, successMessage } = this.state;
        const disable = !newPassword || !confirmPassword
        const passwordClass = getPasswordStrengthClass(newPassword)
        return(
            <div>
                <Header unAuth buttonTitle="Join" path="/signup"/>
                <section className="form-area">
                    <h1>Reset Password</h1>
                    <div className="form-items">

                        <div className="form-group password-input mb-4">
                            <input
                                type={showPassword ? "text" : "password"}
                                name="newPassword"
                                placeholder="New Password"
                                className={`luci-input ${newPassword?.trim() ? 'filled' : ''}`}
                                value={newPassword}
                                onChange={this.onChange}
                            />
                            <img src={EYE} className="pwd-eye" onClick={() => this.setState({showPassword: !showPassword})}/>
                        </div>

                        <div className="form-group password-input mb-4">
                            <input
                                type={showConfirmPassword ? "text" : "password"}
                                name="confirmPassword"
                                placeholder="Confirm Password"
                                className={`luci-input ${confirmPassword?.trim() ? 'filled' : ''}`}
                                value={confirmPassword}
                                onChange={this.onChange}
                            />
                            <img src={EYE} className="pwd-eye" onClick={() => this.setState({showConfirmPassword: !showConfirmPassword})}/>
                        </div>

                        {/*<div className={`password-strength mb-3 ${passwordClass}`}>
                            <span className="s-fill"/>
                            <span className="s-fill"/>
                            <span className="s-fill"/>
                            <span className="s-fill"/>
                            <span className="s-fill"/>
                            <span className="ps-text">{passwordClass}</span>
                        </div>*/}

                        {
                            (errorMessage || successMessage) ?
                                <div className="text-center mb-2">
                                    <small className={errorMessage ? "text-danger" : "text-success"}>{errorMessage || successMessage}</small>
                                </div> : null
                        }

                        <div className="form-group">
                            <button
                                className={`btn luci-btn ${(disable || errorMessage) ? "gray-btn cursor-not-allowed" : "primary-btn" } font-weight-bold pt-3 pb-3 w-100 submit-btn`}
                                type="submit"
                                onClick={this.onResetPassword}
                                disabled={disable}
                            >
                                Reset Password
                            </button>
                        </div>

                    </div>
                </section>
                <footer className="footer-bg">
                    <div className="container">
                    </div>
                </footer>
            </div>
        )
    }

}

const mapDispatchToProps = (dispatch) => {
    return {
        onSetUserInfo: (data) => {
            dispatch(setUserDetails(data))
        }
    }
}

export default connect(null,mapDispatchToProps)(RestorePassword)
