import axios from "axios";
import config from "../config";
import { clearStorage } from '../utils/constants';

export const axiosInstance = axios.create({
  baseURL: '',
});

axiosInstance.interceptors.response.use((response) => {
  if(response.status === 404 || response.status === 500) {
    window.location.href = '/oops';
  }
  return response;
}, (error) => {
  if (error.response?.status === 404 || error.response?.status === 500) {
    return window.location.href = '/oops';
  }
  if (error.response?.status === 401) {
    clearStorage();
    return window.location.href = '/';
  }
  return Promise.reject(error);
});

export const onLogOut = () => {
  clearStorage();
  window.location.href = '/'
}

export const getAuthToken = () => localStorage.getItem('AUTH_TOKEN');

export const clearAuthToken = () => localStorage.removeItem('AUTH_TOKEN');

export const getAPIEndpoint = (endpoint) => `${config.serverAddress}/api${endpoint || ''}`;

export const getAuthHeaders = () => ({ Authorization: `Bearer ${getAuthToken()}` });

const returnPromise = async (data, needError) => {
  return await new Promise((resolve, reject) => setTimeout(() => needError ? reject({error: true}) : resolve(data), 2000));
}

const cardList = [
  {cardName: 'Citi® Single Cash Card', total: "$120", reward: "20", introBonus: "$100"},
  {cardName: 'Citi® Double Cash Card', total: "$95", reward: "25", introBonus: "$70"}
]

export class ApiService {

  static async getData(url, headers, cancelToken, data) {
    const config = {
      headers: {
        ...getAuthHeaders(),
        ...(headers || {}),
      },
    };
    if (data) {
      config.data = data;
    }
    let resData = null;
    const response = await axiosInstance.get(url, config).catch((thrown) => {
      const data = thrown?.response?.data || {};
      if((data.errors || []).length) {
        resData = { error: ((data.errors[0] || {}).msg || (data.errors[0] || {}).message || 'something went wrong'), errObj: data }
      } else {
        resData = { error: (data.msg || data.message || 'something went wrong'), errObj: data }
      }
    });
    return resData || response.data;
  }

  static async postMethod(url, data, headers, cancelToken) {
    const config = {
      headers: {
        ...getAuthHeaders(),
        ...(headers || {})
      }
    };
    if (cancelToken && cancelToken.token) {
      config.cancelToken = cancelToken.token;
    }
    let resData = null;
    const response = await axiosInstance.post(url, data, config).catch(thrown => {
      const data = thrown?.response?.data || {};
      if((data.errors || []).length) {
        resData = { error: ((data.errors[0] || {}).msg || (data.errors[0] || {}).message || 'something went wrong'), errObj: data }
      } else {
        resData = { error: (data.msg || data.message || 'something went wrong'), errObj: data }
      }
    });
    return resData || response.data;
  }
  
  static async putMethod(url, data, headers, cancelToken) {
    const config = {
      headers: {
        ...getAuthHeaders(),
        ...(headers || {})
      }
    };
    if (cancelToken && cancelToken.token) {
      config.cancelToken = cancelToken.token;
    }
    let resData = null;
    const response = await axiosInstance.put(url, data, config).catch(thrown => {
      if(((((thrown || {}).response || {}).data || {}).errors || []).length) {
        resData = { error: (thrown.response.data.errors[0] || {}).msg || (thrown.response.data.errors[0] || {}).message || 'something went wrong' }
      } else {
        resData = { error: (((thrown || {}).response || {}).data || {}).msg || (((thrown || {}).response || {}).data || {}).message || 'something went wrong' }
      }
    });
    return resData || response.data;
  }

  static async signIn(body) {
    return await ApiService.postMethod(`${getAPIEndpoint()}/auth/sign-in`, body);
  }

  static async signUp(body) {
    return await ApiService.postMethod(`${getAPIEndpoint()}/auth/sign-up`, body);
  }

  static async passwordReset(token, body) {
    return await ApiService.postMethod(`${getAPIEndpoint()}/auth/password-reset/${token}`, body);
  }

  static async forgotPassword(body) {
    return await ApiService.postMethod(`${getAPIEndpoint()}/auth/password-restore`, body);
  }

  static async getUserDetails() {
    return await ApiService.getData(`${getAPIEndpoint()}/auth/authorize`);
  }
  
  static async setPlaidAccount(publicKey, data) {
    return await ApiService.postMethod(`${getAPIEndpoint()}/plaid/auth/add-token?publicToken=${publicKey}`, data);
  }
  
  static async handlePlaidError(body) {
    return await ApiService.postMethod(`${getAPIEndpoint()}/plaid/auth/plaid-error`, body);
  }
  
  static async getPlaidAccount() {
    return await ApiService.getData(`${getAPIEndpoint()}/plaid/auth/accounts`);
  }
  
  static async getTransactions() {
    return await ApiService.getData(`${getAPIEndpoint()}/plaid/auth/transactions`);
  }
  
  static async runBackTest(query, data) {
    return await ApiService.postMethod(`${getAPIEndpoint()}/plaid/auth/run-backtest${query}`, data);
  }
  
  static async getAccounts(accounts) {
    return await ApiService.getData(`/mockdata/${accounts}.json`);
  }
  
  static async unlinkAccount() {
    return await ApiService.postMethod(`${getAPIEndpoint()}/plaid/auth/accounts-unlink-all`, {});
  }
  
  static async saveProfile(userId, data) {
    return await ApiService.putMethod(`${getAPIEndpoint()}/users/${userId}`, data);
  }

  static async getCardList() {
    return await returnPromise(cardList);
  }

  static async refreshAccessToken({accessToken}) {
    return await ApiService.postMethod(`${getAPIEndpoint()}/plaid/auth/create-link-token?accessToken=${accessToken}`);
  }

  static async removeAccessToken({accessToken}) {
    return await ApiService.postMethod(`${getAPIEndpoint()}/plaid/auth/remove-access-token?accessToken=${accessToken}`);
  }

  static async continueWithGoogle() {
    window.location.href = `${getAPIEndpoint()}/auth/google`
  }

  static async createLinkToken() {
    return await ApiService.postMethod(`${getAPIEndpoint()}/plaid/auth/create-link-token`, {});
  }

}
