import React from "react";
import { connect } from "react-redux";
import moment from "moment";
import {cloneDeep} from "lodash";
import Header from "../Header";
import Sidebar from "../Sidebar";
import FirstStep from "./Components/FirstStep";
import SecondStep from "./Components/SecondStep";
import PlaidErrorModal from "./Components/FirstStep/component/PlaidErrorModal";
import NoCreditCardModal from "./Components/FirstStep/component/NoCreditCardModal";
import { ApiService } from "../../../services/ApiService";
import { setAccounts } from "../../../redux/modules/accounts/accountsActions";
import { setCosts } from "../../../redux/modules/manualCosts/costsActions";
import FindCardModal from "./Components/FirstStep/component/FindCardModal";
import UsingPlaidModal from "./Components/UsingPlaidModal";
import { fetchCards, setCards } from "../../../redux/modules/cards/cardsActions";
import { setDashboardStep } from "../../../redux/modules/dashboardStep/dashboardStepActions";
import {setResultSetting} from "../../../redux/modules/resultSetting/resultSettingActions";
import {
  getStoredAccounts,
  getStoredCosts,
  removeComma,
  getDateQuery,
  getDateRanges,
  getCardsListByYearWise,
  isUserLoggedIn,
  getStoredCards,
  getManualStoredValue
} from "../../../utils/constants";
import "../../../common/styles/dashboard.scss";


class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentStep: 0,
      isFindCardModal: false,
      isAccountsLoading: false,
      isCardRequest: false,
      isFindingCards: false,
      resettingCard: false,
      isPlaidPopUp: false,
      plaidPopUpErrorMsg: "",
      isUsingPlaidPopUp: false,
      isNoCreditCardPopUp: false,
      isNewUser: false,
      institutionName: '',
      debug: null
    };
  }

  async componentDidMount() {
    const { fetchAccountsLoaded, cardList, fetchCardsLoaded, isFirstStepComplete } = this.props

    await this.onCreateLinkToken()
    if (!fetchAccountsLoaded) {
      if(!!(getStoredAccounts() || []).length || (getStoredCosts() || {}).isManualCost) {
        this.gotoSecondStep()
      }
      if((getStoredCosts() || {}).isManualCost) {
        this.props.onSetCosts(getStoredCosts() || {})
        if(fetchCardsLoaded && isFirstStepComplete) {
          this.gotoSecondStep()
        } else {
          this.setState({isFindingCards: true})
          setTimeout(() => {
            this.onFindingCards()
          }, 500)
        }
      }
      this.getAccountService(false);
    } else if(fetchCardsLoaded && isFirstStepComplete) {
      this.gotoSecondStep()
    } else if(!this.isAccountAvailable() && !isUserLoggedIn()) {
      localStorage.setItem("isUserLogin", "yes")
      setTimeout(() => {
        this.setState({
          isUsingPlaidPopUp: true,
          isNewUser: true
        })
      }, 500)
    } else if(Object.keys(getStoredCards() || {}).length) {
      this.gotoSecondStep()
    }
  }

  onCreateLinkToken = async () => {
    this.props.onSetYearProjection({linkToken: ""})
    const response = await ApiService.createLinkToken();
    if((response || {}).error) {
      localStorage.setItem("link-token", "")
    } else {
      localStorage.setItem("link-token", response || "")
      this.props.onSetYearProjection({linkToken: response || ""})
    }
  }

  getAccountService = async (isAddingAccount) => {
    this.setState({
      isAccountsLoading: true,
    });
    const { banks } = this.props;
    let response = await ApiService.getPlaidAccount();
    if (!response || response.error) {
      if (response?.errObj?.errors && response.errObj.errors[0]?.param?.error_type === 'popup') {
        this.setState({
          isAccountsLoading: false,
          isPlaidPopUp: true,
          institutionName: response.errObj.errors[0]?.param?.institutionName
        });
        return;
      }
      this.setState({
        isAccountsLoading: false,
      });
      return;
    }

    if(isAddingAccount) {

      let accountsArray = []

      banks.forEach(bank => {
        bank.accounts.forEach(account => {
          accountsArray.push(account)
        })
      })

      response.forEach(x => {
        const index = (accountsArray || []).findIndex(account => account.account_id === x.account_id)
        if(index !== -1) {
          x.enabled = accountsArray[index].enabled
        } else {
          x.enabled = true
        }
      })

    } else {
      response = (response || []).map(a => ({ ...a, enabled: true }));
    }

    const debitCardAccounts = response.filter(x => x.subtype === "checking");
    const creditCardAccounts = response.filter(x => x.subtype === "credit card");
    banks[0].accounts = debitCardAccounts;
    // banks[0].accounts = [];
    banks[1].accounts = creditCardAccounts;
    this.props.onSetAccounts([...banks]);

    /*****************  when user comes first time after sign up *****************/

    if(!this.isAccountAvailable() && !isUserLoggedIn() && !isAddingAccount) {
      localStorage.setItem("isUserLogin", "yes")
      setTimeout(() => {
        this.setState({
          isUsingPlaidPopUp: true,
          isNewUser: true
        })
      }, 500)
    }
    /*****************  --------------- *****************/

    this.setState({
      isAccountsLoading: false
    });

    localStorage.setItem("LUCI_DATAS", JSON.stringify(this.props.state))

    window.scrollTo(0,0);
  };
  
  setStateChange = (states) => {
    this.setState({
      ...states
    })
  }

  onStepChange = step => {
    const { currentStep } = this.state
    if(currentStep === step) return;

    if(step === 1 && !this.isAccountAvailable() && !Object.keys(getStoredCards() || {}).length) {
      this.onAllCardsActive()

      this.setState({
        isUsingPlaidPopUp: true
      })
    } else {
      if(step === 1) {
        this.onAllCardsActive()
      }
      this.setState({
        currentStep: step
      }, () => window.scrollTo(0, 0));
    }
    setTimeout(() => document.body.style.overflow = '', 300);
  };

  onAllCardsActive = () => {
    const { banks, onSetAccounts, onSetCosts, costs } = this.props
    if(!this.getCardsEnable() && !getManualStoredValue(costs)) {
      banks.forEach(bank => {
        bank.accounts.forEach(account => {
          account.enabled = true
        })
      })
      onSetAccounts(banks);
      onSetCosts({ isManualCost: false });
      localStorage.setItem("LUCI_DATAS", JSON.stringify(this.props.state))
    } else if(!!getManualStoredValue(costs) && !costs.isManualCost && !this.getCardsEnable()) {
      this.onFindingCards()
      onSetCosts({ isManualCost: true });
      localStorage.setItem("LUCI_DATAS", JSON.stringify(this.props.state))
    } else if(this.getCardsEnable()) {
      onSetCosts({ isManualCost: false });
      localStorage.setItem("LUCI_DATAS", JSON.stringify(this.props.state))
    } else if(!this.getCardsEnable() && getManualStoredValue(costs)) {
      this.onFindingCards()
      onSetCosts({ isManualCost: true });
      localStorage.setItem("LUCI_DATAS", JSON.stringify(this.props.state))
    }
  }

  getCardsEnable = () => {
    const { banks = [] } = this.props;
    let isEnable = false;
    banks.forEach(bank => {
      const length = (bank.accounts || []).filter(acc => acc.enabled).length;
      if (length) {
        isEnable = true
      }
    })
    return isEnable
  }

  onShowBankDetails = () => {
    
    // console.log("TEST"); return; // fdsa
    
    
    const { isFirstStepComplete, onSetCosts } = this.props

    if(!this.isAccountAvailable() && !Object.keys(getStoredCards() || {}).length) {
      return this.setState({isUsingPlaidPopUp: true})
    }

    if(Object.keys(getStoredCosts() || {}).length || !isFirstStepComplete) {
      this.onFetchingCards()
    }
    this.gotoSecondStep()
    onSetCosts({ isManualCost: false });
    // setTimeout(() => localStorage.setItem("costs", JSON.stringify(this.props.costs)), 500)
  };

  onCardRequestChange = (value) => {
    this.setState({
      isCardRequest: value
    })
  }

  getEnableCardsIds = () => {
    const {banks} = this.props

    let ids = []
    banks.forEach(bank => {
      bank.accounts.forEach(acc => {
        if (acc.enabled) {
          ids.push(`account=${acc.account_id}`)
        }
      })
    })

    return ids || []
  }

  onFetchingCards = async () => {
    const ids = this.getEnableCardsIds()
    await this.props.onFetchCards({ // fdsa
      apiEndpoint: "run-debug",
      query: `?${ids.join("&")}${getDateQuery()}`,
      onSuccess: (res) => {
        this.setState({debug: res});
        // this.props.onSetCards(res || {})
        // localStorage.setItem("LUCI_DATAS", JSON.stringify(this.props.state))
      },
      onFail: (response) => {
        if (response?.data?.errors && response.data.errors[0]?.param?.error_type === 'popup') {
          this.setState({
            isPlaidPopUp: true,
            institutionName: response.data.errors[0]?.param?.institutionName,
            currentStep: 0,
            plaidPopUpErrorMsg: response.data.errors[0]?.msg || ""
          });
        }
        console.log("failed", response)
        localStorage.setItem("LUCI_DATAS", JSON.stringify(this.props.state))
      }
    })
  }

  gotoSecondStep = () => {
    this.props.onSetDashboardStep({isFirstStepComplete: true})
    this.setState({
      currentStep: 1,
      resettingCard: false
    })
  }

  onFindCardModalChange = () => {
    this.onUsingPlaidChange(false)
    this.setState({
      isFindCardModal: !this.state.isFindCardModal,
      isPlaidPopUp: false,
      isNoCreditCardPopUp: false,
      plaidPopUpErrorMsg: ""
    })
  }

  onFindingCards = async () => {
    const { costs } = this.props

    const { grocery, gas, restaurant, other, recreation, travel } = costs || {}
    const dateRange = getDateRanges(1)
    const dateQuery = `&startDate=${moment((dateRange || [])[0]).format("YYYY-MM-DD")}&endDate=${moment((dateRange || [])[1]).format("YYYY-MM-DD")}&nMonths=${12}`
    const query = `?1=${removeComma(grocery)}&2=${removeComma(gas)}&4=${removeComma(restaurant)}&5=${removeComma(other)}&8=${removeComma(recreation)}&9=${removeComma(travel)}${dateQuery}`
    // localStorage.setItem("costs", JSON.stringify(costs))
    // localStorage.removeItem('accounts')
    // localStorage.removeItem('dates')
    await this.props.onFetchCards({
      apiEndpoint: "run-backtest-manual",
      query: query,
      onSuccess: (res) => {
        this.onBanksCardOff()
        this.props.onSetCards(res || {})
        this.setState({
          isFindCardModal: false,
          isFindingCards: false,
          resettingCard: true
        });
        this.gotoSecondStep()
        localStorage.setItem("LUCI_DATAS", JSON.stringify(this.props.state))
      },
      onFail: (response) => {
        if (response?.data?.errors && response.data.errors[0]?.param?.error_type === 'popup') {
          this.setState({
            isPlaidPopUp: true,
            institutionName: response.data.errors[0]?.param?.institutionName,
            currentStep: 0,
            plaidPopUpErrorMsg: response.data.errors[0]?.msg || ""
          });
        }
        this.setState({
          isFindingCards: false
        });
        localStorage.setItem("LUCI_DATAS", JSON.stringify(this.props.state))
      }
    })
  };

  backToFirstStep = () => {
    this.setState({
      currentStep: 0
    })
  }

  onGoToResultPage = () => {
    const { currentStep } = this.state

    if(currentStep === 1) {
      this.setState({currentStep: 0})
    } else if(currentStep === 0 && !this.isAccountAvailable() && !Object.keys(getStoredCards() || {}).length) {
      this.setState({isUsingPlaidPopUp: true})
    } else if(this.props.isFirstStepComplete) {
      if(!!(getStoredAccounts() || []).length || (getStoredCosts() || {}).isManualCost) {
        this.gotoSecondStep()
      }
    }
  }

  onBanksCardOff = () => {
    const { banks } = this.props

    banks.forEach(bank => {
      bank.accounts.forEach(account => {
        account.enabled = false
      })
    })

    this.props.onSetAccounts(banks);
  }

  onUsingPlaidChange = (value) => {
    this.setState({
      isUsingPlaidPopUp: value,
      isNewUser: false
    })
  }

  onPlaidModalChange = (object) => {
    this.setState({
      ...object
    })
  }

  isAccountAvailable = () => {
    const { banks = [] } = this.props

    let isAvailable = true
    if(!(banks?.[0]?.accounts || []).length && !(banks?.[1]?.accounts || []).length) {
      isAvailable = false
    }

    return isAvailable
  }

  onAccountsLoading = () => {
    this.setState({
      isAccountsLoading: true
    })
  }

  render() {
    const { isFirstStepComplete, onSetDashboardStep } = this.props
    const {
      currentStep,
      isFindCardModal,
      isCardRequest,
      isAccountsLoading,
      isFindingCards,
      resettingCard,
      isPlaidPopUp,
      isUsingPlaidPopUp,
      institutionName,
      isNewUser,
      isNoCreditCardPopUp,
      plaidPopUpErrorMsg
    } = this.state;
    
    if (this.state.debug) {
      const fdsa = JSON.stringify(this.state.debug)
      return <div>{fdsa}</div>
    }

    return (
      <div className="dashboard-wrapper">


        <Header
          isFirstStepComplete={isFirstStepComplete}
          onGoToResultPage={this.onGoToResultPage}
        />
        <section>
          <div
            className={`wrapper profile-sidebar ${
              currentStep === 0 ? "first-step-lg" : "second-step-lg"
            }`}
          >
            <Sidebar
              currentStep={currentStep}
              isFirstStepComplete={isFirstStepComplete}
              onStepChange={this.onStepChange}
            />

            { currentStep === 0 ? (
              <FirstStep
                currentStep={currentStep}
                isAccountsLoading={isAccountsLoading}
                getAccountService={this.getAccountService}
                onShowBankDetails={this.onShowBankDetails}
                setStateChange={this.setStateChange}
                onSetDashboardStep={onSetDashboardStep}
                onFindCardModalChange={this.onFindCardModalChange}
                onAccountsLoading={this.onAccountsLoading}
                onCreateLinkToken={this.onCreateLinkToken}
              />
            ) : null}

            {currentStep === 1 ? (
              <SecondStep
                isCardRequest={isCardRequest}
                isAccountsLoading={isAccountsLoading}
                isFindingCards={isFindingCards}
                resettingCard={resettingCard}
                onCardRequestChange={this.onCardRequestChange}
                getEnableCardsIds={this.getEnableCardsIds}
                onFindCardModalChange={this.onFindCardModalChange}
                backToFirstStep={this.backToFirstStep}
                onPlaidModalChange={this.onPlaidModalChange}
                onAccountsLoading={this.onAccountsLoading}
                getAccountService={this.getAccountService}
              />
            ) : null}

            { isFindCardModal ?
              <FindCardModal
                onClose={this.onFindCardModalChange}
                onFindingCards={this.onFindingCards}
              /> : null
            }
            { isPlaidPopUp && <PlaidErrorModal institutionName={institutionName} message={plaidPopUpErrorMsg || ""} onClose={() => this.setState({isPlaidPopUp: false, plaidPopUpErrorMsg: ""})} onFindCardModal={this.onFindCardModalChange} />}
            { isNoCreditCardPopUp && <NoCreditCardModal institutionName={institutionName} onClose={() => this.setState({ isNoCreditCardPopUp: false})} onFindCardModal={this.onFindCardModalChange} />}
          </div>
  
          <UsingPlaidModal
            onClose={this.onUsingPlaidChange}
            visible={isUsingPlaidPopUp}
            onFindCardModalChange={this.onFindCardModalChange}
            getAccountService={this.getAccountService}
            onSetDashboardStep={onSetDashboardStep}
            isNewUser={isNewUser || false}
            onAccountsLoading={this.onAccountsLoading}
            onCreateLinkToken={this.onCreateLinkToken}
          />
        </section>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  state: state || {},
  costs: state.costs || {},
  banks: state.accounts.banks || [],
  fetchAccountsLoaded: state.accounts.fetchAccountsLoaded || false,
  cardList: getCardsListByYearWise(cloneDeep(state.resultSetting.topIncludedIntroBonus ? state.cards?.cardsDetails?.results : state.cards?.cardsDetails?.noBonus_results), state.cards?.cardsDetails?.cards || {}, state.resultSetting.topYearProjection) || [],
  fetchCardsLoaded: state.cards.fetchCardsLoaded || false,
  isFirstStepComplete: state.dashboardStep.isFirstStepComplete || false
});

const mapDispatchToProps = dispatch => {
  return {
    onSetAccounts: data => {
      dispatch(setAccounts(data));
    },
    onSetCosts: data => {
      dispatch(setCosts(data));
    },
    onFetchCards: data => {
      dispatch(fetchCards(data));
    },
    onSetCards: data => {
      dispatch(setCards(data));
    },
    onSetDashboardStep: data => {
      dispatch(setDashboardStep(data));
    },
    onSetYearProjection: (data) => {
      dispatch(setResultSetting(data))
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
