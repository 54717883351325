import React, {useState} from "react";
import {connect} from "react-redux";
import {Tooltip} from "antd";
import ArrowBlack from "../../../../../../assets/images/dashboard/arrow-black.svg";
import MediaQuery from "react-responsive";
import {getCategoriesColor, getCategoriesIcon, valueWithCommas} from "../../../../../../utils/constants";
import BarChart from './BarChart';
import Help from "../../../../../../assets/images/dashboard/help-icon.svg";
import "./SpendingBehavior.scss"

const SpendingBehavior = (props) => {
  const {monthObject, weekObject, onStepChange} = props
  const [selectedTime, onSelectTime] = useState("month");
  const [selectedType, onSelectType] = useState("transactions");
  const [selectedChartColumn, onSelectChartColumn] = useState((weekObject?.time_buckets.length - 1) || 0);
  
  const onTimeChange = (value) => {
    onSelectTime(value);
  }
  
  const onTypeChange = (value) => onSelectType(value);
  
  const onColumnChange = (value) => {
    setTimeout(() => {
      if (value !== selectedChartColumn && value !== "") {
        onSelectChartColumn(value)
      }
    }, 200)
  };
  
  const getChartData = () => {
    let dataArray = [["", "", {type: 'string', role: 'tooltip'}, {role: 'style'}]];
    const array = selectedTime === "week" ? weekObject.time_buckets : monthObject.time_buckets;
    if (array.length === 0) return dataArray;
    let categories = [];
    if (selectedType === "category") {
      let keys = Object.keys(array[0]?.sums).filter(x => x !== 'All');
      dataArray = [['Time', ...keys]];
      array.forEach((x, i) => {
        categories = keys.map(y => {
          return x.sums[y] || 0
        });
        dataArray.push(
            [
              x.time_display,
              ...categories,
            ])
        }
      );
    } else {
      array.forEach((x, i) =>
        dataArray.push(
          [
            x.time_display,
            x.sums.All,
            `$${valueWithCommas(x.sums.All || 0)}`,
            i == selectedChartColumn ? 'stroke-color: #d45209; stroke-width: 4;' : null,
          ])
      );
    }
  
    return dataArray
  }
  
  const getTableData = () => {
    let data = [];
    if (selectedChartColumn >= 0) {
      const object = (selectedTime === "week" ? weekObject : monthObject) || {};
      const categories = [...(object.category_order || [])];
      categories.reverse();
      if (selectedType === "transactions") {
        data = object?.time_buckets[selectedChartColumn]?.transactions?.All || []
      } else if (selectedType === "category") {
        (Object.entries(object?.time_buckets[selectedChartColumn]?.sums) || {}).map(([key, value]) => {
          if (key !== "All") {
            data.push({date: "", category: key, name:
              <div className="gr-name d-flex align-items-center">
                <img
                  src={getCategoriesIcon(key)}
                  alt={`${key}-icon`}
                  style={{background: getCategoriesColor(key), width: 35, height: 35, padding: 5, borderRadius: 5}}
                />&nbsp;&nbsp;
                <span>{key}</span>
              </div>,
              amount: value})
          }
        })
      }
      
      if (selectedType === "category" && Array.isArray(categories) && categories.length) {
        let newData = [];
        categories.forEach(cat => {
            const d = data.find(d => d.category === cat);
            if (d) {
              newData.push(d);
              data = data.filter(x => x.category != cat);
            }
        });
        if (data.length) {
          newData = newData.concat(data);
        }
        return newData.filter(x => x.amount > 0);
      }
    }
    
    return data.filter(x => x.amount > 0)
  }

  const getTableFilteredData = () => {
    let array = [...getTableData()]

    if(selectedType !== "transactions") {
      array = array.sort((a,b) => b.amount - a.amount)
    }

    return array
  }
  
  return (
    <div className="spending-behavior">
      <div className="pick-text">
        Breakdown of your spending data
        <Tooltip
            title="Debit card transactions, such as money transfers, that can’t be charged on a credit card are excluded"
            trigger={"click"}
        >
          <img className="help-icon ml-2" src={Help}/>
        </Tooltip>
      </div>
      <div className="spending-behavior-box">
        <div className="row">
          <div className="col-md-6 col-sm-12">
            <div className="year-projection spending-tab spending-behavior-tab mt-4">
              <div
                className={`first-year ${selectedTime === "month" ? "selected" : ""}`}
                onClick={() => onTimeChange("month")}
              >
                Last 6 months
              </div>
              <div
                className={`last-year ${selectedTime === "week" ? "selected" : ""}`}
                onClick={() => onTimeChange("week")}
              >
                Last 6 weeks
              </div>
            </div>
            <BarChart
              getChartData={getChartData}
              onColumnChange={onColumnChange}
              selectedType={selectedType}
              selectedTime={selectedTime}
              weekObject={weekObject}
              monthObject={monthObject}
              selectedChartColumn={selectedChartColumn}
            />
          </div>
        
        {
          selectedChartColumn !== "" ?
            <div className="col-md-6 col-sm-12">
              <div className="year-projection spending-tab mt-4">
                <div
                  className={`first-year ${selectedType === "transactions" ? "selected" : ""}`}
                  onClick={() => onTypeChange("transactions")}
                >
                  Transactions
                </div>
                <div
                  className={`last-year ${selectedType === "category" ? "selected" : ""}`}
                  onClick={() => onTypeChange("category")}
                >
                  Category
                </div>
              </div>
              
              <div className="spending-behavior-table">
                <div className="table-content">
                  <div className="table-row">
                    {selectedType === "transactions" ? <div className="td-1 fw-600">Date</div> : null}
                    <div className="td-2 fw-600">Description</div>
                    <div className="td-3 fw-600">Amount</div>
                  </div>
                  <div className="border-div"/>
                  <div className="scroll-table">
                    {
                      (getTableFilteredData() || []).length ? (getTableFilteredData() || []).map((detail, index) => {
                        const {date_disp, name, amount} = detail || {}
                        return (
                          <div className="table-row" key={index.toString()}>
                            {selectedType === "transactions" ?
                              <div className="td-1">
                                {date_disp}
                              </div> : null
                            }
                            <div className="td-2">
                              {name}
                            </div>
                            <div className="td-3">
                              <b>{`$${valueWithCommas(amount || 0)}`}</b>
                            </div>
                          </div>
                        )
                      }) :
                      <div className="text-center">
                        No data found!
                      </div>
                    }
                  </div>
                </div>
              </div>
            
            </div> : null
        }
        </div>
        
        <img
          src={ArrowBlack}
          className={`black-arrow mt-100`}
          onClick={() => onStepChange(0)}
          style={{left: "-25px"}}
        />
        
        <MediaQuery maxWidth={991}>
          <div className="visible-xs">
            <img
              src={ArrowBlack}
              className={`black-arrow mt-300`}
              onClick={() => onStepChange(0)}
              style={{left: "-5px"}}
            />
          </div>
        </MediaQuery>
      
      </div>
    
    </div>
  )
}


const mapStateToProps = state => ({
  monthObject: state.cards.cardsDetails?.spendingStats?.month || [],
  weekObject: state.cards.cardsDetails?.spendingStats?.week || []
});

export default connect(mapStateToProps, null)(SpendingBehavior);
