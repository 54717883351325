import React from "react";
import {connect} from "react-redux";
import cloneDeep from "lodash/cloneDeep";
import { PieChart } from "react-minimal-pie-chart";
import {Tooltip} from "antd";
import MediaQuery from "react-responsive";
import {
    commaSeparator,
    getCategoriesIcon,
    getCategoriesColor,
    valueWithCommas
} from "../../../../../../utils/constants";
import Help from "../../../../../../assets/images/dashboard/help-icon.svg";
import Arrow from "../../../../../../assets/images/Inner/arrow.svg";
import ArrowBlack from "../../../../../../assets/images/dashboard/arrow-black.svg";
import More from "../../../../../../assets/images/dashboard/more.svg";
import SpendingBehavior from "./SpendingBehavior"
import SpendMoneyModal from "./SpendMoneyModal"
import RelevantTransactionsModal from "./RelevantTransactionsModal"

const colors = [
    "#3DD598",
    "#044877",
    "rgb(209 149 61)",
    "#FF974A",
    "rgb(113 117 240)",
    "rgb(252 203 42)",
    "#7BDAFF",
    "#FF8976",
    "#d567ff",
    "rgb(37 172 67)",
    "rgb(95 37 244)",
    "rgb(59 183 230)",
    "#654987",
    "#165955",
    "#000",
    "#572f1b",
    "olive",
    "silver",
    "chocolate",
    "#5e68de",
    "#e1a69f",
    "#3f5926",
    "#4B0082",
    "#6F4E37",
    "#7FFFD4",
    "#5A7CC2",
    "#9F00FF",
];

class TopSpending extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            isMore: false,
            isMoneyModal: false,
            isRelevantModal: false,
            relevantTransactions: [],
            selected: "category",
            currentStep: 0
        }
    }

    getCardList = () => {
        const { transactions_statistics, topSpendings, recurringSpendings } = this.props || {}
        const { selected } = this.state || {}

        const list = selected === "category" ? transactions_statistics : selected === "recurring" ? recurringSpendings : topSpendings
        let array = cloneDeep(list);

        const index = array.findIndex(x => x.category === "Other")

        if(index !== -1) {
            array.splice(index, 1)
            array = array.sort((a,b) => b.spending - a.spending)
            array.push(list[index])
        } else {
            array = array.sort((a,b) => b.spending - a.spending)
        }

        return array
    }

    getChartData = (stat) => {
        return stat.map((s, i) => {
            return {
                ...s,
                color: this.getCategoryColor(s.category, i),
                title: s.category,
                value: s.spending
            };
        });
    }

    content = (category) => {
        const { spendingByCategory } = this.props || {}
        return(
            <div className="p-3">
                <div className="text-nowrap">
                    <img src={getCategoriesIcon(category)} style={{background: getCategoriesColor(category), width: 35, padding: 5, borderRadius: 5, marginRight: 20}}/>
                    <span className="color-white" style={{fontSize: 20}}><b>Top Merchants</b></span>
                </div>
                {
                    (spendingByCategory[category] || []).map((value, index) => (
                        <div className="color-white mt-4 justify-content-between d-flex" key={index.toString()}>
                            <span className="top-category">{value.name}</span>
                            <span className="gr-spending w-80 text-right ml-1">${valueWithCommas(value.amount)}</span>
                        </div>
                    ))
                }
            </div>
        )
    }

    onMoreChange = () => {
        this.setState({
            isMore: !this.state.isMore
        })
    }

    onChange = (value) => {
        this.setState({
            selected: value
        })
    }

    onStepChange = (step) => {
        this.setState({
            currentStep: step
        })
    }

    onMoneyModalChange = () => {
        this.setState({
            isMoneyModal: !this.state.isMoneyModal
        })
    }

    onRelevantModalChange = (list) => {
        const { isRelevantModal } = this.state
        this.setState({
            isRelevantModal: !isRelevantModal,
            relevantTransactions: !isRelevantModal ? list : []
        })
    }

    getCategoryColor = (name, index) => {
        const { selected } = this.state

        if(name === "Other") {
            return "#404857"
        }
        return (selected === "merchants" || selected === "recurring") ? colors[index] || getCategoriesColor(name) : getCategoriesColor(name)
    }

    render() {
        const { getSelectedDate, avgSpending, spending, selectedDate, spendingByCategory, isManualCost, onAccountsLoading, getAccountService, backToFirstStep } = this.props || {}
        const { isMore, selected, currentStep, isMoneyModal, isRelevantModal, relevantTransactions } = this.state || {}

        if(currentStep === 1) {
            return (
                <SpendingBehavior
                    onStepChange={this.onStepChange}
                    currentStep={currentStep}
                />
            )
        }

        const cardCategories = this.getCardList() || [];

        const getCardCategories = this.getChartData(cardCategories) || [];
        return(
            <div className="mt-30">
                <div className="pick-text">
                    Breakdown of your spending data
                    <Tooltip
                        title="Debit card transactions, such as money transfers, that can’t be charged on a credit card are excluded"
                        trigger={"click"}
                    >
                        <img className="help-icon ml-2" src={Help}/>
                    </Tooltip>
                </div>
                <div className="spending-pie-chart data-graph data-graph-border m-0">
                    <div className="dg-area">
                        
                        <div className={`graph-info ${(getCardCategories || []).length > 6 ? "w-100" : ""}`}>
                            <div className={`graph-left ${(getCardCategories || []).length > 6 ? "flex-unset" : ""}`}>
                                <div className="dg-dates" style={{marginTop: -5}}>
                                    <div className="dg-date">{getSelectedDate()}</div>
                                </div>
                                <div className="chart-area mt-3">
                                    <div
                                        className="d-flex align-items-center justify-content-center pens flex-grow-1 position-relative">
                                        <PieChart
                                            animation
                                            animationDuration={500}
                                            animationEasing="ease-out"
                                            center={[50, 50]}
                                            rounded={false}
                                            data={this.getChartData(cardCategories)}
                                            labelPosition={50}
                                            lengthAngle={360}
                                            lineWidth={15}
                                            paddingAngle={0}
                                            radius={50}
                                            startAngle={0}
                                            viewBoxSize={[100, 100]}
                                            style={{
                                                height: "250px",
                                                position: "relative",
                                                transform: "rotate(-90deg)"
                                            }}
                                        />
                                        <div className="chart-inner-text d-flex flex-column">
                                            <div>
                                                <h3 className="ci-title">${commaSeparator(spending || 0)}</h3>
                                            </div>
                                            <div>
                                                <span className="ci-value">Total Spending</span>
                                            </div>
                                            <div>
                                                <hr/>
                                            </div>
                                            <div>
                                                <h3 className="ci-title">
                                                    ${commaSeparator(avgSpending || "")}
                                                    {
                                                        selectedDate === 6 ?
                                                            <Tooltip
                                                                title="Assumes 30 days in a month"
                                                                trigger={"click"}
                                                            >
                                                                <img className="help-icon ml-1" src={Help}/>
                                                            </Tooltip> : null
                                                    }
                                                </h3>
                                            </div>
                                            <div>
                                                <span className="ci-value">Avg per Month</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={`graph-right pt-0 ${(getCardCategories || []).length > 6 ? "pl-40" : ""} z-index-11`}>
                                <div className="year-projection spending-tab">
                                    <div
                                        className={`first-year first-year-category ${selected === "category" ? "selected" : ""}`}
                                        onClick={() => this.onChange("category")}
                                    >
                                        Category
                                    </div>
                                    <div
                                        className={`second-year ${selected === "merchants" ? "selected" : ""}`}
                                        onClick={() => isManualCost ? this.onMoneyModalChange() :  this.onChange("merchants")}
                                    >
                                        Top Merchants
                                    </div>
                                    <div
                                        className={`last-year ${selected === "recurring" ? "selected" : ""}`}
                                        onClick={() => isManualCost ? this.onMoneyModalChange() :  this.onChange("recurring")}
                                    >
                                        Recurring Expenses
                                    </div>
                                </div>

                                <div className="row">
                                    {(getCardCategories || []).map((x, index) => {
                                        let object = { trigger: "hover" }
                                        const isSpending = (spendingByCategory[x.category]  || []).length
                                        if(isSpending) {
                                            object.title = () => this.content(x.category)
                                        }
                                        return(
                                            <div className={`${index > 3 && !isMore ? "mobile-hide" : ""} ${(getCardCategories || []).length > 6 ? `col-md-6 ${index % 2 ? "graph-pl-40" : "graph-pr-40"}` : 'col-md-12'} gr-item justify-content-between`} key={index.toString()}>
                                                <Tooltip
                                                    {...object}
                                                    visible={false}
                                                >
                                                    <div className={`gr-name ${isSpending ? "cursor-pointer" : ""}`}>
                                                        <img
                                                            src={getCategoriesIcon(x.category)}
                                                            alt={`${x.category}-icon`}
                                                            style={{background: this.getCategoryColor(x.category, index)}}
                                                        />
                                                        <span>{x.category}</span>
                                                    </div>
                                                </Tooltip>
                                                <div className={`fw-600 ${selected !== "category" ? "d-flex align-items-center" : ""}`}>
                                                    ${valueWithCommas(x.spending)}
                                                    {
                                                        selected !== "category"  ?
                                                            <img
                                                                className={`cursor-pointer h-25 mr-0 ${x.category === "Other" ? "invisible" : ""}`}
                                                                src={More} style={{height: 25, width: 25}}
                                                                onClick={() => this.onRelevantModalChange(x.transactions || [])}
                                                            /> : null
                                                    }
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                                {
                                    (getCardCategories || []).length > 4 ?
                                        <div className="text-center web-hide">
                                            <span onClick={this.onMoreChange}>{ isMore ? "Less" : "More"} <img src={Arrow} style={!isMore ? {transform: 'rotate(180deg)'} : {}} /></span>
                                        </div> : null
                                }

                            </div>
                        </div>
                    </div>

                    <img
                        src={ArrowBlack}
                        className={`black-arrow mt-100 right`}
                        onClick={() => isManualCost ? this.onMoneyModalChange() : this.onStepChange(1)}
                        style={{right: "-25px"}}
                    />
                    {
                        !isManualCost ?
                          <MediaQuery maxWidth={991}>
                              <div className="visible-xs">
                                  <img
                                    src={ArrowBlack}
                                    className={`black-arrow mt-300 right`}
                                    onClick={() => this.onStepChange(1)}
                                    style={{right: "-5px"}}
                                  />
                              </div>
                          </MediaQuery> : null
                    }

                </div>
    
                {isMoneyModal && <SpendMoneyModal
                  isVisible={isMoneyModal}
                  onClose={this.onMoneyModalChange}
                  onAccountsLoading={onAccountsLoading}
                  getAccountService={getAccountService}
                  backToFirstStep={backToFirstStep}
                />
                }

                <RelevantTransactionsModal
                    isVisible={isRelevantModal}
                    dataList={relevantTransactions || []}
                    onClose={this.onRelevantModalChange}
                />
            </div>
        )
    }
}

const mapStateToProps = state => ({
    spendingByCategory: state.cards.cardsDetails.topSpendingsByCategory || [],
    topSpendings: (state.cards.cardsDetails.topSpendings || []).map(x => ({...x, category: x.name, spending: x.sum})) || [],
    recurringSpendings: (state.cards.cardsDetails.recurringSpendings || []).map(x => ({...x, category: x.name, spending: x.sum})) || []
});

export default connect(mapStateToProps, null)(TopSpending);

