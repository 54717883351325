import React, { useEffect } from 'react';
import { connect } from "react-redux";
import { usePlaidLink } from 'react-plaid-link';
import { ApiService } from "../../../../../services/ApiService";

const OAuthLink = (props) => {
    
    const linkToken = props.linkToken || ""
    
    const onSuccess = async (publicKey, metaData) => {
        const response = await ApiService.setPlaidAccount(publicKey, metaData);
        localStorage.removeItem('LUCI_DATAS');
        if(response) {
            window.location.href = "/dashboard"
        }
    };

    const onExit = async (error, metaData) => {
        if (error != null) {
            const body = { error, metaData };
            await ApiService.handlePlaidError(body);
        }
        window.location.href = "/dashboard"
    };

    const config = {
        token: linkToken,
        receivedRedirectUri: window.location.href,
        onSuccess,
        onExit
    };

    const { open, ready, error } = usePlaidLink(config);

    // automatically re-initialize Link
    useEffect(() => {
        if (ready) {
            open();
        }
    }, [ready, open]);

    return <></>;
};


const mapStateToProps = state => ({
    linkToken: state.resultSetting.linkToken || ""
});

export default connect(mapStateToProps, null)(OAuthLink);