import React from "react";
import { Modal, Spin } from "antd";
import Warning from "../../../../../../assets/images/dashboard/warning.svg";


class UnlinkAccountModal extends React.Component {
    constructor(props){
        super(props)
    }

    render() {
        const { onClose, onUnLink, isLoading } = this.props
        return (
            <Modal
                visible={true}
                footer={null}
                width={400}
                className="unlink-account-modal"
                onCancel={onClose}
            >
                <div className="modal-header border-bottom-0 p-0 text-center d-block mt-15">
                    <img className="warning-image" src={Warning} />
                    <h5 className="modal-title p-card-title">
                        Unlink Accounts
                    </h5>
                </div>
                <div className="unlink-desc">
                    Are you sure you want to unlink  all
                    the accounts?
                </div>
                <div className="modal-footer border-top-0 justify-content-center mt-15">
                    <button
                        className={`btn luci-btn gray-btn font-weight-bold `}
                        onClick={onClose}
                    >
                        Cancel
                    </button>
                    <button
                        className={`btn luci-btn primary-btn font-weight-bold unlink-red`}
                        onClick={onUnLink}
                        disabled={isLoading}
                    >
                        {isLoading ? <Spin className="pr-10" /> : null }Unlink
                    </button>
                </div>
            </Modal>
        );
    }
}

export default UnlinkAccountModal;
