import React from "react";
import {connect} from "react-redux";
import Arrow from "../../../assets/images/dashboard/arrow.svg";
import ASImage from "../../../assets/images/dashboard/as.svg";
import PRImage from "../../../assets/images/dashboard/pr.svg";
import Edit from "../../../assets/images/dashboard/edit.svg";
import check from "../../../assets/images/dashboard/checkmark.svg";

class Sidebar extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      isSidebar: true
    }
  }

  onChange = () => {
    this.setState({
      isSidebar: !this.state.isSidebar
    })
  }
  
  onStepChange = (step) => {
    this.props.onStepChange(step);
    if (!this.state.isSidebar) {
      this.onChange();
    }
  }

  render() {
    const { currentStep, isFirstStepComplete } = this.props
    const { isSidebar } = this.state
    return(
      <div id="sidebar" className={isSidebar ? "active" : ""}>
        <div className="left-side-area">
          <div className="sidebar-header">
            <a id="sidebarCollapse" className="btn-info-toggle" onClick={this.onChange}>
              <i><img src={Arrow}/></i>
              <span/>
            </a>
          </div>
          <div className="sidebar-nav">
            <div
              className={`nav-item ${currentStep === 0 ? "selected" : "completed"}`}
              onClick={() => this.onStepChange(0)}
            >
              <a className="share-with-luci">
                <span title="Cards Connected with luci">
                  <img src={ASImage}/>
                  {
                    isFirstStepComplete && currentStep === 1 ? <img src={Edit} className="edit-icon"/> : null
                  }
                  {
                    isFirstStepComplete && currentStep === 1 ? <img src={check} className="check-icon"/> : null
                  }
                </span>
                Cards Connected with luci
              </a>
            </div>
            <div
              // className={`nav-item ${currentStep === 1 ? "selected" : ""} ${isFirstStepComplete ? "" : "cursor-not-allowed"}`}
              // onClick={() => isFirstStepComplete ? this.onStepChange(1) : ({})}

              className={`nav-item ${currentStep === 1 ? "selected" : ""}`}
              onClick={() => this.onStepChange(1)}
            >
              {/*<a className={`${isFirstStepComplete ? "" : "cursor-not-allowed"}`}>*/}
              <a>
                <span title="Personalized Results">
                  <img src={PRImage}/>
                </span>
                Personalized Results
              </a>
            </div>
          </div>
        </div>
      </div>
    )
  }

}

const mapStateToProps = state => ({
  banks: state.accounts.banks || []
});

export default connect(mapStateToProps, null)(Sidebar);
