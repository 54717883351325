import React from "react";
import { Modal } from "antd";


class NoCreditCardModal extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      isModal: true
    }
  }
  
  onClose = () => {
    this.setState({
      isModal: false,
    });
    this.props.onClose();
  }
  
  isFindCardModal = () => {
    this.setState({
      isModal: false,
    });
    this.props.onFindCardModal();
  }
  
  render() {
    return (
      <Modal
        visible={this.state.isModal}
        footer={null}
        className="unlink-account-modal"
        onCancel={this.onClose}
      >
        <div className="modal-header border-bottom-0 p-0 text-center d-block mt-15">
          <h5 className="modal-title p-card-title fs-25">
            No Credit or Debit Cards Found
          </h5>
        </div>
        <div className="unlink-desc">
          No credit or debit cards were found in your {this.props.institutionName} account
        </div>
        <div className="unlink-desc">
          Please try a different bank or click <span className="link-text cursor-pointer" onClick={this.isFindCardModal}>here</span> to manually enter your spending estimates.
        </div>
        <div className="modal-footer border-top-0 justify-content-center mt-15">
          <button
            className={`btn luci-btn primary-btn font-weight-bold`}
            onClick={this.onClose}
          >
            Go Back
          </button>
        </div>
      </Modal>
    );
  }
}

export default NoCreditCardModal;
