import React from "react";
import {Loading} from "../../common/components/Loading/Loading";
import {clearStorage} from "../../utils/constants";

function getParameterByName(name, url = window.location.href) {
    try {
        name = name.replace(/[\[\]]/g, '\\$&');
        var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
          results = regex.exec(url);
        if (!results) return null;
        if (!results[2]) return '';
        return decodeURIComponent(results[2].replace(/\+/g, ' '));
    } catch {
        return ""
    }
    
}

class GoogleAuthentication extends React.Component {
    componentWillMount() {
        const token = this.getAccessToken()
        if(token) {
            clearStorage();
            localStorage.removeItem("isUserLogin")
            localStorage.setItem("AUTH_TOKEN", token);
            setTimeout(() => {
                window.location.href = "/dashboard"
            }, 2000);
            
        } else {
            window.location.href = "/"
        }
    }

    getAccessToken = () => {
        return getParameterByName("accessToken",window.location.search );
    }

    render() {
        return <div className="text-center"><Loading isInteral={true} isCenter={true}/></div>
    }
}

export default GoogleAuthentication
