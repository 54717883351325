import Home from "../pages/Home";
import CookiesPolicy from "../pages/CookiesPolicy";
import PrivacyPolicy from "../pages/PrivacyPolicy";
import AboutUs from "../pages/AboutUs";
import Terms from "../pages/Terms";
import SignIn from "../pages/SignIn";
import SignUp from "../pages/SignUp";
import ForgotPassword from "../pages/ForgotPassword";
import Support from "../pages/Support";

export const routePaths = {
  home: "/",
  cookiesPolicy: "/cookies-policy",
  support: "/support",
  terms: "/terms",
  privacyPolicy: "/privacy-policy",
  about: "/about",
  signIn: "/signin",
  SignUp: "/signup",
  forgotPassword: "/forgot-password"
};

export const unAuthRoutes = [
    {
        title: "Home",
        path: routePaths.home,
        url: routePaths.home,
        exact: true,
        main: Home
    },
    {
        title: "Cookies-Policy",
        path: routePaths.cookiesPolicy,
        url: routePaths.cookiesPolicy,
        exact: true,
        main: CookiesPolicy
    },
    {
        title: "Support",
        path: routePaths.support,
        url: routePaths.support,
        exact: true,
        main: Support
    },
    {
        title: "Terms",
        path: routePaths.terms,
        url: routePaths.terms,
        exact: true,
        main: Terms
    },
    {
        title: "Privacy-Policy",
        path: routePaths.privacyPolicy,
        url: routePaths.privacyPolicy,
        exact: true,
        main: PrivacyPolicy
    },
    {
        title: "About",
        path: routePaths.about,
        url: routePaths.about,
        exact: true,
        main: AboutUs
    },
    {
        title: "SignIn",
        path: routePaths.signIn,
        url: routePaths.signIn,
        exact: true,
        main: SignIn
    },
    {
        title: "SignUp",
        path: routePaths.SignUp,
        url: routePaths.SignUp,
        exact: true,
        main: SignUp
    },
    {
        title: "Forgot Password",
        path: routePaths.forgotPassword,
        url: routePaths.forgotPassword,
        exact: true,
        main: ForgotPassword
    }
];
