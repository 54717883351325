import React from "react";
import { connect } from "react-redux";
import { PlaidLink } from "react-plaid-link";
import { Modal } from "antd";
import Lock from "../../../../assets/images/dashboard/lock.svg";
import Result from "../../../../assets/images/result.svg";
import Celebration from "../../../../assets/images/celebration.svg";
import Image1 from "../../../../assets/images/new-user-popup/image1.svg";
import Image2 from "../../../../assets/images/new-user-popup/image2.png";
import Image3 from "../../../../assets/images/new-user-popup/image3.svg";
import { ApiService } from "../../../../services/ApiService";


class UsingPlaidModal extends React.Component {

    handleClick = async (publicKey, metaData) => {
        const { onAccountsLoading, getAccountService, onSetDashboardStep, onClose } = this.props
        onAccountsLoading()
        await ApiService.setPlaidAccount(publicKey, metaData);
        getAccountService(true);
        onSetDashboardStep({isFirstStepComplete: false});
        onClose(false)
        localStorage.removeItem('LUCI_DATAS')
    }

    handleExit = async (error, metaData) => {
        if (error != null && error.error_code === 'INVALID_LINK_TOKEN') {
            this.props.onCreateLinkToken()
        }
        if (error != null) {
            const body = { error, metaData };
            await ApiService.handlePlaidError(body);
        }
    }

    render() {
        const { onClose, onFindCardModalChange, isNewUser, visible, linkToken } = this.props
        return (
            <Modal
                visible={visible}
                footer={null}
                width={820}
                className="unlink-account-modal share-using-plaid-modal"
                onCancel={() => onClose(false)}
            >
                { visible &&
                    <div className="plaid-body">
                        <div className="plaid-description">
                            <div className="modal-header border-bottom-0 p-0 text-center d-block mt-15">
                                <img className="warning-image" src={isNewUser ? Celebration : Result} />
                                <h5 className="modal-title p-card-title">
                                    {
                                        isNewUser ?
                                            "Let's get started!" :
                                            "Share how you spend to see results"
                                    }
                                </h5>
                            </div>
                            <div className="unlink-desc">
                                {
                                    isNewUser ?
                                        "View results by securely sharing how you spend your money using Plaid." :
                                            "Securely share how you spend your money using Plaid."
                                }
                            </div>
                            <div className="unlink-desc">
                                Click <a href="#" className="link-text" onClick={onFindCardModalChange}>here</a> to manually enter your spending estimates.
                            </div>
                            <div className="modal-footer border-top-0 justify-content-center mt-15">
                                <PlaidLink
                                    token={linkToken || ""}
                                    onSuccess={this.handleClick}
                                    onExit={this.handleExit}
                                    className="plaid-btn"
                                >
                                    <button
                                        className={`btn luci-btn primary-btn font-weight-bold share-plaid-btn`}
                                        // onClick={() => onClose(false)}
                                    >
                                        <img className="pb-1 mr-2" src={Lock}/>
                                        Share Using Plaid
                                    </button>
                                </PlaidLink>
                            </div>
                        </div>

                        <div id="carouselExampleIndicators" className="example-slide carousel slide" data-ride="carousel">

                            <div className="carousel-inner">

                                <div className="carousel-item active">
                                    <div>
                                        <h5 className="slide-card-title"><b>Breakdown of your spending</b></h5>
                                        <div className="image-container">
                                            <img src={Image1} />
                                        </div>
                                    </div>
                                </div>

                                <div className="carousel-item">
                                    <div>
                                        <h5 className="slide-card-title"><b>Personalized Recommendation based on your spending</b></h5>
                                        <div className="image-container">
                                            <img src={Image2} />
                                        </div>
                                    </div>
                                </div>

                                <div className="carousel-item">
                                    <div>
                                        <h5 className="slide-card-title"><b>Breakdown of your spending by reward categories</b></h5>
                                        <div className="image-container">
                                            <img src={Image3} />
                                        </div>
                                    </div>
                                </div>

                                {/*<div className="carousel-item">
                                    <div>
                                        <h5 className="slide-card-title"><b>Personalized recommendation based on your category spending</b></h5>
                                        <Card3 />
                                    </div>
                                </div>*/}

                            </div>

                            <a className="carousel-control-prev" href="#carouselExampleIndicators" role="button"
                               data-slide="prev">
                                <span className="carousel-control-prev-icon" aria-hidden="true"/>
                                <span className="sr-only">Previous</span>
                            </a>
                            <a className="carousel-control-next" href="#carouselExampleIndicators" role="button"
                               data-slide="next">
                                <span className="carousel-control-next-icon" aria-hidden="true"/>
                                <span className="sr-only">Next</span>
                            </a>
                        </div>

                    </div>
                }
            </Modal>
        );
    }
}


const mapStateToProps = state => ({
    linkToken: state.resultSetting.linkToken || ""
});

export default connect(mapStateToProps, null)(UsingPlaidModal);