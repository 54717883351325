import React from "react";
import { Modal } from "antd";

const AdvertiserDisclosureModal = ({ onClose }) => (
    <Modal
        visible={true}
        footer={null}
        width={450}
        className="unlink-account-modal advertiser-disclosure-modal"
        onCancel={onClose}
        style={{maxWidth: "unset"}}
    >
        <div className="modal-header border-bottom-0 p-0 mt-3">
            <h5 className="modal-title p-card-title">
                Advertiser Disclosure
            </h5>
        </div>

        <div className="unlink-desc">
            Many of the credit card offers that appear on the website are from credit card companies from which luci receives compensation.
            luci is totally unbiased and does NOT factor in affiliate compensation in its card recommendations to users. This site does not
            include all credit card companies or all available credit card offers.
        </div>
        <div className="unlink-desc mt-4">
            Editorial Note: Opinions expressed here are the author’s alone, not those of any bank, credit card issuer,
            airlines or hotel chain, and have not been reviewed, approved, or otherwise endorsed by any of these entities.
        </div>
    </Modal>
)

export default AdvertiserDisclosureModal;
