import React from "react";
import ReactWOW from 'react-wow'
import icProblem  from "../../assets/images/ic_problem.svg";
import icQuestion  from "../../assets/images/ic_question.svg";

const Problems = () => {
  return (
    <section className="problem-question">
      <div className="container">
        <div className="row">
          <ReactWOW animation="fadeInLeft" delay="0.5s">
            <div className="col-lg-6">
              <div className="problem-area">
                <h2>Problem</h2>
                <p>Millions of people are losing hundreds to thousands of dollars in cashback and other rewards every year.</p>
                <img src={icProblem} alt=""/>
              </div>
            </div>
          </ReactWOW>
          <ReactWOW animation="fadeInRight" delay="0.5s" >
            <div className="col-lg-6">
              <div className="question-area">
                <h2>Question</h2>
                <p>How do you know which credit card is best for your spending habits?</p>
                <img src={icQuestion} alt=""/>
              </div>
            </div>
          </ReactWOW>
        </div>
      </div>
    </section>
  );
};

export default Problems;
