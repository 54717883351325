import React from "react";
import moment from "moment";
import IntroAPR from "../assets/images/dashboard/category/0intobonus.svg"
import BalanceTransfer from "../assets/images/dashboard/category/BalanceTransfer.svg"
// import BuildingCredit from "../assets/images/dashboard/category/Rewards.svg"
import BuildingCredit from "../assets/images/dashboard/category/BuildingCredt.svg"
import CashBack from "../assets/images/dashboard/category/CashBack.svg"
import IntroBonus from "../assets/images/dashboard/category/IntroBonus.svg"
import NoAnnualFee from "../assets/images/dashboard/category/AnnualFee.svg"
import Travel from "../assets/images/dashboard/category/Travel.svg"
import Rewards from "../assets/images/dashboard/category/Rewards.svg"
import Shops from "../assets/images/dashboard/category/Shop.svg"
import Service from "../assets/images/dashboard/category/Service.svg"
import OnlineShopping from "../assets/images/dashboard/category/OnlineShopping.svg"
import FlatRateCashBack from "../assets/images/dashboard/features/flat-rate-cash-back.svg"
import NoForeignTransactionFees from "../assets/images/dashboard/features/no-foreign-transaction-fees.svg"
import RotatingCategories from "../assets/images/dashboard/features/rotating-categories.svg"
import CategoryCashBack from "../assets/images/dashboard/features/category-cash-back.svg"
import TravelBenefits from "../assets/images/dashboard/features/travel-benefits.svg"
import Rate from "../assets/images/dashboard/rate.svg"
import Notes from "../assets/images/dashboard/note.png";
import ThumbsUp from "../assets/images/dashboard/thumbs-up.svg";
import ThumbsDown from "../assets/images/dashboard/thumbs-down.svg";
import Groceries from "../assets/images/dashboard/chart/shopping-basket.svg";
import GasStation from "../assets/images/dashboard/chart/gas-station.svg";
import WarehousesStores from "../assets/images/dashboard/chart/warehouse.svg";
import Restaurant from "../assets/images/dashboard/chart/cutlery.svg";
import Other from "../assets/images/dashboard/chart/receipt-dollar.svg";
import Media from "../assets/images/dashboard/chart/movie.svg";
import Transportation from "../assets/images/dashboard/chart/public-transportation.svg";
import Recreation from "../assets/images/dashboard/chart/beach-ball.svg";
import TravelIcon from "../assets/images/dashboard/chart/airport.svg";
import CompassIcon from "../assets/images/dashboard/chart/compass.svg";
import Pharmacy from "../assets/images/dashboard/chart/pharmacy.svg";
import Department from "../assets/images/dashboard/chart/shopping-mall.svg";
import GymsAndFitness from "../assets/images/dashboard/chart/barbell.svg";
import Line from "../assets/images/dashboard/line.svg";

export const colors = [
  "#165955",
  "#044877",
  "rgb(209 149 61)",
  "#FF974A",
  "#404857",
  "rgb(113 117 240)",
  "#7BDAFF",
  "#FF8976",
  "#d567ff",
  "rgb(37 172 67)",
  "rgb(95 37 244)",
  "rgb(59 183 230)",
  "#654987",
  "#165955",
  "#000",
  "#404857",
  "#572f1b",
  "olive",
  "silver",
  "chocolate",
  "#5e68de",
  "#e1a69f",
  "#3f5926",
  "#4B0082",
  "#6F4E37",
  "#7FFFD4",
  "#5A7CC2",
  "#9F00FF",
]

export const BASE_URL = "";
export const scrollToTop = () => window.scroll(0, 0);
export const dateFormat = 'MM/DD/YY';
export const removeComma = (value) => {
  value = value && value.replace(/,/g, "")
  return Number(value || "")
};

export const getTotalCost = (costs) => {
  const { restaurant, gas, grocery, travel, recreation, other } = costs || {};
  const total = removeComma(restaurant || 0) + removeComma(gas || 0) + removeComma(grocery || 0) + removeComma(travel || 0) + removeComma(recreation || 0) + removeComma(other || 0)
  return commaSeparator(total) || 0;
};

export const getCardImageURL = (cardId) => `${window.location.origin}/images/ID${cardId}.png` || "";

export const commaSeparator = (value) => {
  value = (value || "").toString()
  return (value || "").replace(/\B(?=(\d{3})+(?!\d))/g, ",")
}

export const valueWithCommas = (value, decimal) => commaSeparator((value || 0).toFixed(decimal ? parseInt(decimal) : 2))

export const toFixedToDecimal = (value, decimal) => {
  try {
    return value.toFixed(decimal);
  } catch {
    return '0';
  }
}

export const validateEmail = (email) => {
  return (/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(email))
}

export const validatePassword = (passWord) => {
  if (passWord?.trim().length < 6) {
    return 'Passwords must be at least 6 characters long';
  }
  return true;
}

export const getStoredAccounts = () => {
  // const list = localStorage.getItem('accounts')
  // return JSON.parse(list || "[]")
  const localData = getLocalData()
  const { accounts: { banks = [] } = {} } = localData
  return banks
}

export const getStoredCards = () => {
  const localData = getLocalData()
  const { cards: { cardsDetails = {} } = {} } = localData
  return cardsDetails || {}
}


export const getManualStoredValue = (costs = {}) => {

  let total = 0

  Object.keys(costs).forEach(key => {
    if(key !== "isManualCost" && costs[key]) {
      total += Number(costs[key])
    }
  })

  return total
}


export const getStoredCosts = () => {
  // const costs = localStorage.getItem('costs')
  // return JSON.parse(costs || "{}")
  const localData = getLocalData()
  const { costs = {} } = localData;
  return costs
}

export const getStoredDates = () => {
  const dates = localStorage.getItem('dates')
  return JSON.parse(dates || "{}")
}

export const isUserLoggedIn = () => {
  const isLogin = localStorage.getItem('isUserLogin') || ""
  return isLogin === "yes"
}

export const getSelectedDate = (value) => {
  let date = ""
  if (value === 1) {
    const lastYear = new Date(new Date().setFullYear(new Date().getFullYear() - 1))
    date = [moment(lastYear), moment(new Date())];
  } else if (value === 2) {
    date = [moment().subtract(6, 'months'), moment(new Date())]
  } else if (value === 3) {
    date = [moment().subtract(3, 'months'), moment(new Date())]
  } else if (value === 4) {
    date = [moment().subtract(1, 'months'), moment(new Date())]
  } else if (value === 5) {
    date = [moment().subtract(1, 'week'), moment(new Date())]
  } else if (value === 7) {
    date = [moment().subtract(9, 'months'), moment(new Date())]
  }
  if((date || []).length) {
    const date1 = date[0].format(dateFormat)
    const date2 = date[1].format(dateFormat)
    return `${date1} - ${date2}`
  }
  return ""
}

export const storingCardList = (banks) => {
  let modifyList = []
  banks.forEach(x => {
    x.accounts.forEach(y => {
      modifyList.push(y)
    })
  })

  // localStorage.setItem("accounts", JSON.stringify(modifyList))
  // localStorage.removeItem("costs")
}

export const getDateRanges = (value) => {
  let selectedRange = [moment(new Date(new Date().setFullYear(new Date().getFullYear() - 1))), moment()]

  if (value === 1) {
    const lastYear = new Date(new Date().setFullYear(new Date().getFullYear() - 1))
    selectedRange = [moment(lastYear), moment(new Date())];
  } else if (value === 2) {
    selectedRange = [moment().subtract(6, 'months'), moment(new Date())]
  } else if (value === 3) {
    selectedRange = [moment().subtract(3, 'months'), moment(new Date())]
  } else if (value === 4) {
    selectedRange = [moment().subtract(1, 'months'), moment(new Date())]
  } else if (value === 5) {
    selectedRange = [moment().subtract(1, 'week'), moment(new Date())]
  }  else if (value === 7) {
    selectedRange = [moment().subtract(9, 'months'), moment(new Date())]
  }

  return selectedRange
}

export const getDateQuery = () => {

  let dateRange = []
  let nMonths = ""

  if(Object.keys(getStoredDates() || {}).length) {
    const {dateType, date = [], month} = getStoredDates() || {}
    dateRange = Number(dateType) === 6 ? date : getDateRanges(Number(dateType || 1))
    nMonths = month === null ? "" : month
  } else {
    dateRange = getDateRanges(1)
    nMonths = 12
  }

  return `&startDate=${moment((dateRange || [])[0]).format("YYYY-MM-DD")}&endDate=${moment((dateRange || [])[1]).format("YYYY-MM-DD")}&nMonths=${nMonths}`
}

export const getCategoriesIcon = (type) => {
  let src = Other

  if(type === "Supermarkets & Groceries") {
    src = Groceries
  } else if(type === "Gas Stations") {
    src = GasStation
  } else if(type === "Warehouses and Wholesale Stores") {
    src = WarehousesStores
  } else if(type === "Restaurants") {
    src = Restaurant
  } else if(type === "Other") {
    src = Other
  } else if(type === "Media") {
    src = Media
  } else if(type === "Transportation" || type === "Uber" || type === "Lyft") {
    src = Transportation
  } else if(type === "Recreation") {
    src = Recreation
  } else if(type === "Travel") {
    src = CompassIcon
  } else if(type === "Flights") {
    src = TravelIcon
  } else if(type === "Pharmacies") {
    src = Pharmacy
  } else if(type === "Department Stores") {
    src = Department
  } else if(type === "Gyms and Fitness Centers") {
    src = GymsAndFitness
  } else if(type === "Shops") {
    src = Shops
  } else if(type === "Service") {
    src = Service
  } else if(type === "Online Shopping") {
    src = OnlineShopping
  }

  return src
}

export const getCategoriesColor = (type) => {
  let color = ''

  if(type === "Supermarkets & Groceries") {
    color = "#3DD598"
  } else if(type === "Gas Stations") {
    color = "#044877"
  } else if(type === "Warehouses and Wholesale Stores") {
    color = "rgb(209 149 61)"
  } else if(type === "Restaurants") {
    color = "#FF974A"
  } else if(type === "Other") {
    color = "#404857"
  } else if(type === "Media") {
    color = "rgb(113 117 240)"
  } else if(type === "Transportation") {
    color = "rgb(218 131 234)"
  } else if(type === "Recreation") {
    color = "#7BDAFF"
  } else if(type === "Travel") {
    color = "#FF8976"
  } else if(type === "Flights") {
    color = "#d567ff"
  } else if(type === "Pharmacies") {
    color = "rgb(37 172 67)"
  } else if(type === "Department Stores") {
    color = "rgb(95 37 244)"
  } else if(type === "Gyms and Fitness Centers") {
    color = "rgb(59 183 230)"
  } else if(type === "Shops") {
    color = "#654987"
  } else if(type === "Service") {
    color = "#165955"
  } else if (type === "Uber" || type === "Lyft") {
    color = "#000"
  } else if (type === "Online Shopping") {
    color = "#FCCB2A"
  } else if (type === "Phone Service") {
    color = "#404857"
  } else if (type === "Cable Service") {
    color = "gray"
  } else if (type === "Internet Service") {
    color = "#572f1b"
  } else if (type === "Travel Credit" || type === "Travel Credit (Incl. Promotion)") {
    color = "olive"
  } else if (type === "Airline Fee Credit" || type === "Streaming Credit") {
    color = "silver"
  } else if (type === "Walgreens" || type === "Merchant Display Name") {
    color = "chocolate"
  } else if (type === "CVS") {
    color = "#5e68de"
  } else if (type === "PayPal") {
    color = "#e1a69f"
  } else if (type === "Amazon") {
    color = "#3f5926"
  } else if (type === "Walmart.com") {
    color = "#4B0082"
  } else if (type === "Target.com" || type === "DoorDash") {
    color = "#6F4E37"
  } else if (type === "Vivid Seats" || type === "Google Wallet") {
    color = "#7FFFD4"
  } else if (type === "Whole Foods") {
    color = "#5A7CC2"
  } else if (type === "Walmart") {
    color = "#9F00FF"
  } else if (type === "Costco") {
    color = "#8E3179"
  }

  return color || colors[Math.floor(Math.random() * colors.length)]
}

export const getCategoryIcon = (type) => {
  let src = ""

  if(type === "No Annual Fee") {
    src = NoAnnualFee
  } else if(type === "Intro Bonus") {
    src = IntroBonus
  } else if(type === "Cash Back") {
    src = CashBack
  } else if(type === "Travel") {
    src = Travel
  } else if(type === "Balance Transfer") {
    src = BalanceTransfer
  } else if(type === "0% Intro APR") {
    src = IntroAPR
  } else if(type === "Building Credit") {
    // src = BuildingCredit
    src = BuildingCredit
  } else if(type === "Rewards") {
    src = Rewards
  }

  return src
}

export const getFeaturesIcon = (type) => {
  let src = Rate

  if(type === "Travel Benefits") {
    src = TravelBenefits
  } else if(type === "No Foreign Transaction Fees") {
    src = NoForeignTransactionFees
  } else if(type === "Flat Rate Cash Back") {
    src = FlatRateCashBack
  } else if(type === "Rotating Categories") {
    src = RotatingCategories
  } else if(type === "Category Cash Back") {
    src = CategoryCashBack
  }

  return src
}

export const getNotesFromLuci = (text, type) => {
  let src = Notes
  let modifiedText = text

  if((modifiedText || "").startsWith("-")) {
    src = ThumbsDown
    modifiedText = (modifiedText || "").replace("-", "")
    modifiedText = (modifiedText || "").trim()
  } else if((modifiedText || "").startsWith("+")) {
    src = ThumbsUp
    modifiedText = (modifiedText || "").replace("+", "")
    modifiedText = (modifiedText || "").trim()
  }

  return type === "text" ? modifiedText : src
}

export const getCategoriesList = () => ["No Annual Fee", "Intro Bonus", "Cash Back", "Travel", "Balance Transfer", "0% Intro APR", "Building Credit", "Rewards"]

export const getFeaturesList = () => ["Travel Benefits", "No Foreign Transaction Fees", "Flat Rate Cash Back", "Rotating Categories", "Category Cash Back"]

export const getPasswordStrengthClass = (password = "") => {
  const passwordLength = password ? password.length : 0;
  if (passwordLength > 0 && passwordLength <= 4) {
    return 'weak';
  } else if(passwordLength > 4 && passwordLength <= 7) {
    return 'medium';
  } else if(passwordLength > 7) {
    return 'strong';
  } else {
    return 'none';
  }
}

export const getAccountsCardsLogo = (string) => {
  let url = ""
  if(string) {
    url = `data:image/gif;base64,${string}`
  }

  return url
}

export const getToolTipConstant = (array) => (
  (array || []).map((text, index) => (
    <div className="d-flex align-items-start" key={index.toString()}>
      <img src={Line} className="mt-10 mr-10"/>
      <p className="mb-1" dangerouslySetInnerHTML={{__html: text}}/>
    </div>
  ))
);

export const getLocalData = () => {
  if (!localStorage.getItem('LUCI_DATAS')) return {};
  try {
    return JSON.parse(localStorage.getItem('LUCI_DATAS'))
  } catch {
    localStorage.removeItem('LUCI_DATAS');
    return {};
  }
}

export const clearStorage = () => {
  localStorage.removeItem('AUTH_TOKEN')
  localStorage.removeItem('accounts')
  localStorage.removeItem('dates')
  localStorage.removeItem('costs')
  localStorage.removeItem('LUCI_DATAS')
};

export const firstYear = () => "1";
export const secondYear = () => "2";
export const lastYear = () => "3";

export const getCardsListByYearWise = (results, cards, selectedYear) => {

  if(!Object.keys(results || {}).length) {
    return []
  }

  let array = []

  if(selectedYear === lastYear()) {
    array = results?.[selectedYear] || []
  } else if(selectedYear === secondYear()) {
    array = results?.[selectedYear] || []
  } else if(selectedYear === firstYear()) {
    array = results?.[selectedYear] || []
  }

  return modifyCardsList([...array], cards)
}

export const modifyCardsList = (result, cardsObject) => {

  const cardsList = Object.values(cardsObject)

  result.forEach((card, cardIndex) => {
    const index = (cardsList || []).findIndex(y => y.id === card.cardId)
    if(index !== -1) {
      result[cardIndex] = {...cardsList[index], ...card}
    }
  })

  return onMakeTextBold(result)
}

export const onMakeTextBold = (resultArray) => {
  let modifiedList = []

  resultArray.forEach(x => {

    const { moreDetailInfo = [], introOfferDetail = [], rewardDetail = [], annualFeeDetail = [], annualFeeGeneralDetail = [], rewardRateGeneralDetail = [], introOfferGeneralDetail = [], stat = [] } = x || {}

    x.calculation = {
      isReward: (x.calculation || {}).isReward !== false,
      isIntro: (x.calculation || {}).isIntro !== false,
      isAnnual: (x.calculation || {}).isAnnual !== false
    }

    modifiedList.push({
      ...x,
      moreDetailInfo: onModifiedSentences(moreDetailInfo || []) || [],
      // introOfferDetail: onModifiedSentences(introOfferDetail || []) || [],
      // rewardDetail: onModifiedSentences(rewardDetail || []) || [],
      // annualFeeDetail: onModifiedSentences(annualFeeDetail || []) || [],
      // annualFeeGeneralDetail: onModifiedSentences(annualFeeGeneralDetail || []) || [],
      // rewardRateGeneralDetail: onModifiedSentences(rewardRateGeneralDetail || []) || [],
      // introOfferGeneralDetail: onModifiedSentences(introOfferGeneralDetail || []) || [],
      // stat: (stat || []).map(y => ({...y, tooltip: onModifiedSentences(y.tooltip || []) || []}))
    })
  })

  return modifiedList
}

export const onModifiedSentences = (array) => {
  let modifiedArray = []

  if(!Array.isArray(array)) {
    return modifiedArray;
  }

  array.forEach(text => {
    if(text) {
      let modified = ""
      let array = (text || "").split(": ")

      if((array || []).length > 1) {
        modified = `<b>${array[0]}</b>`
        modified += ": "
        array.forEach((x, index) => {
          if(index) {
            modified += x
          }
        })
      } else {
        modified = text
      }

      modifiedArray.push(modified)
    }
  });

  return modifiedArray
}

export const getSpendingCategoryList = (object, year) => {

  if(!Object.keys(object || {}).length) {
    return []
  }

  let array = []
  Object.keys(object).forEach(category => {
    array.push({name: category, spending: object?.[category]?.[year]?.spending})
  })

  array = array.sort((a,b) => b.spending - a.spending)
  return (array || []).map(x => x.name) || []
}
