import React,{ useState } from "react";
import {Link} from "react-router-dom";
import {getPasswordStrengthClass, scrollToTop, validateEmail, clearStorage, validatePassword} from '../../utils/constants';
import EYE from "../../assets/images/Inner/eye.svg";
import GOOGLE from "../../assets/images/Inner/google.svg";
import Spending from "../../assets/images/signup/spending.svg";
import Calculate from "../../assets/images/signup/calculate.svg";
import Reward from "../../assets/images/signup/reward.svg";
import Header from "../../common/components/AppLayout/Header";
import {ApiService} from "../../services/ApiService";
import "../../common/styles/inner.scss";

const descriptionArray = [
    {image: Spending, title: "All your spending in one place", description: "A clear breakdown of your total spending across all your cards by category."},
    {image: Calculate, title: "Calculate rewards with a single click", description: "View how much you could earn from all the best reward cards available along with a clear breakdown of how your spending translates into rewards."},
    {image: Reward, title: "Maximize your credit card rewards", description: "Personalized credit card recommendation based on your spending that maximizes your rewards. No more hours spent guesstimating or reading credit card blogs."}
]

const SignUp = (props) => {
    scrollToTop();

    const [data, setData] = useState({});
    const [loginError, setLoginError] = useState("");

    const passwordClass = getPasswordStrengthClass(data.password || "")

    const handleChange = (e) => {
        const {name, value} = e.target;
        setData({...data, [name]: value})
    };

    const onSignUp = async () => {
        setLoginError("");
        const { firstName, lastName, email, password, confirmPassword } = data;
        const errors = {};
        if (!firstName?.trim()) {
            errors.firstName = 'Please enter first name';
        }
        if (!lastName?.trim()) {
            errors.lastName = 'Please enter last name';
        }
        if (!email?.trim()) {
            errors.email = 'Please enter email address';
        }
        if (!password?.trim()) {
            errors.password = 'Please enter password';
        }
        if (validatePassword(password) !== true) {
            errors.password = validatePassword(password);
        }
        if (!confirmPassword?.trim()) {
            errors.confirmPassword = 'Please enter confirm password';
        }
        if (confirmPassword?.trim() !== password?.trim()) {
            errors.confirmPassword = 'Password and confirm password do not match';
        }
        if (!validateEmail(email)) {
            errors.email = "Please enter a valid email address"
        }
        setData({...data, errors: errors});
        if(errors && Object.keys(errors).length) return;
        const response = await ApiService.signUp({firstName, lastName, email, password});
        if(!response || response.error) {
            setLoginError(response.error || "")
        } else {
            // message.success({
            //     content: 'You have signed up successfully!',
            //     onClose: () => props.history.push('/signin')
            // })
            // this.props.onSetUserInfo(response)
            clearStorage();
            localStorage.removeItem("isUserLogin")
            localStorage.setItem("AUTH_TOKEN", response.accessToken)
            window.location.href = "/dashboard"
        }
    }
    
    const errors = data.errors || {};

    const onContinueWithGoogle = async () => {
        await ApiService.continueWithGoogle()
    }

    return (
        <div>
            <Header unAuth buttonTitle="Sign In" path="/signin" isSignup/>
            <section className="form-area signup-form">
                <div className="form-info-zone">
                    <div className="form-inputs">
                        <h1 className="text-center">Maximize your credit card rewards with luci - 100% free</h1>

                        <div className="form-items">
                            <div className="form-group">
                                <input type="text" id name="firstName" placeholder="First Name" className={`luci-input ${data.firstName?.trim() ? 'filled' : ''} ${errors.firstName ? ' required' : ''}`} onChange={handleChange} />
                                {
                                    errors.firstName ?
                                        <div className="ml-4">
                                            <small className="text-danger">{errors.firstName}</small>
                                        </div> : null
                                }
                            </div>
                            <div className="form-group">
                                <input type="text" id name="lastName" placeholder="Last Name" className={`luci-input ${data.lastName?.trim() ? 'filled' : ''} ${errors.lastName ? ' required' : ''}`} onChange={handleChange}/>
                                {
                                    errors.lastName ?
                                        <div className="ml-4">
                                            <small className="text-danger">{errors.lastName}</small>
                                        </div> : null
                                }
                            </div>
                            <div className="form-group">
                                <input type="email" id name="email" placeholder="Your Email" className={`luci-input ${data.email?.trim() ? 'filled' : ''} ${errors.email ? ' required' : ''}`} onChange={handleChange}/>
                                {
                                    errors.email ?
                                        <div className="ml-4">
                                            <small className="text-danger">{errors.email}</small>
                                        </div> : null
                                }
                            </div>
                            <div className="form-group password-input">
                                <input type={data.showPassword ? 'text' : 'password'} className={`luci-input ${data.password?.trim() ? 'filled' : ''} ${errors.password ? ' required' : ''}`} id name="password" onChange={handleChange} placeholder="Create Password"/>
                                <img src={EYE} onClick={() => handleChange({target: {name: 'showPassword', value: !data.showPassword}})} className="pwd-eye"/>
                                {
                                    errors.password ?
                                        <div className="ml-4">
                                            <small className="text-danger">{errors.password}</small>
                                        </div> : null
                                }
                            </div>
                            <div className="form-group password-input">
                                <input type="password" id name="confirmPassword" placeholder="Confirm password" className={`luci-input ${data.confirmPassword?.trim() ? 'filled' : ''} ${errors.confirmPassword ? ' required' : ''}`} onChange={handleChange}/>
                                {
                                    errors.confirmPassword ?
                                        <div className="ml-4">
                                            <small className="text-danger">{errors.confirmPassword}</small>
                                        </div> : null
                                }
                                {/*<div className={`password-strength ${passwordClass}`}>
                                    <span className="s-fill"/>
                                    <span className="s-fill"/>
                                    <span className="s-fill"/>
                                    <span className="s-fill"/>
                                    <span className="s-fill"/>
                                    <span className="ps-text">{passwordClass}</span>
                                </div>*/}
                            </div>
                            {
                                loginError ?
                                    <div className="text-center">
                                        <small className="text-danger">{loginError || "Something went wrong"}</small>
                                    </div> : null
                            }
                            <div className="form-group">
                                <p className="form-txt">By continuing, you are agreeing to luci’s <Link to="/terms"
                                                                                                        className="link-text">Terms
                                    of Service</Link> and <Link to="/privacy-policy" className="link-text">Privacy Policy</Link>
                                </p>
                            </div>
                            <div className="form-group">
                                <button
                                    className="btn luci-btn primary-btn font-weight-bold pt-3 pb-3 w-100 submit-btn"
                                    type="submit"
                                    onClick={onSignUp}
                                >
                                    Sign Up
                                </button>
                            </div>
                            <div className="form-group mt-4 mb-4">
                                <p className="or-txt">or sign up using</p>
                            </div>
                            <div className="form-group">
                                <div className="google-btn">
                                    <button className="btn luci-btn primary-btn w-100 google-btn" type="submit" onClick={onContinueWithGoogle}>
                                        <img src={GOOGLE}/>
                                        Continue with Google
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="description">
                        {
                            descriptionArray.map((details, index) => (
                                <div className="mb-5" key={index.toString()}>
                                    <div className="mb-2">
                                        <img src={details.image} />
                                    </div>
                                    <b>{details.title}</b>
                                    <p className="mt-2">{details.description}</p>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </section>
            <footer className="footer-bg">
                <div className="container">
                </div>
            </footer>
        </div>
    );
};

export default SignUp;


