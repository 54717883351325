import React from "react";
import { connect } from "react-redux";
import { Modal } from "antd";
import { PlaidLink } from "react-plaid-link";
import {setDashboardStep} from "../../../../../../redux/modules/dashboardStep/dashboardStepActions";
import Close from "../../../../../../assets/images/dashboard/close.svg";
import MonthAnalysis from "../../../../../../assets/images/money-modal/month-analysis.svg"
import RecurringExpense from "../../../../../../assets/images/money-modal/recurring-expense.svg"
import TransactionAnalysis from "../../../../../../assets/images/money-modal/transaction-analysis.svg"
import WeekAnalysis from "../../../../../../assets/images/money-modal/week-analysis.svg"
import Lock from "../../../../../../assets/images/dashboard/lock.svg";
import config from "../../../../../../config";
import {ApiService} from "../../../../../../services/ApiService";


class SpendMoneyModal extends React.Component {

    handleClick = async (publicKey, metaData) => {
        const { onAccountsLoading, getAccountService, onSetDashboardStep } = this.props
        onAccountsLoading()
        await ApiService.setPlaidAccount(publicKey, metaData);
        getAccountService(true);
        onSetDashboardStep({isFirstStepComplete: false});
        localStorage.removeItem('LUCI_DATAS')
        this.onButtonClick()
    }

    handleExit = async (error, metaData) => {
        const body = { error, metaData };
        await ApiService.handlePlaidError(body);
        // this.onButtonClick();
    }

    onButtonClick = () => {
        const { backToFirstStep } = this.props;
        this.onModalClose();
        backToFirstStep();
    }
    
    onModalClose = () => {
        this.props.onClose();
        setTimeout(() => document.body.style.overflow = '', 300);
    }

    render() {
        return (
            <Modal
                visible={true}
                footer={null}
                className="find-cards-modal spending-money-modal"
                onCancel={this.onModalClose}
            >
                <div className="modal-header border-bottom-0 p-0">
                    <h5 className="modal-title p-card-title text-center fs-25">
                        View additional information by securely sharing how you spend your money using plaid
                    </h5>
                </div>

                <div className="category-section">
                    <div className="row category-row">
                        <div className="col-md-6 col-sm-12 section-1 text-center">
                            <div className="pt-2">
                                <img src={RecurringExpense} />
                                <h5 className="pt-4 pb-4">
                                    Spending by Top Merchants & Recurring Expenses
                                </h5>
                            </div>
                        </div>
                        <div className="col-md-6 col-sm-12 section-2 text-center">
                            <div className="pt-2">
                                <img src={MonthAnalysis} />
                                <h5 className="pt-4 pb-4">
                                    Last 6 Months Spending Analysis
                                </h5>
                            </div>
                        </div>
                        <div className="col-md-6 col-sm-12 section-3 text-center">
                            <div className="pt-5">
                                <img src={WeekAnalysis} />
                                <h5 className="pt-4">
                                    Last 6 Weeks Spending Analysis
                                </h5>
                            </div>
                        </div>
                        <div className="col-md-6 col-sm-12 text-center">
                            <div className="pt-5">
                                <img src={TransactionAnalysis} />
                                <h5 className="pt-4">
                                    Analysis by Transactions & Category
                                </h5>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal-footer border-top-0 justify-content-center">
                    <button
                        className={`btn luci-btn primary-btn font-weight-bold share-plaid-btn`}
                        onClick={this.onButtonClick}
                    >
                        <img className="pb-1 mr-2" src={Lock}/>
                        Share Using Plaid
                    </button>
                </div>
            </Modal>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onSetDashboardStep: data => {
            dispatch(setDashboardStep(data));
        }
    }
}

export default connect(null, mapDispatchToProps)(SpendMoneyModal);
