import { handleActions } from "redux-actions";
import { initialAccountsState } from "./initialAccountsState";
import { AccountsConstants } from "./accountsConstants";
import {
  requestFail,
  requestPending,
  requestSuccess
} from "../../../utils/fetch";

export const accountsReducer = handleActions(
  {
    [requestSuccess(AccountsConstants.FETCH_ACCOUNTS)]: (
      state,
      action
    ) => ({
      ...state,
      accounts: action.payload,
      fetchAccountsLoading: false,
      fetchAccountsFailure: false,
      fetchAccountsLoaded: true,
    }),
    [requestPending(AccountsConstants.FETCH_ACCOUNTS)]: state => ({
      ...state,
      fetchAccountsLoading: true,
      fetchAccountsFailure: false,
      fetchAccountsLoaded: false,
    }),
    [requestFail(AccountsConstants.FETCH_ACCOUNTS)]: state => ({
      ...state,
      fetchAccountsLoading: true,
      fetchAccountsFailure: false,
      fetchAccountsLoaded: false,
      accounts: null,
    }),
    [AccountsConstants.SET_ACCOUNTS]: (
      state,
      action
    ) => ({
      ...state,
      banks: action.payload,
      fetchAccountsLoaded: true,
    }),
    [AccountsConstants.SET_RE_LOGIN_REQUIRED_INSTITUTES]: (
        state,
        action
    ) => ({
        ...state,
        reLoginRequiredInstitutes: action.payload
    })
  },
  initialAccountsState()
);
