import { firstYear } from "../../../utils/constants";

export const resultSettingState = () => ({
  topYearProjection: firstYear(),
  bottomYearProjection: firstYear(),
  allCardsYearProjection: firstYear(),
  topIncludedIntroBonus: true,
  bottomIncludedIntroBonus: false,
  linkToken: ""
});
