import React from "react";
import ReactWOW from "react-wow";
import Eddie from "../../assets/images/luci's-creator/Eddie.png"
import Kanji from "../../assets/images/luci's-creator/Kanji.png"
import Mike from "../../assets/images/luci's-creator/Mike.png"
import Richard from "../../assets/images/luci's-creator/Richard.png"
import Schwee from "../../assets/images/luci's-creator/Schwee.png"
import Luci from "../../assets/images/luci's-creator/Luci.png"


class AboutUs extends React.Component {

    render() {

        return(
            <section className="banner mb-95">
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-lg-12 banner-left">
                            <div className="banner-text wow fadeInLeft about-pt-50">
                                <h1> Our mission</h1>
                                <p>To save users money and time</p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 col-lg-4 about-pr-50">
                            <ReactWOW animation="fadeInLeft" delay="0.5s">
                                <div className="banner-text wow fadeInLeft">
                                    <div className="text-center mb-3">
                                        <img src={Eddie} className="w-120 br-50" />
                                    </div>
                                    <h4 className="text-center mb-0">Eddie Ha</h4>
                                    <div className="text-center fs-18">Engineering</div>
                                    <p className="fs-14 mt-3">
                                        Previously worked at DRW as a trader. Built a poker AI bot and placed 2nd in MIT Pokerbots.
                                        MIT grad with a degree in Mathematics with Computer Science.<br/> <b>Fun fact:</b> Directed and produced
                                        a viral <a target="_blank" href="https://www.youtube.com/watch?v=lJtHNEDnrnY&index=85&list=FLs24Vw79d12S48aOcVMPwJw">YouTube video</a>&nbsp;(5.5M views) starring Richard as the MIT Psy.
                                    </p>
                                </div>
                            </ReactWOW>
                        </div>
                        <div className="col-12 col-lg-4 about-pr-50">
                            <ReactWOW animation="fadeInRight" delay="0.5s">
                                <div className="banner-text wow fadeInLeft">
                                    <div className="text-center mb-3">
                                        <img src={Kanji} className="w-120 br-50" />
                                    </div>
                                    <h4 className="text-center mb-0">Kanji Godhani</h4>
                                    <div className="text-center fs-18">Engineering</div>
                                    <p className="fs-14 mt-3">
                                        Self taught coder who is a React, Node, .NET Core, and Javascript ninja. Enjoys building mobile app games for fun.
                                        Government Engineering College grad with a degree in Computer Science and Engineering.<br/>
                                        <b>Fun fact:</b> Taught himself how to code at 12 and has been coding ever since.
                                    </p>
                                </div>
                            </ReactWOW>
                        </div>
                        <div className="col-12 col-lg-4 about-pr-50">
                            <ReactWOW animation="fadeInRight" delay="0.5s">
                                <div className="banner-text wow fadeInLeft">
                                    <div className="text-center mb-3">
                                        <img src={Richard} className="w-120 br-50" />
                                    </div>
                                    <h4 className="text-center mb-0">Richard Yoon</h4>
                                    <div className="text-center fs-18">Product</div>
                                    <p className="fs-14 mt-3">
                                        Previously worked at Honey as the 2nd product manager and Goldman Sachs as a quant trader.
                                        MIT grad with a degree in Mechanical Engineering.<br/> <b>Fun fact:</b> Scored a sack against 2013 NFL Draft’s
                                        1st pick <a target="_blank" href="https://en.wikipedia.org/wiki/Eric_Fisher_(American_football)">Eric Fisher</a>&nbsp;- has a pic of it and will show it to anyone who asks and who doesn’t.
                                    </p>
                                </div>
                            </ReactWOW>
                        </div>
                        <div className="col-12 col-lg-4 about-pr-50">
                            <ReactWOW animation="fadeInLeft" delay="0.5s">
                                <div className="banner-text wow fadeInLeft">
                                    <div className="text-center mb-3">
                                        <img src={Schwee} className="w-120 br-50" />
                                    </div>
                                    <h4 className="text-center mb-0">Stephen Schwee</h4>
                                    <div className="text-center fs-18">Engineering</div>
                                    <p className="fs-14 mt-3">
                                        Previously worked at DRW as a trader. Won first place in University of Chicago's Midwest Algorithmic Trading Competition. Caltech grad with a degree in Mechanical Engineering and a degree in Business, Economics, and Management.<br/> <b>Fun fact:</b> Cooked dinner for Stephen Hawking.
                                    </p>
                                </div>
                            </ReactWOW>
                        </div>
                        {/*<div className="col-12 col-lg-4 about-pr-50">
                            <ReactWOW animation="fadeInRight" delay="0.5s">
                                <div className="banner-text wow fadeInLeft">
                                    <div className="text-center mb-3">
                                        <img src={Mike} className="w-120 br-50" />
                                    </div>
                                    <h4 className="text-center mb-0">Mike Teodorescu</h4>
                                    <div className="text-center fs-18">Advisor</div>
                                    <p className="fs-14 mt-3">
                                        Assistant Professor in the Information Systems Department at <a target="_blank" href="https://www.bc.edu/bc-web/schools/carroll-school/faculty-research/faculty-directory/mike-teodorescu.html">Boston College</a>. Author of several patents in the technology
                                        fields and co-author of several software related patent applications. Featured in McKinsey Analytics, IOT Solutions,
                                        and HBS Working Knowledge. PHD in Strategy and A.B. in Computer Science from Harvard.
                                    </p>
                                </div>
                            </ReactWOW>
                        </div>
                        <div className="col-12 col-lg-4 about-pr-50">
                            <ReactWOW animation="fadeInRight" delay="0.5s">
                                <div className="banner-text wow fadeInLeft">
                                    <div className="text-center mb-3">
                                        <img src={Luci} className="w-120 br-50" />
                                    </div>
                                    <h4 className="text-center mb-0">Luci Duan</h4>
                                    <div className="text-center fs-18">Inspiration</div>
                                    <p className="fs-14 mt-3">
                                        Personal finance wiz who always tells her friends about the best shopping deals, personal finance tips,
                                        and ways to maximize credit card rewards. Started investing her savings from her first job in a Roth IRA account at age 15.
                                        This app was named after Luci with the goal that just as Luci helps her friends, luci will help users save money and time!
                                    </p>
                                </div>
                            </ReactWOW>
                        </div>*/}
                    </div>
                </div>
            </section>
        )
    }

}

export default AboutUs
