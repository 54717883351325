import React from "react";
import { Route, Switch } from "react-router-dom";
import {
  unAuthRoutes
} from "../routes";

const LoggedOutRoutes = unAuthRoutes.map((r, i) => (
  <Route
    key={i}
    path={r.path}
    exact={r.exact}
    render={props => <r.main {...props} />}
  />
));

const Main = () => {
  return (
    <Switch>{[LoggedOutRoutes]}</Switch>
  );
};

export default Main;
