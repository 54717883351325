import React from "react";
import {connect} from "react-redux";
import MediaQuery from "react-responsive";
import {Link, withRouter} from "react-router-dom";
import bavBar from '../../../../assets/images/navbar-toggler-icon.svg';
import logo from '../../../../assets/images/logo.svg';
import { getAuthToken, onLogOut } from '../../../../services/ApiService';
import AdvertiserDisclosureModal from "./AdvertiserDisclosureModal"


class Header extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isModal: false
    }
  }

  onModalChange = () => {
    this.setState({
      isModal: !this.state.isModal
    })
  }

  render() {
    const { currentUser, unAuth, hideHumburger, buttonTitle, path, history } = this.props
    const { isModal } = this.state
    const { firstName, lastName, email } = currentUser || {};
    const getInitial = () => (firstName || lastName) ? `${(firstName || '')[0] || ''}${(lastName || '')[0] || ''}` : ((email || " ")[0] || " ").toUpperCase()
    return (
      <header className={unAuth ? "bg-form z-index-111" : "bg-header-b"}>

        { isModal ? <AdvertiserDisclosureModal onClose={this.onModalChange} /> : null }
        <div className="advertiser-disclosure"><small onClick={this.onModalChange}>Advertiser Disclosure</small></div>

        <div className={unAuth ? "container" : "container"}>
          <div className={unAuth ? "row bg-login" : "row bg-c"}>
            <div className="col">
              <nav className="navbar navbar-expand-lg navbar-light pt-5">
                <Link to="/" className="navbar-brand"><img src={logo} alt=""/></Link>
                {/*{
                  !hideHumburger &&
                    <button
                      className="navbar-toggler"
                      type="button"
                      data-toggle="dropdown"
                      data-target="#navbarTogglerDemo02"
                      aria-expanded="false"
                      aria-label="Toggle navigation"
                    >
                      <img src={bavBar} alt=""/>
                    </button>
                }*/}

                <div className={`collapse navbar-collapse`} id="navbarTogglerDemo02">
                  <ul className="navbar-nav ml-auto mt-2 mt-lg-0">
                    <li className="nav-item">
                      <a href="/#HowItWorks" className={`nav-link ${unAuth ? "" : "color-white"}`}>How it works</a>
                    </li>
                    <li className="nav-item">
                      <Link to="/support" className={`nav-link ${unAuth ? "" : "color-white"}`}>Support</Link>
                    </li>
                    <li className="nav-item">
                      <Link to="/about" className={`nav-link ${unAuth ? "" : "color-white"}`}>About us</Link>
                    </li>
                    {/*<li className="nav-item">
                      <a href="/blog" className={`nav-link ${unAuth ? "" : "color-white"}`}>Blog</a>
                    </li>*/}

                    {/*{ !getAuthToken() ?
                      <li className="nav-item">
                        <Link to="/signin" className="nav-link color-white">Sign In</Link>
                      </li> :
                      <li className="nav-item">
                        <a className="nav-link" onClick={() => history.push("/dashboard")}>Your Results</a>
                      </li>
                    }*/}

                  </ul>
                </div>

                <MediaQuery maxWidth={991}>
                  {
                    !hideHumburger &&
                      <a
                        className="navbar-toggler"
                        role="button"
                        id="navbarTogglerDemo02"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <img src={bavBar} alt=""/>
                      </a>
                  }
                  <div className={`collapse navbar-collapse logout-area dropdown-menu`} aria-labelledby="navbarTogglerDemo02">
                    <a href="/#HowItWorks" className={`nav-link lh-25`}>How it works</a>
                    <Link to="/support" className={`nav-link lh-25`}>Support</Link>
                    <Link to="/about" className={`nav-link lh-25`}>About us</Link>
                    {/*<a href="/blog" className={`nav-link lh-25`}>Blog</a>*/}
                  </div>
                </MediaQuery>

                {
                  getAuthToken() ?
                    <div className="form-inline my-2 my-lg-0 user-name home-login dropdown show lh-">
                      <a
                        className="nav-link"
                        role="button"
                        id="collapseLogout"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <span className="color-white">{firstName || ""} { ' ' } {lastName || ""}</span>
                        <span className="short-name">{getInitial()}</span>
                      </a>
                      <div className={`collapse logout-area dropdown-menu p-0`} aria-labelledby="collapseLogout">
                        <Link className="account" to={!getAuthToken() ? "/signin" : "/dashboard"}>Your Results</Link>
                        <Link className="account" to="/account">Account</Link>
                        <a onClick={onLogOut}>Logout</a>
                      </div>
                    </div> :
                    (buttonTitle || "") ?
                      <form className="form-inline my-2 my-lg-0 btn-signup">
                        <button className="btn luci-btn primary-btn ml-3 font-weight-bold" onClick={()=>{ history.push(path)} }>
                          <Link to={path} className="primary-btn">{buttonTitle || ""}</Link>
                        </button>
                      </form> : null
                }
              </nav>
            </div>
          </div>
        </div>
      </header>
    );
  }
}

const mapStateToProps = state => ({
  currentUser: state.userDetails.currentUser || {}
})

export default withRouter(connect(mapStateToProps,null)(Header))
