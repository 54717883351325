const banks = [{
  bankName: 'Debit Cards',
  mask: 'Checking Acct.',
  accounts: [],
  transactions: [],
  key: 'ChaseCY2'
},{
  bankName: 'Credit Cards',
  mask: '',
  accounts: [],
  transactions: [],
  key: 'ChaseRY'
}];

export const initialAccountsState = () => ({
  fetchAccountsLoading: false,
  fetchAccountsFailure: false,
  fetchAccountsLoaded: false,
  banks: [...banks],
  reLoginRequiredInstitutes: [],
});
