import React from "react";
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import EYE from "../../assets/images/Inner/eye.svg";
import GOOGLE from "../../assets/images/Inner/google.svg";
import Header from "../../common/components/AppLayout/Header";
import {ApiService} from "../../services/ApiService";
import {clearStorage, scrollToTop, validateEmail} from "../../utils/constants";
import {setUserDetails} from "../../redux/modules/userDetails/userDetailsActions";
import "../../common/styles/form.scss";
import "../../common/styles/_components.scss";

class SignIn extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            email: "",
            password: "",
            isShowPassword: false,
            loginError: '',
            errors: {}
        }
    }

    componentWillMount() {
        scrollToTop();
    }

    onChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value
        })
    }

    onSignIn = async () => {
        const { email, password } = this.state
        let errors = {}
        if(!email) {
            errors.email = "Please enter email address"
        }
        if (!validateEmail(email)) {
            errors.email = "Please enter valid email address"
        }
        if(!password) {
            errors.password = "Password required"
        }
        this.setState({
            errors
        });
        if(errors && Object.keys(errors).length) return;
        const response = await ApiService.signIn({email, password})
        if(!response || response.error) {
            this.setState({
                loginError: response.error || ""
            })
        } else {
            this.props.onSetUserInfo(response)
            clearStorage();
            localStorage.removeItem("isUserLogin")
            localStorage.setItem("AUTH_TOKEN", response.accessToken)
            window.location.href = "/dashboard"
        }
    }

    onContinueWithGoogle = async () => {
        await ApiService.continueWithGoogle()
    }

    render() {
        const { email, password, isShowPassword, errors, loginError } = this.state;
        
        return(
            <div>
                <Header unAuth buttonTitle="Join" path="/signup"/>
                <section className="form-area">
                    <h1>Account Login</h1>
                    <div className="form-items">
                        <div className="form-group">
                            <input
                                type="email"
                                // id
                                name="email"
                                placeholder="Your Email"
                                className={`luci-input ${email?.trim() ? 'filled' : ''} ${errors.email ? ' required' : ''}`}
                                value={email}
                                onChange={this.onChange}
                            />
                            {
                                errors.email ?
                                    <div className="ml-4">
                                        <small className="text-danger">{errors.email}</small>
                                    </div> : null
                            }
                        </div>
                        <div className="form-group password-input mb-0">
                            <input
                                type={isShowPassword ? "text" : "password"}
                                // id
                                name="password"
                                placeholder="Password"
                                className={`luci-input ${password?.trim() ? 'filled' : ''} ${errors.password ? ' required' : ''}`}
                                value={password}
                                onChange={this.onChange}
                            />
                            <img src={EYE} className="pwd-eye" onClick={() => this.setState({isShowPassword: !isShowPassword})}/>
                        </div>
                        {
                            errors.password ?
                                <div className="ml-4">
                                    <small className="text-danger">{errors.password}</small>
                                </div> : null
                        }
                        <div className="form-group ml-3 mb-4 mt-3">
                            <div className="d-flex justify-content-between">
                                <div className="custom-control custom-checkbox mb-3">
                                    <input type="checkbox" className="custom-control-input" id="customCheck"
                                           name="example1"/>
                                    <label className="custom-control-label" htmlFor="customCheck">Remember Me</label>
                                </div>
                                <div className="">
                                    <Link to="/forgot-password" className="link-text">Forgot Password</Link>
                                </div>
                            </div>
                        </div>
                        {
                            loginError ?
                                <div className="text-center">
                                    <small className="text-danger">{loginError || "Something went wrong"}</small>
                                </div> : null
                        }
                        <div className="form-group">
                            <p className="form-txt">By continuing, you are agreeing to luci’s <Link to="/terms"
                                                                                                 className="link-text">Terms
                                of Service</Link> and <Link to="/privacy-policy" className="link-text">Privacy Policy</Link>
                            </p>
                        </div>
                        <div className="form-group">
                            <button
                                className="btn luci-btn primary-btn font-weight-bold pt-3 pb-3 w-100 submit-btn"
                                type="submit"
                                onClick={this.onSignIn}
                            >
                                Sign In
                            </button>
                        </div>
                        <div className="form-group mt-4 mb-4">
                            <p className="or-txt">or sign up using</p>
                        </div>
                        <div className="form-group">
                            <div className="google-btn">
                                <button className="btn luci-btn primary-btn w-100 google-btn" type="submit" onClick={this.onContinueWithGoogle}>
                                    <img src={GOOGLE}/>
                                    Continue with Google
                                </button>
                            </div>
                        </div>
                    </div>
                </section>
                <footer className="footer-bg">
                    <div className="container">
                    </div>
                </footer>
            </div>
        )
    }

}

const mapDispatchToProps = (dispatch) => {
    return {
        onSetUserInfo: (data) => {
            dispatch(setUserDetails(data))
        }
    }
}

export default connect(null,mapDispatchToProps)(SignIn)
