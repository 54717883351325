import React from "react";
import { Modal, Tooltip } from "antd";
import {
    getCardImageURL, getCategoryIcon, getFeaturesIcon,
    getNotesFromLuci,
    getToolTipConstant
} from "../../../../../../utils/constants";
import Help from "../../../../../../assets/images/dashboard/help-icon.svg";
import Line from "../../../../../../assets/images/dashboard/line.svg";


class CardDetailsModal extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            activeTab: "1"
        }
    }

    getFormattedAPR = (value, index) => {
        let stringValue = "";
        const array = value.split(",") || [];
        if ((array || []).length && array[index] || "") {
            stringValue = (array[index] || "").replace(index ? "]" : "[", "")
        }
        return stringValue
    }

    onTabChange = (tab) => {
        this.setState({
            activeTab: tab
        })
    }

    render() {
        const { onCancel, selectedCardInfo } = this.props
        const { activeTab } = this.state
        const {
            cardId,
            name,
            moreDetailInfo = [],
            cardInfo,
            moreDetailNotes = [],
            annualFeeDisplay = [],
            annualFeeGeneralDetail = [],
            rewardRateGeneralDetail = [],
            APR = "",
            introAPR = "",
            regularAPR = "",
            link = "",
            introOffer = "",
            rewardRate = "",
            introOfferGeneralDetail = [],
            creditRange = [],
            category = [],
            features = []
        } = selectedCardInfo || {}
        return (
            <Modal
                visible={true}
                footer={null}
                onCancel={onCancel}
                width={'none'}
                className="card-detail-modal bd-example-modal-lg modal-xl"
            >
                <div>
                    <div
                        className="modal-content p-0"
                    >
                        <div className="modal-header">
                            <h5 className="modal-title">Card Details</h5>
                        </div>
                        <div className="modal-body">
                            <div className="card-first">
                                <div className="t-card-name">
                                    <h4>{name || ""}</h4>
                                    <p className="t-card-info">{cardInfo || ''}</p>
                                </div>
                                <div className="card-modal-line">
                                    <div className="t-card-img">
                                        <img className="card-image" src={getCardImageURL(cardId)}/>
                                        <button
                                            className="btn luci-btn primary-btn shadow-btn font-weight-bold"
                                            onClick={() => window.open(link, "_blank")}
                                            type="submit">Apply
                                        </button>
                                    </div>

                                    <div className="card-f">
                                        <div className="cf-item">
                                            <span className="cf-title">Annual Fee</span>
                                            <span className="cf-value">
                                                {(annualFeeDisplay || []).map((fee, index) => <span key={index.toString()}>{`${index === 0 ? fee : ` (${fee})`}`}</span>)}
                                                { !!(annualFeeGeneralDetail || []).length &&
                                                <Tooltip
                                                    title={getToolTipConstant(annualFeeGeneralDetail || [])}
                                                    trigger={"click"}
                                                >
                                                    <img className="help-icon ml-1" src={Help}/>
                                                </Tooltip>
                                                }
                                            </span>
                                        </div>
                                        <div className="cf-item">
                                            <span className="cf-title">Intro Offer</span>
                                            <span className="cf-value">
                                                {introOffer?.intro_description || ""}
                                                { !!(introOfferGeneralDetail || []).length &&
                                                <Tooltip
                                                    title={getToolTipConstant(introOfferGeneralDetail || [])}
                                                    trigger={"click"}
                                                >
                                                    <img className="help-icon ml-1" src={Help}/>
                                                </Tooltip>
                                                }
                                            </span>
                                        </div>
                                        <div className="cf-item">
                                            <span className="cf-title">Reward Rate</span>
                                            <span className="cf-value">
                                                {rewardRate}
                                                { !!(rewardRateGeneralDetail || []).length &&
                                                <Tooltip
                                                    title={getToolTipConstant(rewardRateGeneralDetail || [])}
                                                    trigger={"click"}
                                                    getPopupContainer={trigger => trigger.parentElement}
                                                    overlayClassName="hide-triangle-icon"
                                                >
                                                    <img className="help-icon ml-1" src={Help}/>
                                                </Tooltip>
                                                }
                                            </span>
                                        </div>
                                        <div className="cf-item">
                                            <span className="cf-title">Credit Range</span>
                                            <span className="cf-value">{creditRange.join(" - ")}</span>
                                        </div>

                                    </div>
                                </div>
                            </div>

                            <div className="card-tab">
                                <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                                    <li className="nav-item" onClick={() => this.onTabChange("1")}>
                                        <a className={`nav-link ${activeTab === "1" ? "active" : ""}`} id="pills-profile-tab" data-toggle="pill"
                                           href="#pills-profile" role="tab" aria-controls="pills-profile"
                                           aria-selected="false"
                                        >
                                            Category & Features
                                        </a>
                                    </li>
                                    <li className="nav-item" onClick={() => this.onTabChange("2")}>
                                        <a className={`nav-link ${activeTab === "2" ? "active" : ""}`} id="pills-details-tab" data-toggle="pill"
                                           href="#pills-details" role="tab" aria-controls="pills-details"
                                           aria-selected="true"
                                        >
                                            Card Details
                                        </a>
                                    </li>
                                    <li className="nav-item" onClick={() => this.onTabChange("3")}>
                                        <a className={`nav-link ${activeTab === "3" ? "active" : ""}`} id="APR-profile-tab" data-toggle="pill"
                                           href="#pills-APR" role="tab" aria-controls="pills-APR"
                                           aria-selected="false"
                                        >
                                            APR
                                        </a>
                                    </li>

                                </ul>
                                <div className="tab-content" id="pills-tabContent">
                                    <div className={`tab-pane fade see-more ${activeTab === "2" ? "show active" : ""}`} id="pills-details"
                                         role="tabpanel" aria-labelledby="pills-details-tab">

                                        <div className="card-dtl">
                                            <div className="card-dtl-l">
                                                <div className="cr-title">
                                                    Details
                                                </div>
                                                {
                                                    (moreDetailInfo || []).map((reward, index) => (
                                                        <div className="card-dtl-item" key={index.toString()}>
                                                            <img src={Line} className="mt-2"/>
                                                            <p dangerouslySetInnerHTML={{__html: reward}}/>
                                                        </div>
                                                    ))
                                                }
                                            </div>
                                            <div className="card-dtl-r">
                                                <div className="cr-title">
                                                    Notes from luci
                                                </div>
                                                {
                                                    (moreDetailNotes || []).map((note, index) => (
                                                        <div className="card-dtl-item" key={index.toString()}>
                                                            <img className={`w-20 ${(note || "").startsWith("+") ? "mt-0" : ""}`} src={getNotesFromLuci(note, "src")}/>
                                                            <p>{getNotesFromLuci(note, "text")}</p>
                                                        </div>
                                                    ))
                                                }
                                            </div>
                                        </div>

                                    </div>
                                    <div className={`tab-pane fade ${activeTab === "1" ? "show active" : ""}`} id="pills-profile" role="tabpanel"
                                         aria-labelledby="pills-profile-tab">

                                        <div className="card-features">
                                            <div className="card-f-area">
                                                <div className="card-left popup-card-left">
                                                    <div className="cr-title">
                                                        Category
                                                    </div>
                                                    <div className="cf-icons">
                                                        {
                                                            (category || []).map((cat, index) => (
                                                                <div className="cfi-item" key={index.toString()}>
                                                                    <img src={getCategoryIcon(cat)}/>
                                                                    <span>{cat}</span>
                                                                </div>
                                                            ))
                                                        }
                                                    </div>
                                                </div>
                                                <div className="card-right popup-card-right">
                                                    <div className="cr-title">
                                                        Features & Other Rewards
                                                    </div>
                                                    <div className="cf-icons">
                                                        {
                                                            (features || []).map((feature, index) => (
                                                                <div className="cfi-item" key={index.toString()}>
                                                                    <img src={getFeaturesIcon(feature)}/>
                                                                    <span>{feature}</span>
                                                                </div>
                                                            ))
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className={`tab-pane fade ${activeTab === "3" ? "show active" : ""}`} id="pills-APR" role="tabpanel"
                                         aria-labelledby="APR-profile-tab">

                                        <div className="card-features">
                                            <div className="card-f-area">
                                                <div className="card-left popup-card-left">
                                                    <div className="cr-title">
                                                        Intro APR
                                                    </div>
                                                    <div className="cf-icons">
                                                        <img src={Line}/> &nbsp;&nbsp;{introAPR || this.getFormattedAPR(APR, 0)}
                                                    </div>
                                                </div>
                                                <div className="card-right popup-card-right">
                                                    <div className="cr-title">
                                                        Regular APR
                                                    </div>
                                                    <div className="cf-icons">
                                                        <img src={Line}/> &nbsp;&nbsp;{regularAPR || this.getFormattedAPR(APR, 1)}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </Modal>
        );
    }
}



export default CardDetailsModal;
