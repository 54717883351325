import { handleActions } from "redux-actions";
import { initialCardsState } from "./initialCardsState";
import { CardsConstants } from "./cardsConstants";
import {
  requestFail,
  requestPending,
  requestSuccess
} from "../../../utils/fetch";


export const cardsReducer = handleActions(
  {
    [requestSuccess(CardsConstants.FETCH_CARDS)]: (
      state,
      action
    ) => ({
      ...state,
      accounts: action.payload,
      fetchCardsLoading: false,
      fetchCardsFailure: false,
      fetchCardsLoaded: true,
    }),
    [requestPending(CardsConstants.FETCH_CARDS)]: state => ({
      ...state,
      fetchCardsLoading: true,
      fetchCardsFailure: false,
      fetchCardsLoaded: false,
    }),
    [requestFail(CardsConstants.FETCH_CARDS)]: (
      state,
      action
    ) => ({
      ...state,
      fetchCardsLoading: false,
      fetchCardsFailure: true,
      fetchCardsLoaded: true,
      errorMessage: action.payload,
      cardsDetails: {},
    }),
    [CardsConstants.SET_CARDS]: (
      state,
      action
    ) => ({
      ...state,
      cardsDetails: action.payload,
      errorMessage: "",
      fetchCardsLoaded: true
    }),
  },
  initialCardsState()
);
