import { handleActions } from "redux-actions";
import { ResultSettingConstants } from "./resultSettingConstants";
import { resultSettingState } from "./resultSettingState";

export const resultSettingReducer = handleActions(
    {
        [ResultSettingConstants.SET_RESULT_SETTING]: (
            state,
            action
        ) => ({
            ...state,
            ...action.payload
        }),
    },
    resultSettingState()
);