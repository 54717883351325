import React from "react";
import ReactWOW from 'react-wow'
import { scrollToTop } from '../../utils/constants';
import FAQ from "../../assets/images/Inner/faq.svg";
import TitleLine from "../../assets/images/title-line.svg";
import ARROW from "../../assets/images/Inner/arrow.svg";

const Support = () => {
  scrollToTop();
  return (
      <>
          <section className="banner">
              <div className="container">
                  <div className="row">
                      <div className="col-12 col-lg-6 banner-left">
                          <div className="banner-text wow fadeInLeft" data-wow-delay="0.5s">
                              <h1> Support and FAQ</h1>
                              <p>We always have your security in mind. Rest easy knowing that your data is protected with bank-level SSL encryption and 256-bit encryption.</p>
                          </div>
                      </div>
                      <div className="col-12 col-lg-6 banner-right">
                          <div className="card-area text-right">
                              <ReactWOW animation="fadeInRight" delay="0.5s">
                                  <figure className="card-lg">
                                      <img src={FAQ} alt="" />
                                  </figure>
                              </ReactWOW>
                          </div>
                      </div>
                  </div>
              </div>
          </section>
          <section className="informative-area" id="info-area">
              <div className="container">
                  <div className="row">
                      <div className="col-lg-12">
                          <ReactWOW animation="fadeInUp" delay="0.5s">
                            <h2>Frequently asked questions</h2>
                          </ReactWOW>
                          <img src={TitleLine}  />
                      </div>
                      <div className="col-lg-12 mt-5 faq-area">
                          <div className="info-text">
                              <div className="accordion" id="accordionExample">
                                  <div className="card z-in9">
                                      <div className="card-header" id="headingOne">
                                          <button className="btn btn-link" type="button" data-toggle="collapse"
                                                  data-target="#collapseOne" aria-expanded="true"
                                                  aria-controls="collapseOne">
                                              What does luci do?
                                              <img src={ARROW} className="arrow-toggle"/>
                                          </button>
                                      </div>
                                      <div id="collapseOne" className="collapse show" aria-labelledby="headingOne">
                                          <div className="card-body">
                                              Say goodbye to hours of reading through credit card blogs to find your
                                              credit card. luci recommends credit cards that will maximize your rewards
                                              by understanding how you spend your money.
                                          </div>
                                      </div>
                                  </div>
                                  <div className="card">
                                      <div className="card-header" id="headingTwo">
                                          <h2 className="mb-0">
                                              <button className="btn btn-link collapsed" type="button"
                                                      data-toggle="collapse" data-target="#collapseTwo"
                                                      aria-expanded="false" aria-controls="collapseTwo">
                                                  How does luci work?
                                                  <img src={ARROW} className="arrow-toggle"/>
                                              </button>
                                          </h2>
                                      </div>
                                      <div id="collapseTwo" className="collapse" aria-labelledby="headingTwo">
                                          <div className="card-body">
                                              Using the spending data that you share with luci, luci’s patent pending
                                              technology calculates what your rewards would have been if you had spent
                                              your money using different credit cards. <br/><br/>
                                              luci calculates your reward potential for all the credit cards that are in
                                              luci’s database, and provides you with a personalized recommendation on
                                              cards that could give you the most reward for your spending habit.
                                          </div>
                                      </div>
                                  </div>
                                  <div className="card">
                                      <div className="card-header" id="headingThree">
                                          <h2 className="mb-0">
                                              <button className="btn btn-link collapsed" type="button"
                                                      data-toggle="collapse" data-target="#collapseThree"
                                                      aria-expanded="false" aria-controls="collapseThree">
                                                  How does luci protect my personal information?
                                                  <img src={ARROW} className="arrow-toggle"/>
                                              </button>
                                          </h2>
                                      </div>
                                      <div id="collapseThree" className="collapse" aria-labelledby="headingThree">
                                          <div className="card-body">
                                              luci uses bank-level SSL encryption and secures your data with 256-bit
                                              encryption.
                                          </div>
                                      </div>
                                  </div>
                                  <div className="card">
                                      <div className="card-header" id="heading-f">
                                          <h2 className="mb-0">
                                              <button className="btn btn-link collapsed" type="button"
                                                      data-toggle="collapse" data-target="#collapse-f"
                                                      aria-expanded="false" aria-controls="collapse-f">
                                                  Does luci sell my information to third parties?
                                                  <img src={ARROW} className="arrow-toggle"/>
                                              </button>
                                          </h2>
                                      </div>
                                      <div id="collapse-f" className="collapse" aria-labelledby="heading-f" >
                                          <div className="card-body">
                                              luci does not sell any of your personal information to third parties.
                                          </div>
                                      </div>
                                  </div>
                                  {/*<div className="card">
                                      <div className="card-header" id="heading-five">
                                          <h2 className="mb-0">
                                              <button className="btn btn-link collapsed" type="button"
                                                      data-toggle="collapse" data-target="#collapse-five"
                                                      aria-expanded="false" aria-controls="collapse-five">
                                                  Does my transaction data get stored or tracked?
                                                  <img src={ARROW} className="arrow-toggle"/>
                                              </button>
                                          </h2>
                                      </div>
                                      <div id="collapse-five" className="collapse" aria-labelledby="heading-five" >
                                          <div className="card-body">
                                              luci does not store or track your transaction data. Your transaction data
                                              is used to present you with results and then removed from our system.
                                          </div>
                                      </div>
                                  </div>*/}
                                  <div className="card">
                                      <div className="card-header" id="heading-fv">
                                          <h2 className="mb-0">
                                              <button className="btn btn-link collapsed" type="button"
                                                      data-toggle="collapse" data-target="#collapse-fv"
                                                      aria-expanded="false" aria-controls="collapse-fv">
                                                  Is luci free and how does luci make money?
                                                  <img src={ARROW} className="arrow-toggle"/>
                                              </button>
                                          </h2>
                                      </div>
                                      <div id="collapse-fv" className="collapse" aria-labelledby="heading-fv" >
                                          <div className="card-body">
                                              luci is 100% free to users. luci receives compensation from credit
                                              card partners when users sign-up for the card using links on luci. Not all
                                              credit cards give luci compensation but luci still displays them to
                                              give users the best recommendation possible. luci is totally unbiased and does
                                              NOT factor in affiliate compensation in its card recommendations to users.
                                          </div>
                                      </div>
                                  </div>
                                  <div className="card">
                                      <div className="card-header" id="heading-s">
                                          <h2 className="mb-0">
                                              <button className="btn btn-link collapsed" type="button"
                                                      data-toggle="collapse" data-target="#collapse-s"
                                                      aria-expanded="false" aria-controls="collapse-s">
                                                  Why do I need to connect to my bank accounts?
                                                  <img src={ARROW} className="arrow-toggle"/>
                                              </button>
                                          </h2>
                                      </div>
                                      <div id="collapse-s" className="collapse" aria-labelledby="heading-s">
                                          <div className="card-body">
                                              By securely connecting to your bank accounts using Plaid,
                                              you can share your spending data with luci. luci only receives your
                                              spending history and nothing else. luci and Plaid cannot make transactions
                                              or transfers on your accounts.
                                          </div>
                                      </div>
                                  </div>
                                  <div className="card">
                                      <div className="card-header" id="heading-sv">
                                          <h2 className="mb-0">
                                              <button className="btn btn-link collapsed" type="button"
                                                      data-toggle="collapse" data-target="#collapse-sv"
                                                      aria-expanded="false" aria-controls="collapse-sv">
                                                  What is Plaid and is it secure?
                                                  <img src={ARROW} className="arrow-toggle"/>
                                              </button>
                                          </h2>
                                      </div>
                                      <div id="collapse-sv" className="collapse" aria-labelledby="heading-sv">
                                          <div className="card-body">
                                              <a href="https://plaid.com/security/" target="_blank">Plaid</a> helps 
                                              you securely connect apps and services with your bank
                                              accounts. Plaid uses a combination of Advanced Encryption Standard
                                              (AES-256) and Transport Layer Security (TLS) to help keep your personal
                                              information safe end-to-end. The end-to-end encryption ensures that luci
                                              does not receive or see your bank account login credentials.
                                          </div>
                                      </div>
                                  </div>
                                  <div className="card">
                                      <div className="card-header" id="heading-e">
                                          <h2 className="mb-0">
                                              <button className="btn btn-link collapsed" type="button"
                                                      data-toggle="collapse" data-target="#collapse-e"
                                                      aria-expanded="false" aria-controls="collapse-e">
                                                  How do I get started?
                                                  <img src={ARROW} className="arrow-toggle"/>
                                              </button>
                                          </h2>
                                      </div>
                                      <div id="collapse-e" className="collapse" aria-labelledby="heading-e">
                                          <div className="card-body">
                                              Simply make an account by clicking <a href="/signup"
                                                                                    target="_blank">here</a>. We are
                                              excited to help you save money and time!
                                          </div>
                                      </div>
                                  </div>
                                  <div className="card">
                                      <div className="card-header" id="heading-v">
                                          <h2 className="mb-0">
                                              <button className="btn btn-link collapsed" type="button"
                                                      data-toggle="collapse" data-target="#collapse-v"
                                                      aria-expanded="false" aria-controls="collapse-v">
                                                  How can I get in touch?
                                                  <img src={ARROW} className="arrow-toggle"/>
                                              </button>
                                          </h2>
                                      </div>
                                      <div id="collapse-v" className="collapse" aria-labelledby="heading-v">
                                          <div className="card-body">
                                              We love hearing from our users. Email us at <a
                                              href="mailto:support@joinluci.com">support@joinluci.com</a>.
                                          </div>
                                      </div>
                                  </div>
                                  {/*<div className="card">
                                      <div className="card-header" id="heading-eleven">
                                          <h2 className="mb-0">
                                              <button className="btn btn-link collapsed" type="button"
                                                      data-toggle="collapse" data-target="#collapse-eleven"
                                                      aria-expanded="false" aria-controls="collapse-eleven">
                                                  How do I disconnect my credit card/ bank from luci?
                                                  <img src={ARROW} className="arrow-toggle"/>
                                              </button>
                                          </h2>
                                      </div>
                                      <div id="collapse-eleven" className="collapse" aria-labelledby="heading-eleven">
                                          <div className="card-body">
                                              Please email us at <a href="mailto:support@joinluci.com">support@joinluci.com</a>.
                                          </div>
                                      </div>
                                  </div>*/}
                                  <div className="card">
                                      <div className="card-header" id="heading-eleven">
                                          <h2 className="mb-0">
                                              <button
                                                  className="btn btn-link collapsed"
                                                  type="button"
                                                  data-toggle="collapse"
                                                  data-target="#collapse-eleven"
                                                  aria-expanded="false"
                                                  aria-controls="collapse-eleven"
                                              >
                                                  Why is it called luci?
                                                  <img src={ARROW} className="arrow-toggle"/>
                                              </button>
                                          </h2>
                                      </div>
                                      <div id="collapse-eleven" className="collapse" aria-labelledby="heading-eleven">
                                          <div className="card-body">
                                              We named it after our friend Luci, who always tells us about the best shopping deals,
                                              personal finance tips, and ways to maximize credit card rewards! Just as Luci helps us,
                                              we hope luci will help our users save money and time!
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </section>
        </>
  );
};

export default Support;
