import { handleActions } from "redux-actions";
import {DashboardStepConstants} from "./dashboardStepConstants";
import {dashboardStepState} from "./dashboardStepState";

export const dashboardStepReducer = handleActions(
    {
        [DashboardStepConstants.SET_DASHBOARD_STEP]: (
            state,
            action
        ) => ({
            ...state,
            ...action.payload
        }),
    },
    dashboardStepState()
);