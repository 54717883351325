import {call, put, takeLatest} from "redux-saga/effects";
import { request } from "../../../utils/fetch";
import { CardsConstants } from "./cardsConstants";
import {getAPIEndpoint} from "../../../services/ApiService";

function* fetchCards(action) {
  const { apiEndpoint, query, onSuccess, onFail } = action.payload
  yield call(
    request({
      type: CardsConstants.FETCH_CARDS,
      method: "post",
      url: getAPIEndpoint(`/plaid/auth/${apiEndpoint}${query}`),
      success: onSuccess ? onSuccess : null,
      fail: onFail ? onFail : null
    }),
    action
  );
}

function putCards(payload) {
  put({
    type: CardsConstants.SET_CARDS,
    payload
  });
}

export default function* rootSaga() {
  yield takeLatest(CardsConstants.FETCH_CARDS, fetchCards);
  yield takeLatest(CardsConstants.SET_CARDS, putCards);
}
