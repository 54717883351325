import React from "react";
import {Link} from "react-router-dom";
import Header from "../../common/components/AppLayout/Header";
import "../../common/styles/inner.scss";
import {ApiService} from "../../services/ApiService";

class ForgotPassword extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            email: "",
            successMessage: ""
        }
    }

    onChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value,
            successMessage: "",
            errorMessage: ""
        })
    }

    onForgotPassword = async () => {
        const { email } = this.state

        const response = await ApiService.forgotPassword({email})
        console.log({response})
        if(!response || response.error) {
            this.setState({
                errorMessage: response.error || ""
            })
        } else {
            this.setState({
                successMessage: response.message || "",
                email: ""
            })
        }
    }


    render() {
        const { email, successMessage, errorMessage } = this.state
        return(
            <div>
                <Header unAuth buttonTitle="Join" path="/signup"/>
                <section className="form-area">
                    <h1>Forgot Password</h1>
                    <div className="form-items">
                        <div className="form-group">
                            <input type="email" value={email} id name="email" onChange={this.onChange} placeholder="Your Email" className="luci-input" />
                        </div>
                        <div className="form-group mb-2">
                            <p className="form-txt">We will send you instructions on how to reset your password by email.</p>
                        </div>
                        {
                            (successMessage || errorMessage) ?
                                <div className="form-group mb-2">
                                    <p className={`form-txt ${successMessage ? "text-success" : "text-danger"}`}>{successMessage || errorMessage}</p>
                                </div> : null
                        }
                        <div className="form-group mt-5">
                            <button
                                className="btn luci-btn primary-btn font-weight-bold pt-3 pb-3 w-100 submit-btn"
                                type="submit"
                                onClick={this.onForgotPassword}
                            >
                                Submit
                            </button>
                        </div>
                        <div className="form-group mt-4 mb-5">
                            <p className="or-txt">Back to <Link to="/signin" className="link-text font-weight-bold">Login</Link></p>
                        </div>
                        <div className="form-group">
                            <p className="form-txt">By continuing, you are agreeing to luci’s <Link to="/terms" className="link-text">Terms of Service</Link> and <Link to="/privacy-policy" className="link-text">Privacy Policy</Link></p>
                        </div>
                    </div>
                </section>
                <footer className="footer-bg">
                    <div className="container">
                    </div>
                </footer>
            </div>
        )
    }
}

export default ForgotPassword;
