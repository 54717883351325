import { createAction } from "redux-actions";
import { AccountsConstants } from "./accountsConstants";

export const fetchAccounts = createAction(
  AccountsConstants.FETCH_ACCOUNTS
);

export const setAccounts = createAction(
  AccountsConstants.SET_ACCOUNTS
);

export const setReLoginRequiredInstitutes = createAction(
    AccountsConstants.SET_RE_LOGIN_REQUIRED_INSTITUTES
);
