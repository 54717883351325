import { get } from "lodash";
import { call, put } from "redux-saga/effects";
import { BASE_URL } from "./constants";
import {getAuthHeaders, axiosInstance} from "../services/ApiService";
export const requestPending = type => `${type}_PENDING`;
export const requestSuccess = type => `${type}_SUCCESS`;
export const requestFail = type => `${type}_FAILURE`;

export const getFetchCardError = (errRes) => {
  if(errRes.status === 412 && (errRes?.data?.errors || []).length) {
    return (errRes?.data?.errors?.[0]?.msg || "Oops, Something went wrong. Please try again later.")
  } else {
    return "Oops, Something went wrong. Please try again later."
  }
}

export const request = ({
  type,
  method,
  url,
  data,
  headers,
  success,
  fail,
  payloadOnSuccess,
  payloadOnFail
}) =>
  function* apiRequest(action = { payload: {} }) {
    const {
      params,
      onUploadProgress,
      success: successCallback,
      fail: failCallback
    } = action.payload || {};

    try {
      if (type) {
        yield put({
          type: requestPending(type),
          originalType: type,
          payload: action.payload
        });
      }
      axiosInstance.defaults.baseURL = BASE_URL;
      axiosInstance.defaults.headers.common = {
        "Content-Type": "application/json",
        ...getAuthHeaders(),
      };
      const res = yield call(axiosInstance.request, {
        url,
        method: method.toLowerCase(),
        headers: {
          ...(headers || {}),
        } || {},
        data: data || {},
        params,
        onUploadProgress
      });

      if (type) {
        yield put({
          type: requestSuccess(type),
          payload: payloadOnSuccess
            ? payloadOnSuccess(res.data, action)
            : res.data,
          originalType: type
        });
      }

      if (successCallback) {
        successCallback(res.data);
      }

      if (success) {
        success(res.data, action);
      }

      if (res.headers.authorization) {
        localStorage.setItem("AuthToken", res.headers.authorization);
      }

      return res.data;
    } catch (err) {
      const errRes = get(err, "response", err);

      if (failCallback) {
        failCallback(errRes);
      }

      if (type) {
        yield put({
          type: requestFail(type),
          payload: type === "FETCH_CARDS" ? getFetchCardError(errRes || {}) : payloadOnFail ? payloadOnFail(errRes, action) : errRes,
          originalType: type
        });
      } else {
        throw err;
      }

      if (fail) {
        fail(errRes);
      }
    }

  };

export const getRequest = params => request({ ...params, method: "get" });
export const postRequest = params => request({ ...params, method: "post" });
export const putRequest = params => request({ ...params, method: "put" });
export const deleteRequest = params => request({ ...params, method: "delete" });
