import { handleActions } from "redux-actions";
import { initialCostsState } from "./initialCostsState";
import { CostsConstants } from "./costsConstants";

export const costsReducer = handleActions(
  {
    [CostsConstants.SET_COSTS]: (
      state,
      action
    ) => ({
      ...state,
      ...action.payload
    }),
  },
  initialCostsState()
);
