import React from "react";
import UserA  from "../../assets/images/user-a.png"
import UserB  from "../../assets/images/user-b.jpg"
import UserC  from "../../assets/images/user-c.png"

const Slider = () => {
  return (
    <section className="slider-testimonial">
      <div id="carouselExampleIndicators" className="carousel slide" data-ride="carousel">
        <ol className="carousel-indicators">
          <li data-target="#carouselExampleIndicators" data-slide-to="0" className="active"/>
          <li data-target="#carouselExampleIndicators" data-slide-to="1"/>
          <li data-target="#carouselExampleIndicators" data-slide-to="2"/>
        </ol>
        <div className="carousel-inner">
          <div className="carousel-item active">
            <div className="c-item">
              <p>“With luci I earn <span className="font-weight-bold">$500 more in cash back</span> each year!”</p>
              <div className="client-area">
                <img className="d-block" src={UserA} alt="" />
                  <span>Cynthia, 31</span>
                  <span>New York</span>
              </div>
            </div>
          </div>
          <div className="carousel-item">
            <div className="c-item">
              <p>“<b>One click</b> that saved me hundreds - thanks luci 🙌”</p>
              <div className="client-area">
                <img className="d-block" src={UserB} alt="" />
                  <span>Dan, 30</span>
                  <span>San Francisco</span>
              </div>
            </div>
          </div>
          <div className="carousel-item">
            <div className="c-item">
              <p>“<b>Hundreds saved</b> without changing how I spend 💰”</p>
              <div className="client-area">
                <img className="d-block" src={UserC} alt="" />
                  <span>Kenny, 29</span>
                  <span>Detroit</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Slider;
