import React from "react";
import {connect} from "react-redux";
import { Modal, Spin } from "antd";
import cloneDeep from "lodash/cloneDeep";
import {
    getTotalCost,
    commaSeparator,
    getCategoriesIcon,
    getCategoriesColor
} from "../../../../../../utils/constants";
import {setCosts} from "../../../../../../redux/modules/manualCosts/costsActions";
import Close from "../../../../../../assets/images/dashboard/close.svg";
import Line from "../../../../../../assets/images/dashboard/line.svg";
import Dining from "../../../../../../assets/images/dashboard/modal/dining.svg";
import Gas from "../../../../../../assets/images/dashboard/modal/gas.svg";
import Cart from "../../../../../../assets/images/dashboard/modal/cart.svg";
import Plane from "../../../../../../assets/images/dashboard/modal/plane.svg";
import Entertainment from "../../../../../../assets/images/dashboard/modal/entertainment.svg";
import Everything from "../../../../../../assets/images/dashboard/modal/everything.svg";


class FindCardModal extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            costs: {
                grocery: "",
                gas: "",
                restaurant: "",
                other: "",
                recreation: "",
                travel: ""
            }
        }
    }

    componentDidMount() {
        this.setState({
            costs: cloneDeep(this.props.costs)
        })
    }

    onNumberChange = (event) => {
        let { name, value } = event.target;
        value = value.replace(/,/g, "")
        if((value === "" || (/^[0-9\b]+$/).test(value)) && value.length < 6) {
            // this.props.onSetCosts({[name]: commaSeparator(value)});
            this.setState({
                costs: {
                    ...this.state.costs,
                    [name]: commaSeparator(value)
                }
            })
        }
    }

    onFindCard = () => {
        const { costs } = this.state
        this.props.onSetCosts({...costs, isManualCost: true});
        localStorage.removeItem('LUCI_DATAS')
        setTimeout(() => {
            this.props.onFindingCards();
        }, 500)
    }

    render() {
        const { onClose, fetchCardsLoading } = this.props;
        const { costs } = this.state;
        const { restaurant, gas, grocery, travel, recreation, other } = costs;
        const totalCost = getTotalCost(costs);
        const disable = String(totalCost || 0) === "0" || fetchCardsLoading
        return (
            <Modal
                visible={true}
                footer={null}
                width={500}
                className="find-cards-modal"
                closable={false}
            >
                <div className="modal-header border-bottom-0 p-0">
                    <h5 className="modal-title p-card-title">
                        Find your perfect card
                    </h5>
                    <button type="button" className="close" onClick={onClose}>
                        <img src={Close}/>
                    </button>
                </div>
                <div className="mt-20">
                    <div className="d-flex subline-text">
                        <img src={Line}/>
                        <p>In a typical month, how much do you spend on these categories?</p>
                    </div>
                    <div className="categories-list">
                        <div className="c-list">
                            {/*<img src={Dining}/>*/}
                            <img className="category-icon" src={getCategoriesIcon("Restaurants")} alt={`restaurants-icon`} style={{background: getCategoriesColor("Restaurants")}}/>
                            <span className="c-name">Restaurants</span>
                            <input type="text" className="category-input" name="restaurant" value={restaurant} onChange={this.onNumberChange} />
                        </div>
                        <div className="c-list">
                            {/*<img src={Gas}/>*/}
                            <img className="category-icon" src={getCategoriesIcon("Gas Stations")} alt={`gas-icon`} style={{background: getCategoriesColor("Gas Stations")}}/>
                            <span className="c-name">Gas Stations</span>
                            <input type="text" className="category-input" name="gas" value={gas} onChange={this.onNumberChange} />
                        </div>
                        <div className="c-list">
                            {/*<img src={Cart}/>*/}
                            <img className="category-icon" src={getCategoriesIcon("Supermarkets & Groceries")} alt={`cart-icon`} style={{background: getCategoriesColor("Supermarkets & Groceries")}}/>
                            <span className="c-name">Supermarkets & Groceries</span>
                            <input type="text" className="category-input" name="grocery" value={grocery} onChange={this.onNumberChange} />
                        </div>
                        <div className="c-list">
                            {/*<img src={Plane}/>*/}
                            <img className="category-icon" src={getCategoriesIcon("Travel")} alt={`travel-icon`} style={{background: getCategoriesColor("Travel")}}/>
                            <span className="c-name">Travel</span>
                            <input type="text" className="category-input" name="travel" value={travel} onChange={this.onNumberChange} />
                        </div>
                        <div className="c-list">
                            {/*<img src={Entertainment}/>*/}
                            <img className="category-icon" src={getCategoriesIcon("Recreation")} alt={`recreation-icon`} style={{background: getCategoriesColor("Recreation")}}/>
                            <span className="c-name">Recreation</span>
                            <input type="text" className="category-input" name="recreation" value={recreation} onChange={this.onNumberChange} />
                        </div>
                        <div className="c-list">
                            {/*<img src={Everything}/>*/}
                            <img className="category-icon" src={getCategoriesIcon("Other")} alt={`other-icon`} style={{background: getCategoriesColor("Other")}}/>
                            <span className="c-name">Other</span>
                            <input type="text" className="category-input" name="other" value={other} onChange={this.onNumberChange} />
                        </div>
                        <div className="c-list">
                            <span className="c-total">Total :  ${totalCost} </span>
                        </div>
                    </div>
                </div>
                <div className="modal-footer border-top-0 justify-content-center">
                    <button
                        className={`btn luci-btn ${!disable ? "primary-btn" : "gray-btn" } font-weight-bold pt-3 pb-3`}
                        type="submit"
                        onClick={this.onFindCard}
                        disabled={disable}
                        data-dismiss="modal"
                    >
                        { fetchCardsLoading ? <Spin/> : null } Find Your Perfect Card
                    </button>
                </div>
            </Modal>
        );
    }
}

const mapStateToProps = state => ({
    costs: state.costs || {},
    fetchCardsLoading: state.cards.fetchCardsLoading || false
});

const mapDispatchToProps = (dispatch) => {
    return {
        onSetCosts: (data) => {
            dispatch(setCosts(data))
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(FindCardModal);
