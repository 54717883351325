import React from "react";
import {Input, message, Modal, Spin} from "antd";
import {validatePassword} from "../../../../utils/constants";
import {ApiService} from "../../../../services/ApiService";


class ChangePasswordModal extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            oldPassword: "",
            password: "",
            confirmPassword: ""
        }
    }

    onChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }
    
    onSubmitPassword = async () => {
        const {userDetails = {}} = this.props;
        const { oldPassword, password, confirmPassword } = this.state
        if (!password.trim() || !confirmPassword.trim() || !oldPassword.trim()) {
            return message.error('Please enter all fields!')
        }
        if (validatePassword(password) !== true) {
            return message.error(validatePassword(password))
        }
        if (password !== confirmPassword) {
            return message.error('New password and confirm password do not match')
        }
        this.setState({
            isLoading: true
        });
        const response = await ApiService.saveProfile(userDetails.id, {password: password.trim(), oldPassword: oldPassword.trim()});
        if (!response || response.error) {
            message.error(response?.error || 'Something went wrong while saving data. Please try again later!')
        } else {
            message.success('password saved successfully!')
            this.props.onClose('isPasswordModal');
        }
        this.setState({
            isLoading: false
        })
    }

    render() {
        const { onClose } = this.props
        const { oldPassword, password, confirmPassword, isLoading } = this.state
        return (
            <Modal
                visible={true}
                footer={null}
                width={400}
                className="unlink-account-modal"
                onCancel={() => onClose("isPasswordModal")}
            >
                <div className="modal-header border-bottom-0 p-0 d-block">
                    <h6 className="modal-title p-card-title fs-22">
                        Update your password
                    </h6>
                </div>

                <div className="p-10">
                    <div>
                        <small>Old Password</small>
                        <Input type="password" name="oldPassword" value={oldPassword} onChange={this.onChange} />
                    </div>
                    <div className="mt-15">
                        <small>New Password</small>
                        <Input type="password" name="password" value={password} onChange={this.onChange} />
                    </div>
                    <div className="mt-15">
                        <small>Confirm Password</small>
                        <Input type="password" name="confirmPassword" value={confirmPassword} onChange={this.onChange} />
                    </div>
                </div>

                <div className="modal-footer border-top-0 justify-content-right mr-10">
                    <button
                        className={`btn luci-btn primary-btn font-weight-bold`}
                        onClick={this.onSubmitPassword}
                        disabled={isLoading}
                    >
                        {isLoading ? <Spin className="pr-10" /> : null }Save
                    </button>
                </div>
            </Modal>
        );
    }
}

export default ChangePasswordModal;
