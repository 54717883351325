import {call, put, takeLatest} from "redux-saga/effects";
import { request } from "../../../utils/fetch";
import { AccountsConstants } from "./accountsConstants";

function* fetchAccounts(action) {
  yield call(
    request({
      type: AccountsConstants.FETCH_ACCOUNTS,
      method: "get",
      url: '/mockdata/ChaseCY2.json',
      success: action.payload.onSuccess ? action.payload.onSuccess : null,
      fail: action.payload.onFail ? action.payload.onFail : null
    }),
    action
  );
}

function putAccounts(payload) {
  put({
    type: AccountsConstants.SET_ACCOUNTS,
    payload
  });
}

function putReLoginRequiredInstitutes(payload) {
    put({
        type: AccountsConstants.SET_RE_LOGIN_REQUIRED_INSTITUTES,
        payload
    });
}

export default function* rootSaga() {
    yield takeLatest(AccountsConstants.FETCH_ACCOUNTS, fetchAccounts);
    yield takeLatest(AccountsConstants.SET_ACCOUNTS, putAccounts);
    yield takeLatest(AccountsConstants.SET_RE_LOGIN_REQUIRED_INSTITUTES, putReLoginRequiredInstitutes);
}
