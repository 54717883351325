import React from "react";
import {connect} from "react-redux";
import {Spin, Tooltip, message} from 'antd';
import {PlaidLink} from "react-plaid-link";
import {setAccounts} from "../../../../../../redux/modules/accounts/accountsActions";
import Help from "../../../../../../assets/images/dashboard/help-icon.svg";
import BankArrow from "../../../../../../assets/images/dashboard/bank/arrow.svg";
import creditCard from "../../../../../../assets/images/dashboard/bank/creditcard.png";
import debitCard from "../../../../../../assets/images/dashboard/bank/debitcard.png";
import warning from "../../../../../../assets/images/dashboard/warning-outline.svg";
import {getAccountsCardsLogo} from "../../../../../../utils/constants";
import {setCards} from "../../../../../../redux/modules/cards/cardsActions";
import { ApiService } from "../../../../../../services/ApiService";
import UnlinkAccountModal from "./UnlinkAccountModal"
import {setDashboardStep} from "../../../../../../redux/modules/dashboardStep/dashboardStepActions";

class BankAccount extends React.Component {
    
    state = {
        accountsLoading: false,
        isModal: false,
        isLoading: false,
        renewAccessToken: null,
        removeAccessToken: null,
        renewAccessLinkToken: null,
    }

    onCardChange = (accountIndex, bankIndex) => (event) => {
       const banks = [
           ...this.props.banks
       ];
       const bank = banks[bankIndex];
       const account = bank.accounts[accountIndex];
       account.enabled = event.target.checked;

       this.props.onSetAccounts(banks);
       this.props.onSetCards({});
       this.props.onSetDashboardStep({isFirstStepComplete: false})
       localStorage.removeItem('LUCI_DATAS')
    }
    
    getEnabledAccounts = (accounts) => accounts.filter(acc => acc.enabled).length;

    onModalChange = (e) => {
        this.setState({
            isModal: !this.state.isModal
        })
    }
  
    onUnLink = async () => {
      this.setState({
        isLoading: true,
      });
      
      const response = await ApiService.unlinkAccount();
      if (!response && response.error) {
        this.setState({
          isLoading: false,
        });
        return message.error('Something went wrong while un-linking accounts!');
      } else {
        this.setState({
          isLoading: false,
        });
        const banks = [
          ...this.props.banks
        ];
        banks[0].accounts = [];
        banks[1].accounts = [];
        this.props.onSetAccounts(banks);
        this.props.getAccountService(false);
        this.props.onSetCards({});
        this.props.onSetDashboardStep({isFirstStepComplete: false})
        localStorage.removeItem('LUCI_DATAS');
        return message.success('Accounts unlinked successfully!');
      }
    }

    onRemoveButtonClickHandler = async ({access_token}) => {
        this.setState({
            removeAccessToken: access_token
        });
        await ApiService.removeAccessToken({accessToken: access_token});
        await this.props.getAccountService(false);
        this.setState({
            removeAccessToken: null
        });
    };

    onRefreshTokenButtonClickHandler = async ({access_token}) => {
        this.setState({
            renewAccessToken: access_token
        });
        const tokenLink = await ApiService.refreshAccessToken({accessToken: access_token});
        this.setState({
            renewAccessToken: null,
            renewAccessLinkToken: tokenLink
        });
    };

    onPlaidLinkButtonLoad = () => {
        document.querySelector('.plaid-hide-button')?.click();
    };

    render() {
        const { banks, reLoginRequiredInstitutes } = this.props;
        const { isModal } = this.state;
        return (
            <div className="card-body padd-xs z-index-11">
                <div className="luci-sub-title">
                    Accounts
                    <Tooltip
                        title="Use the toggle to control which cards' spending data should be included"
                        trigger={"click"}
                        // placement="topLeft"
                    >
                        <img className="help-icon ml-2" src={Help}/>
                    </Tooltip>
                    <small className="text-danger fs-11 d-flex cursor-pointer" onClick={this.onModalChange}><u>Unlink Accounts</u></small>
                </div>
                <div className={"reLoginRequiredInstitutesContainer"}>
                    {(reLoginRequiredInstitutes || []).map(({institutionName,access_token},i) => {
                        return (
                            <div className="reLoginRequiredInstitutesCard" key={i}>
                                <div className="instituteNameBox">
                                    <div className={"instituteWarningBox"}>
                                        <img src={warning} alt={"warning"} className={"warning-icon"}/>
                                    </div>
                                    <div className={"instituteBox"}>
                                        <div className="instituteName">{institutionName}</div>
                                        <div className="text-danger warning">Disconnected</div>
                                    </div>
                                </div>
                                <div>
                                    <button className={"btn luci-btn dark-btn gray-color-btn"}
                                            children={this.state.removeAccessToken === access_token ?
                                                <Spin/> : "Remove"}
                                            onClick={() => this.onRemoveButtonClickHandler({access_token})}/>
                                    <button className={"btn luci-btn dark-btn ml-2"}
                                            children={this.state.renewAccessToken === access_token ?
                                                <Spin/> : "Renew Access"}
                                            onClick={() => this.onRefreshTokenButtonClickHandler({access_token})}/>
                                </div>
                            </div>
                        )
                    })}
                </div>
                <div className="bank-area z-index-11">
                  {
                    this.state.accountsLoading ?
                      <div className="text-center"><Spin /></div>
                      :
                      <div className="accordion" id="accordionExample">
                        {
                          (banks || []).map((bank, index) => {
                              return bank.accounts.length === 0 ? <></> : (
                                  <div className="card mt-4" key={`bank-collapse-${index}`}>
                                      <div className="card-header" id={`bank-collapse-${index}`}>
                                          <a className="btn btn-link" href="" data-toggle="collapse"
                                             data-target={`#bank-collapse-area-${index}`} aria-expanded="true"
                                             aria-controls={`bank-collapse-area-${index}`}>
                                              <img src={bank.bankName === 'Debit Cards' ? debitCard : creditCard} className="card-img"/>
                                              <div className="card-name">
                                                  {bank.bankName} <span> { bank.mask ? `(${bank.mask})` : ''}</span>
                                              </div>
                                              <div className="card-enable">Include Spending Data: {this.getEnabledAccounts(bank.accounts)}/{bank.accounts.length}</div>
                                              <img src={BankArrow}
                                                   className="bank-arrow"/>
                                          </a>
                                          {/*<div className="modal-popover web-hide card-popover">
                                              <img src={Help} className="help-icon"/>
                                              <div className="tooltip-luci">
                                                <i className="tasker-popover-arrow"/>
                                                Cards Enabled: {this.getEnabledAccounts(bank.accounts)}/{bank.accounts.length}
                                              </div>
                                            </div>*/}
                                      </div>
                                      <div id={`bank-collapse-area-${index}`} className="collapse show" aria-labelledby={`bank-collapse-${index}`}>
                                          {
                                              (bank.accounts || []).map((account, i) => (
                                                  <div className="card-body bg-gray" key={`account-card-${index}-${i}`}>
                                                      <div
                                                          className={`card-sub-data ${account.enabled ? "" : "disable-card"}`}>
                                                          <img src={getAccountsCardsLogo(account.institutionLogo)}
                                                               className="card-img"/>
                                                          <div
                                                              className="card-name">{account.official_name || account.name}
                                                              <span>
                                                                  <span className={"mask"}>• • • • {account.mask}</span>
                                                              </span>
                                                          </div>
                                                          <div className="toggle">
                                                              <label className="switch">
                                                                  <input type="checkbox"
                                                                         name={`account-card-${index}-${i}`}
                                                                         checked={account.enabled}
                                                                         onChange={this.onCardChange(i, index)}/>
                                                                  <span className="slider round"/>
                                                              </label>
                                                          </div>
                                                      </div>
                                                  </div>
                                              ))
                                          }
                                      </div>
                                  </div>
                              )
                          })
                        }
                      </div>
                  }

                </div>

                {isModal ? <UnlinkAccountModal onClose={this.onModalChange} isLoading={this.state.isLoading}
                                               onUnLink={this.onUnLink}/> : null}
                {this.state.renewAccessLinkToken ? <PlaidLink onSuccess={this.props.onRefreshInstituteOnSuccess}
                            onExit={this.props.onRefreshInstituteOnExit}
                            token={this.state.renewAccessLinkToken}
                            onLoad={this.onPlaidLinkButtonLoad}
                            className={"plaid-hide-button"}
                            children={""}/> : <> </>}
            </div>
        );
    }
}

const mapStateToProps = state => ({
    banks: state.accounts.banks || [],
    fetchAccountsLoaded: state.accounts.fetchAccountsLoaded,
    reLoginRequiredInstitutes: state.accounts.reLoginRequiredInstitutes,
});

const mapDispatchToProps = (dispatch) => {
    return {
        onSetAccounts: (data) => {
            dispatch(setAccounts(data))
        },
        onSetCards: data => {
            dispatch(setCards(data));
        },
        onSetDashboardStep: data => {
            dispatch(setDashboardStep(data));
        }
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(BankAccount);
